import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";
import power_settings_new from "../../assets/images/power_settings_new.svg";
import logo from "../../assets/images/logo.png";
import logoInvert from "../../assets/images/logo-invert.png";
import "./DashboardHeader.scss";
import { Profile_data } from "../../service/Constant";
import { Logout_Api } from "../../api/api";
import { useNavigate, useLocation } from "react-router-dom";
import { logoutfunc } from "../../service/commonFunc";
import icroundsearch from "../../assets/images/ic_round-search.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css"; // Use this CSS module
import * as moment from "moment";
import clockIcon from "../../assets/images/clock.png";
import calendarIcon from "../../assets/images/calendar.png";
import { appThemeAction, MonitorDateAction, SearchChannelAction, adminModeAction, } from "../../Redux/slices";
import { image_url } from "../../service/Constant";
import playoutMonitoring from '../../assets/svg/Header/iconPlyoutmonitoring.svg'
import DashboardMonitoring from '../../assets/svg/Header/monitoringDashbord.svg'

export default function DashboardHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = window.location.href;
 
  const isMonitor = url.includes("monitor") || url.includes("gridMonitor");


  const theme = localStorage.getItem("theme");
  const admin = localStorage.getItem("adminMode");
  const emailList = ["saurabh@runn.tv", "manish@runn.tv", "amit@runn.tv", "all.films@partner.com"];

  const location = useLocation();
  const [userActive, setUserActive] = useState(false);
  const [searchChannel, setSearchChannel] = useState(false);
  const timer = useRef(null)
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },  
  } = useSelector((state) => state);
  const newdate = new Date();
  const [formattedDateString, seFormattedDateString] = useState(
    moment(newdate).format("Do MMMM YYYY")
  );

  const userDropdown = () => {
    setUserActive(!userActive);
  };

  const monitorDate = () => {
    const newdate = new Date();
    seFormattedDateString(moment(newdate).format("dddd, Do MMMM’YY"));
  };

  useEffect(() => {    
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      dispatch(SearchChannelAction(searchChannel));
    },300);    
  },[searchChannel]);

  useEffect(() => {
    dispatch(MonitorDateAction(startDate));
  }, [startDate])

  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.superTenant);
  };
  const getUserDetail = async () => {
    const userLocalData = localStorage.getItem('userdetail');
    const userLocalDataParse = await JSON.parse(userLocalData);
    setUserInfo(userLocalDataParse);
  }
  useEffect(() => {
    accessStore();
    getUserDetail();
    dispatch(adminModeAction(admin === "true" ? true : false));
  },[])

  const monitorDateOpen = (event) => {
    // document.getElementById('hidden-date').click();
    // setIsOpen(true);
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const userDropdownClose = () => {
    setUserActive(false);
  };
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // Update the date every second

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerID);
  }, []);

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const formattedDate = formatDate(currentDate);

  const getCurrentTime = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hours}:${minutes}:${seconds}`;
  };

  const monitorNavigate = () => {
    navigate("/monitor");
  };

  const gridmonitorNavigate = () => {
    navigate("/gridMonitor");
  };

  const enableAdminMode = () => {    
    dispatch(adminModeAction(true));
    navigate("/viewership");
  }

  const initialState = {
    appTheme: "dark", // Default theme is dark
  };

  const goBack = () => {
    // navigate go back history
    navigate(-1);
  };

  useEffect(() => {
    const newdate = new Date(startDate);
    seFormattedDateString(moment(newdate).format("dddd, Do MMMM’YY"));
  }, [startDate]);

  useEffect(() => {
    if (isMonitor) {
      document.getElementsByTagName("body")[0].classList.add("dark-theme");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("dark-theme");
    }
    monitorDate();
  }, [location]);
  (appTheme === "dark" || isMonitor || adminMode) ? document.getElementsByTagName("body")[0].classList.add("dark-theme") : document.getElementsByTagName("body")[0].classList.remove("dark-theme");
  useEffect(() => {
    if(appTheme === "dark" || adminMode) {
      document.getElementsByTagName("body")[0].classList.add("dark-theme");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("dark-theme");
    }
  }, [appTheme]);

  const changeTheme = (event) => {
    const themeType = event.target.checked ? "dark" : "light";
    localStorage.setItem("theme", themeType);
    dispatch(appThemeAction(themeType));

  };

// search 


const handleSearchInput = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  // Clear the existing timeout to prevent multiple searches
  if (typingTimeout) {
    clearTimeout(typingTimeout);
  }

  // Only trigger search if the query has at least 3 characters
  if (query.length >= 3) {
    const timeout = setTimeout(() => {
      console.log("Searching for:", query);
      // Replace the console.log with your search logic
      // Example: dispatch(SearchChannelAction(query));
    }, 500); // Adjust delay as needed (500ms in this example)
    setTypingTimeout(timeout);
  }
};





  useEffect(() => {
    const storedTheme = localStorage.getItem("theme") || "dark"; 
    if (storedTheme === "dark") {
      document.getElementsByTagName("body")[0].classList.add("dark-theme");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("dark-theme");
    }
    dispatch(appThemeAction(storedTheme));
  }, []);

  return isMonitor ? (
    <div className={`dashboard-header monito dark`}>
      <Helmet
        bodyAttributes={{
          style: `background-color : ${(appTheme == "dark" || adminMode) ? "#000" : "#fff"}`,
        }}
      />
      <div className="left-side">
        <div className="logo">
          {/* <a href="#">
            <img src={logoInvert} alt="" />
          </a> */}
          <div className="logo" style={{backgroundImage: `url(${appTheme == "dark" ?  storeFrontAccess?.darkThemeLogoImagePath? image_url+storeFrontAccess?.darkThemeLogoImagePath : logoInvert : storeFrontAccess?.logoImagePath? image_url+storeFrontAccess?.logoImagePath : logo})`, width: '135px', height: '30px'}}></div>
        </div>
        <div className="welcome-name">
          <h5> {url.includes("gridMonitor") ? "Monitoring Dashboard" :"Playout Monitoring" } </h5>
          <div className="date">
            <img src={clockIcon} alt="Clock" />
            <span>{getCurrentTime()}</span>
          </div>
        </div>
      </div>
      <div className="right-side">
        {/* <div
          className="date-box"
          onClick={(event) => {
            monitorDateOpen(event);
          }}
        >
          {/* <DatePicker
            id="hidden-date"
            selected={startDate || null}
            onChange={(date, event) => {
              setStartDate(date);
              event.stopPropagation();
              setIsOpen(false);
            }}
            open={isOpen}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            shouldCloseOnSelect={true}
          /> */}
          {/* <div className="date-container">
            <span>{formattedDateString}</span>
            <span className="calbutton">
              <img src={calendarIcon} alt="calendar" /> Today{" "}
            </span>
          </div> */}
        {/* </div> */}

        {/* <div className="header-search-box">
          <img src={icroundsearch} alt="Search" />
          <input
            type="text"
            className="header-search"
            placeholder="Search for channels and more"
            onChange={(e) => { setSearchChannel(e.target.value); }}
          />
        </div> */}
        <div style={{paddingRight:20}}>
        <div className="search-box" style={{width:'260px', height:45}}>
  <input
    className="border-0 p-2 pr-2"
    type="text"
    placeholder="Search for channels and more"
    value={searchQuery}
    onChange={handleSearchInput} 
    style={{
      flex: 1,
    }}
  />
  <img
    src={icroundsearch}
    alt="Search"
    style={{
      height: "27px",
      width: "27px",
      cursor: "pointer",
      filter:
        "brightness(0) saturate(100%) invert(21%) sepia(90%) saturate(5497%) hue-rotate(0deg) brightness(95%) contrast(108%)",
    }}
  />
</div>
        </div>



        <div className="dashboard-switch-button">
          <button className="btn btn-primary" onClick={goBack}>
            CMS Dashboard
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={`dashboard-header ${adminMode ? 'dark' : appTheme}`}>
      <Helmet
        bodyAttributes={{
          style: `background-color : ${(adminMode || appTheme == "dark") ? "#000" : "#fff"}`,
        }}
      />
      <div className="left-side">
        {/* <div className="logo" style={{backgroundImage: `url(${image_url}${storeFrontAccess?.logoImagePath})`, width: '135px', height: '30px'}}> */}
        <div className="logo" style={{backgroundImage: `url(${appTheme == "dark" ?  storeFrontAccess?.darkThemeLogoImagePath? image_url+storeFrontAccess?.darkThemeLogoImagePath : logoInvert : storeFrontAccess?.logoImagePath? image_url+storeFrontAccess?.logoImagePath : logo})`, width: '135px', height: '30px'}}>
          {/* <a href="#">
            <img src={logo} alt="" />
          </a> */}
        </div>
        <div className="welcome-name">
          <h5>Welcome back, {`${userInfo?.firstName} ${userInfo?.lastName}`}</h5>
          {/* <h5>Welcome back, {Profile_data?.firstName} {Profile_data?.lastName}</h5> */}
          <div className="date">
            <span>{formattedDate}</span>
          </div>
        </div>
      </div>
      <div className="right-side">
        {!adminMode && <div className="checkbox-group">
          {/* <div className="theme-check">
            <input
              type="checkbox"
               checked={theme === "dark" ? true : false}
              // value={theme}
              onChange={(event) => changeTheme(event)}
              id="theme"
            />
            <label htmlFor="theme"></label>
          </div> */}
        </div>}
        <div className="dashboard-switch-button">
        <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Playout Monitoring">
  <button className="btn btn-dark mt-2 gradient-border"  
    onClick={monitorNavigate}>
    <img src={playoutMonitoring} alt="" />
  </button>
  </span>
</div>

<div className="dashboard-switch-button" style={{ marginLeft: 24, paddingRight:40 }}>
<span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Playout Monitoring">
  <button className="btn btn-dark mt-2 gradient-border " 
    onClick={gridmonitorNavigate}>
    <img src={DashboardMonitoring} alt="" />
  </button>
  </span>
</div>


        {/* {emailList.includes(Profile_data?.email) && !adminMode  && <button className="btn btn-secondary ml-2" onClick={enableAdminMode}>
          Super Admin
        </button>} */}
        <div className="user">
          <div className="user-dropdown">
            <a
              className={`box ${userActive ? "active" : "no-active"}`}
              onClick={userDropdown}
            >
              <div className="name">{userInfo?.firstName?.charAt(0)}</div>
              {/* <div className="name">{Profile_data?.firstName.charAt(0)}</div> */}
            </a>
            <div className={`dropdown ${userActive ? "show" : "no-show"}`}>
              <a className="close" onClick={userDropdownClose}>
                Account
              </a>
              <ul>
                {/* <li>
                  <a href="">
                    <img src={person} alt="My Profile" />My Profile
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={help_outline} alt="Need Help" />Need Help?
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={call} alt="Contact Us" />Contact Us
                  </a>
                </li>
                <li>
                  <a href="">
                    <img src={info} alt="More Info" />More Info
                  </a>
                </li> */}
                <li>
                  <a onClick={() => logoutfunc()}>
                    <img src={power_settings_new} alt="Logout" />
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
