import Modal from 'react-bootstrap/Modal';
import styles from "../AdsPopup.module.scss";
import './adsInfo.scss';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import deleteIcon from '../../../assets/images/delete_forever.png';

const AdsInfo = ({
    show, 
    handleClose, 
    assetInfo, 
    millisecondsToHuman, 
    formatTime,
    appTheme, 
    categoryTab, 
    handlecategoryTab,
    permission,
    selectedFiller,
    fillerList,
    setAdsTime,
    deleteEvent,
    adAdsOnClick,
    handleProgramDelete,
    getAssetInfo,
    setProgramTime,
}) => {
    
    return (
        <Modal show={show} className={styles.partnermodal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{assetInfo?.title} | Schedule: {assetInfo?.scheduledDate} at {formatTime(assetInfo?.startAt)} | Duration: {millisecondsToHuman(assetInfo?.totalDuration)}</Modal.Title>
            </Modal.Header>
            <div className="right-side-content col-12">
                <div className="background-right-container rounded">
                    <div className={`top-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
                        <div className="left-side">
                            <h5 className="py-3 px-3">{assetInfo?.title}</h5>
                        </div>
                        <div className="asset-duration-cont row px-1 pb-3">
                            <div className="col-5 start-end-time">
                                <h5 className="px-2 d-flex">{
                                assetInfo?
                                <>
                                    <TimePicker
                                        value={formatTime(assetInfo?.startAt)}
                                        hourPlaceholder="HH"
                                        minutePlaceholder="mm"
                                        secondPlaceholder="ss"
                                        maxDetail="second"
                                        format="HH:mm:ss"
                                        disableClock={true}
                                        onChange={(e) => {
                                            setProgramTime(e, assetInfo?.uuid, assetInfo?.sequence, assetInfo?.panelDate);
                                        }}
                                    />
                                    -
                                    <TimePicker
                                        value={formatTime(assetInfo?.endAt)}
                                        hourPlaceholder="HH"
                                        minutePlaceholder="mm"
                                        secondPlaceholder="ss"
                                        maxDetail="second"
                                        format="HH:mm:ss"
                                        disableClock={true}
                                        disabled={true}
                                        style={{background: "#000"}}
                                    />
                                </>
                                :
                                "00:00:00 - 00:00:00"
                                }</h5>
                            </div>
                            <div className="col-7 d-flex video-ads-time">
                                <h5 className="mx-2"><span>Video</span> - {assetInfo? millisecondsToHuman(assetInfo?.duration) : "00:00:00"}</h5>
                                {
                                    categoryTab === "Ads" ?
                                        <h5 className="mx-2"><span>Ads</span> - 
                                            {assetInfo?
                                                millisecondsToHuman(assetInfo?.adBreaks
                                                    ?.filter(data => data?.type === "ad" || data?.type === "filler")
                                                    ?.reduce((total, ad) => total + ad.duration, 0)
                                                )
                                                :
                                                "00:00:00"
                                            }
                                        </h5>
                                    :
                                    categoryTab === "Promos" ?
                                        <h5 className="mx-2"><span>Promos</span> - 
                                            {assetInfo?
                                                millisecondsToHuman(assetInfo?.adBreaks
                                                    ?.filter(data => data?.assetType === "PROMO")
                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                )
                                                :
                                                "00:00:00"
                                            }
                                        </h5>
                                    :
                                    categoryTab === "Secondary" ? 
                                        <h5 className="mx-2"><span>Sec</span> - 
                                            {assetInfo?
                                                millisecondsToHuman(assetInfo?.adBreaks
                                                    ?.filter(data => data?.assetType === "FILLER")
                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                )
                                                :
                                                "00:00:00"
                                            }
                                        </h5>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`bottom-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
                        <div className="tabs mt-4 p-2">
                            <ul className={`channel-category-tab px-1 ${appTheme === "light" ? "border shadow-sm": ""}`}>
                                <li className={categoryTab === "Ads" ? "active" : ""}>
                                <a onClick={() => handlecategoryTab("Ads")}>Ads</a>
                                </li>
                                <li className={categoryTab === "Promos" ? "active" : ""}>
                                <a onClick={() => handlecategoryTab("Promos")}>Promos</a>
                                </li>
                                <li className={categoryTab === "Secondary" ? "active" : ""}>
                                <a disable onClick={() => {handlecategoryTab("Secondary");}}>Secondary</a>
                                </li>
                            </ul>
                        </div>
                        <div className={`ads-table mx-2 my-3 p-2 rounded ${appTheme === "light"? "shadow-sm border" : ""}`}>
                            <table className={`table ${appTheme === "light"? "border table-striped table-hover" : ""}`}>
                                <thead>
                                    <tr>
                                        <th>Name <span className="header-icon"></span></th>
                                        <th>Start Time <span className="header-icon"></span></th>
                                        <th>Duration</th>
                                        <th>
                                            {
                                                permission === "edit" &&
                                                "Actions"
                                            }
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { categoryTab === "Ads"?
                                        assetInfo?.adBreaks?.some((data) => data?.type === "ad" || data?.type === "filler")?
                                            assetInfo?.adBreaks
                                            ?.filter((data) => data?.type === "ad" || data?.type === "filler")
                                            .map((data, index) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td style={{width:"40%"}}>   
                                                            <select value={data?.assetId} onChange={(e)=> {selectedFiller(e.target.value, data?.adSqId, assetInfo?.sequence, assetInfo?.panelDate)}}>
                                                                {
                                                                    fillerList?.map((fillers) => {
                                                                        return <option value={fillers?.id} >{fillers?.filename}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        <td style={{width:"25%"}}>
                                                            <TimePicker
                                                                value={millisecondsToHuman(data?.startOffsetInMillis)}
                                                                hourPlaceholder="HH"
                                                                minutePlaceholder="mm"
                                                                secondPlaceholder="ss"
                                                                maxDetail="second"
                                                                format="HH:mm:ss"
                                                                disableClock={true}
                                                                onChange={(e) => {
                                                                    setAdsTime(e, data?.adSqId, assetInfo?.sequence, assetInfo?.panelDate);
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{width:"25%"}}>
                                                            <div className="ads-duration">
                                                                {millisecondsToHuman(data?.duration)}
                                                            </div>
                                                        </td>
                                                        <td style={{width:"10%"}}>
                                                            {
                                                                permission === "edit" &&
                                                                <div className="table-action-group">
                                                                    <div className='icon-group'>
                                                                        <span className="table-icon" onClick={()=> {deleteEvent(data?.adSqId, assetInfo?.sequence, assetInfo?.panelDate)}}><img src={deleteIcon} /></span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                No ads to display.
                                            </td>
                                        :
                                        categoryTab === "Promos"?
                                        assetInfo?.adBreaks?.some((data) => data?.assetType === "PROMO")?
                                            assetInfo?.adBreaks
                                            ?.filter((data) => data?.assetType === "PROMO")
                                            .map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>{data?.title}</td>
                                                        <td>
                                                            <TimePicker
                                                                value={millisecondsToHuman(data?.startOffsetInMillis)}
                                                                hourPlaceholder="HH"
                                                                minutePlaceholder="mm"
                                                                secondPlaceholder="ss"
                                                                maxDetail="second"
                                                                format="HH:mm:ss"
                                                                disableClock={true}
                                                                onChange={(e) => {
                                                                    setAdsTime(e, data?.adSqId, assetInfo?.sequence, assetInfo?.panelDate);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            {millisecondsToHuman(data?.durationInMillis)}
                                                        </td>
                                                        <td>
                                                            {
                                                                permission === "edit" &&
                                                                <div className="table-action-group">
                                                                    <div className='icon-group'>
                                                                        <span className="table-icon ms-3" onClick={()=> {deleteEvent(data?.adSqId, assetInfo?.sequence)}}><img src={deleteIcon} /></span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            :
                                            <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                No promos to display.
                                            </td>
                                        :
                                        categoryTab === "Secondary"?
                                        <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                            No Secondary to display.
                                        </td>
                                        :
                                        ''
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            categoryTab === "Ads"?
                            <span className="mt-3 ads-table-btn p-2" onClick={() => {adAdsOnClick(assetInfo?.sequence, assetInfo?.panelDate)}}>+ Ad Break</span>
                            :
                            ''
                        }
                    </div>
                    <div className={`bottom-container-buttons`}>
                        <div className="buttons-container p-2 d-flex justify-content-between">
                            <a 
                                className={`btn btn-secondary w-50 mx-3 ${appTheme === "dark"? 'text-white': ''}`} 
                                onClick={(e) => {handleProgramDelete(e, assetInfo?.sequence - 1, assetInfo?.panelDate)}}
                            >Delete</a>

                            <a 
                                className="btn btn-danger w-50 mx-3"
                                onClick={(e) => { 
                                    e.preventDefault();
                                    if ((assetInfo?.sequence - 1) > 0) {
                                        getAssetInfo(assetInfo?.sequence - 1, assetInfo?.panelDate);
                                        document
                                        .getElementById(`sequenceId${assetInfo?.sequence - 1}`)
                                        ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                                    }
                                }}
                            >Previous</a>
                            <a 
                                className="btn btn-primary w-50 mx-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (assetInfo?.sequence < assetInfo?.panelLength) {
                                        getAssetInfo(assetInfo?.sequence + 1, assetInfo?.panelDate);
                                        document
                                        .getElementById(`sequenceId${assetInfo?.sequence + 1}`)
                                        ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                                    }
                                }}
                            >Next</a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AdsInfo;