import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { 
    Schedules_list, 
    Schedules_Datalist,
    Schedule_update,
    programValidate,
    adValidate,
} from "../../../api/api";
import icroundsearch from "../../../assets/images/ic_round-search.svg";
import ellipse from "../../../assets/images/Ellipse.png";
import deleteIcon from '../../../assets/images/delete_forever.png';
import adMarkers from "../../../assets/images/adMarkers.svg";
import revertIcon from "../../../assets/images/revert.svg";
import { toast, ToastContainer } from "react-toastify";
import zoomInIcon from "../../../assets/images/zoom-in.svg";
import zoomOutIcon from "../../../assets/images/zoom-out.svg";
import AdsInfo from "./adsInfo";
import _ from "lodash";
import expandIcon from "../../../assets/images/expand-icon.svg";
import copyIcon from "../../../assets/images/copy-icon.svg";
import pasteIcon from "../../../assets/images/paste.svg";
import ExpandView from "./expand-view";
import trim_svg from "../../../assets/images/image-trim.svg"
import ErrorPopup from "../../../component/popup/ErrorPopup";

function SchedulingDetailsNew () {

    const [programList, setProgramList] = useState();
    const [originalProgramList, setOriginalProgramList] = useState([]);
    const [promoList, setPromoList] = useState();
    const [fillerList, setFillerist] = useState();
    const [searchedText, setSearchedText] = useState("");
    const [selectedTab, setSelectedTab] = useState("Programs");
    const [gridList, setGridList] = useState();
    const [linePosition, setLinePosition] = useState(null);
    const [panelLinePosition, setPanelLinePosition] = useState(null);
    const [hoverTime, setHoverTime] = useState(null);
    const [panelHoverTime, setPanelHoverTime] = useState(null);
    const [panelHoverDate, setPanelHoverDate] = useState(null);
    const [draggableType, setDraggableType] = useState(null);
    const [zoomInOut, setZoomInOut] = useState(10);
    const [hoveredId, setHoveredId] = useState(null); 
    const [timeSlots, setTimeSlots] = useState([]);
    const [singleTimeSlots, setSingleTimeSlots] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [assetInfo, setAssetInfo] = useState(null);
    const [categoryTab, setCategoryTab] = useState("Ads");
    const [seqId, setSeqId] = useState(1);
    const [newGridData, setNewGridData] = useState([]);    
    const [viewType, setViewType] = useState("defaultView");
    const [expandViewData, setExpandViewData] = useState([]);
    const [expendedViewDate, setExpendedViewDate] = useState('');
    const [copyData, setCopyData] = useState([]);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [errorbox, setErrorbox] = useState(false);
    const [eventTrim, seteventTrim] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const locationState = useLocation();
    
    const {
        appThemeReducer: { appTheme },
    } = useSelector((state) => state);
    const draggedItem = useRef();  

    const searchProgram = (text) => {
        if (text) {
            const filterPrograms = originalProgramList?.filter((data) => {
                return data?.metadata?.title?.toLowerCase().includes(text.toLowerCase());
            });
            setProgramList(filterPrograms);
        } else {
            // If text is empty or null, show the original program list
            setProgramList(originalProgramList);
        }
        setSearchedText(text);
    };

    const channelDragStart = (event) => {
        draggedItem.current = event.target;
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
    };

    const handelProgramDoubleClick = (data, index) => {
        
    } 

    const handleProgramDelete = (event, uuid, panelDate) => {
        event.stopPropagation();
        // Update gridList by removing the item with the matching uuid
        const updatedGridList = gridList.map((schedule) => {
            const updatedScheduleItems = schedule.scheduleItems
                .filter((item) => item.uuid !== uuid) // Remove the item with the matching uuid
                .map((item, index) => ({
                    ...item,
                    sequence: index + 1, // Reset sequence starting from 1
                }));
    
            return {
                ...schedule,
                scheduleItems: updatedScheduleItems,
            };
        });
        setGridList(updatedGridList);
        // addadsSqId(updatedGridList, panelDate);
        setShowPopup(false);
    };

    const closePopup = () => {
        setShowPopup(false);
    }; 

    const handleDragStart = (event) => {
        draggedItem.current = event.target;
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
    };

    const handleDragOver = (event, date) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        let { type, value, key } = draggedItem.current.dataset; 
        setDraggableType(type);
        setPanelHoverDate(date)
        const containerTop = event.currentTarget.getBoundingClientRect().top;
        const containerHeight = event.currentTarget.getBoundingClientRect().height;
    
        const yPos = event.clientY - containerTop;
        const durationPerPixel = 1440 / containerHeight; // 1440 minutes in 24 hours divided by the panel's height
        const timeAtPosition = yPos * durationPerPixel;
    
        // Convert time from minutes to hh:mm:ss format
        let totalSeconds = Math.floor(timeAtPosition * 60);
        if (totalSeconds < 0) {
            totalSeconds = 0;
        }
        const hours = Math.floor(totalSeconds / 3600) % 24; // Ensure hours wrap around at 24
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    
        setPanelLinePosition(yPos);
        setPanelHoverTime(formattedTime);
    };

    const handleDragOver2 = (event, id) => {
        event.preventDefault();
        let { type, value, key } = draggedItem.current.dataset;         
        setDraggableType(type);
        setHoveredId(id); // Set the currently hovered asset ID
        const containerTop = event.currentTarget.getBoundingClientRect().top;
        const yPos = event.clientY - containerTop;
        const durationPerPixel = 1/zoomInOut; // Adjust this as needed
        const timeAtPosition = yPos * durationPerPixel;

        // Convert time from minutes to hh:mm:ss format
        const totalSeconds = Math.floor(timeAtPosition * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        
        setLinePosition(yPos);
        setHoverTime(formattedTime);
    };

    function adjustScheduleItems(gridList) {
        for (let i = 0; i < gridList.length; i++) {
            const currentDate = gridList[i];
            let previousEndAt = null;
    
            // Check the last schedule item's endAt from the previous date
            if (i > 0 && gridList[i - 1].scheduleItems.length > 0) {
                const previousScheduleItems = gridList[i - 1].scheduleItems;
                previousEndAt = previousScheduleItems[previousScheduleItems.length - 1].endAt;
            }
    
            // Traverse scheduleItems of the current date
            currentDate.scheduleItems.forEach((item, index) => {
                if (index === 0) {
                    // For the first scheduleItem of the current date
                    if (previousEndAt && currentDate.date <= previousEndAt) {
                        // If current date is less than or equal to the previous endAt
                        item.startAt = previousEndAt + 1000; // Add 1 second
                    } else {
                        // Otherwise, start at the current date's start
                        item.startAt = currentDate.date;
                    }
                } else {
                    // For subsequent scheduleItems, startAt is the endAt of the previous item + 1 second
                    item.startAt = currentDate.scheduleItems[index - 1].endAt + 1000;
                }
    
                // Calculate endAt based on the adjusted startAt and totalDuration
                item.endAt = item.startAt + item.totalDuration;
            });
        }
    
        setGridList(gridList);
        // expandPanelView(expendedViewDate);
    }

    const addadsSqIdOld = (data, panelDate) => {
        
        // Find the object matching the provided panelDate
        const panelData = data.find((item) => item.date === panelDate);
    
        if (!panelData) {
            console.error(`No data found for the panelDate: ${panelDate}`);
            return;
        }
    
        // Extract scheduleItems for the matched date
        const { scheduleItems } = panelData;
    
        let startTime = new Date(panelDate).setUTCHours(0, 0, 0, 0);
        // Process each program in the scheduleItems
        const updatedScheduleItems = scheduleItems.map((program) => {
            // Calculate total duration from adBreaks
            const totalAdBreakDuration =
                program?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0) || 0;
            const totalDuration = totalAdBreakDuration + (program?.duration || 0);
            const endTime = startTime + (program?.duration || 0) + totalAdBreakDuration;
            const adBreaksCount = program?.adBreaks?.length || 0;
            // Sort the adBreaks based on startOffsetInMillis or startAt and reassign adSqId
            const updatedAdBreaks = program?.adBreaks
                ?.sort((a, b) => (a.startOffsetInMillis || 0) - (b.startOffsetInMillis || 0))
                .map((ad, index) => ({
                    ...ad,
                    adSqId: index + 1, // Assign incremental adSqId
                }));
    
            const updatedProgram = {
                ...program,
                adBreaks: updatedAdBreaks,
                totalDuration: totalDuration,
                adBreaksCount: adBreaksCount,
                startAt: startTime,
                endAt: endTime,
            };
    
            startTime = endTime + 1000; // Update startTime for the next program
    
            return updatedProgram;
        });
        
        // Replace the original scheduleItems with the updated ones
        panelData.scheduleItems = updatedScheduleItems;
        
        // Update the data in your state or wherever it's stored
        adjustScheduleItems([...data]); // Assuming setGridList is your state setter function
    };  
    
    const addadsSqId = (data, panelDate) => {
        // Find the object matching the provided panelDate
        const panelData = data.find((item) => item.date === panelDate);
    
        if (!panelData) {
            console.error(`No data found for the panelDate: ${panelDate}`);
            return;
        }
    
        // Extract scheduleItems for the matched date
        const { scheduleItems } = panelData;
    
        // Process each program in the scheduleItems
        const updatedScheduleItems = scheduleItems.map((program) => {
            // Calculate total duration from adBreaks
            const totalAdBreakDuration =
                program?.adBreaks?.reduce((sum, ad) => sum + (ad.duration || 0), 0) || 0;
    
            // Sort the adBreaks based on startOffsetInMillis or startAt and reassign adSqId
            const updatedAdBreaks = program?.adBreaks
                ?.sort((a, b) => (a.startOffsetInMillis || 0) - (b.startOffsetInMillis || 0))
                .map((ad, index) => ({
                    ...ad,
                    adSqId: index + 1, // Assign incremental adSqId
                }));
    
            // Update program with adjusted totalDuration and endAt
            const totalDuration = (program?.duration || 0) + totalAdBreakDuration;
            const updatedProgram = {
                ...program,
                adBreaks: updatedAdBreaks,
                totalDuration: totalDuration,
                endAt: (program?.startAt || 0) + totalDuration, // Adjust endAt based on startAt
            };
    
            return updatedProgram;
        });
    
        // Replace the original scheduleItems with the updated ones
        panelData.scheduleItems = updatedScheduleItems;
    
        // Update the data in your state or wherever it's stored
        setGridList([...data]); // Assuming adjustScheduleItems is your state setter function
    };

    function generateUniqueUUID(gridList) {
        const allUUIDs = gridList.flatMap(item => item.scheduleItems.map(schedule => schedule.uuid));        
        let uuid;
        do {
            uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0;
                const v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        } while (allUUIDs.includes(uuid));
        return uuid;
    }

    const handleDrop = async(event, panelDate) => {        
        event.preventDefault();
        setHoveredId(null);
        setLinePosition(null);
        let { type, value, key } = draggedItem.current.dataset; 
        let { id } = event.target.dataset;
        try {
            value = JSON.parse(value);
        } catch (error) {
            // If JSON.parse fails, leave value as-is (e.g., time string "00:00:30") for ads
        }
        let ads = [];
        let obj = {};
        let totalAdsDuration = 0;
        if (type === "draggable-list") {
            if (value?.isSplit) {
                setPanelHoverTime(null);
                setPanelLinePosition(null);
                setPanelHoverDate(null);
                return;
            }
            // Convert panelHoverTime (HH:MM:SS) to milliseconds
            const [hours, minutes, seconds] = panelHoverTime.split(":").map(Number);
            const hoverTimeInMillis = (hours * 3600 + minutes * 60 + seconds) * 1000;
            let combinedTimeInMillis = panelDate + hoverTimeInMillis;
        
            let updatedGridList = [...gridList];
            const valueOfItem = gridList
                    .flatMap((grid) => grid?.scheduleItems)
                    .find((item) => item?.uuid === value?.uuid); 
            value = valueOfItem;
            
            const gridListToCheck = gridList.map((data) => {
                return {
                    ...data,
                    scheduleItems:data?.scheduleItems.filter(item => item.uuid !== value?.uuid)
                } 
            })
            
            // Find overlapping item across all gridList scheduleItems
            const overlappingItem = gridListToCheck.find(item => 
                item.scheduleItems.some(schedule => {
                    const itemStart = schedule.startAt;
                    const itemEnd = schedule.endAt;
                    const objStart = combinedTimeInMillis;
                    const objEnd = combinedTimeInMillis + value["totalDuration"];
                    return (
                        (objStart >= itemStart && objStart <= itemEnd) || // Starts within an existing item's time
                        (objEnd >= itemStart && objEnd <= itemEnd) ||     // Ends within an existing item's time
                        (objStart <= itemStart && objEnd >= itemEnd)   // Fully covers an existing item's time
                    );
                })
            );
            
            if (overlappingItem) {
                const overlappingSchedule = overlappingItem.scheduleItems.find(schedule => {
                    const itemStart = schedule.startAt;
                    const itemEnd = schedule.endAt;
                    const objStart = combinedTimeInMillis;
                    const objEnd = combinedTimeInMillis + value["totalDuration"];
                
                    return (
                        (objStart >= itemStart && objStart <= itemEnd) || 
                        (objEnd >= itemStart && objEnd <= itemEnd) ||     
                        (objStart <= itemStart && objEnd >= itemEnd)     
                    );
                });
                    
                if (overlappingSchedule && overlappingSchedule?.uuid !== value?.uuid) {
                    if (overlappingItem.scheduleItems.indexOf(overlappingSchedule) === overlappingItem.scheduleItems.length - 1) {
                        if (overlappingSchedule.endAt >= overlappingItem.date + 24 * 3600 * 1000) {
                            if (overlappingItem.date === panelDate) {
                                toast.error("Overlap exceeds 24 hours within the same panel date.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 3000
                                });
                                setPanelHoverTime(null);
                                setPanelLinePosition(null);
                                setPanelHoverDate(null);
                                return;
                            } else {
                                // Adjust startAt for different date
                                combinedTimeInMillis = overlappingSchedule.endAt;
                                // Check next day's scheduleItems for overlap
                                const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date + 24 * 3600 * 1000));
                                if (nextDayIndex !== -1) {
                                    const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                    let nextItem = nextDaySchedules[0]; // First item of the next day
                                    if (nextItem?.uuid === value?.uuid) {
                                        nextItem = undefined;
                                    }
                                    
                                    if (nextItem) {
                                        const nextItemStart = nextItem.startAt;
                                        const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                                        
                                        if (availableSpace < (value["totalDuration"])) {
                                            toast.error("Not enough space to adjust the program.", {
                                                position: toast.POSITION.BOTTOM_RIGHT,
                                                autoClose: 3000
                                            });
                                            setPanelHoverTime(null);
                                            setPanelLinePosition(null);
                                            setPanelHoverDate(null);
                                            return;
                                        }
                                    }
                                }
                            }
                        } else {
                            combinedTimeInMillis = overlappingSchedule.endAt;
                        }
                    } else {
                        // Check if enough space exists between overlapping item and the next item
                        const nextItemIndex = overlappingItem.scheduleItems.indexOf(overlappingSchedule) + 1;
                        let nextItem = overlappingItem.scheduleItems[nextItemIndex];
                        if(value?.uuid === nextItem?.uuid) {
                            nextItem = overlappingItem.scheduleItems[nextItemIndex + 1];
                        } else {
                            nextItem = overlappingItem.scheduleItems[nextItemIndex]
                        }
                        if (nextItem) {
                            const spaceAvailable = nextItem.startAt - (overlappingSchedule.endAt); // Time gap in ms
                            if (spaceAvailable >= value["totalDuration"]) {
                                combinedTimeInMillis = overlappingSchedule.endAt;
                            } else {
                                toast.error("Not enough space to adjust the program.", {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 3000
                                });
                                setPanelHoverTime(null);
                                setPanelLinePosition(null);
                                setPanelHoverDate(null);
                                return;
                            }
                        } else {
                            combinedTimeInMillis = overlappingSchedule.endAt;
                        }
                    }
                }
            }

            // Update and sort scheduleItems within each grid
            updatedGridList = updatedGridList.map((grid) => {
                // Update scheduleItems and sort them directly
                const updatedScheduleItems = grid.scheduleItems
                    .map((scheduleItem) => {
                        if (scheduleItem.uuid === value?.uuid) {
                            const updatedStartAt = combinedTimeInMillis;
                            const updatedEndAt = updatedStartAt + scheduleItem.totalDuration;

                            return {
                                ...scheduleItem,
                                startAt: updatedStartAt,
                                endAt: updatedEndAt,
                            };
                        }
                        return scheduleItem;
                    })
                    .sort((a, b) => a.startAt - b.startAt) // Sort by startAt

                    .map((item, index) => ({
                        ...item,
                        sequence: index + 1, // Update sequence
                    }));

                return {
                    ...grid,
                    scheduleItems: updatedScheduleItems,
                };
            });

            // Update state after confirming no overlap
            setGridList(updatedGridList);
            setPanelHoverTime(null);
            setPanelLinePosition(null);
            setPanelHoverDate(null);
        }
        if (type === "programs" && value) {

            // const assetValidate = await programValidate(value["id"]);
            // if (!assetValidate?.status) {
            //     toast.error(assetValidate?.message, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 3000
            //     });
            //     return;
            // }

            const dateIndex = gridList.findIndex(item => item.date === panelDate);
            
            // to check the default ad markers
            if (value?.defaultAdMarkers?.length) {
                const updatedAdBreaks = value?.defaultAdMarkers
                    .sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
                    .map((ad, index) => {
                        totalAdsDuration += ad.durationInMillis
                        return {
                            assetId: ad?.assetId,
                            date: null,
                            duration: ad?.durationInMillis,
                            startOffsetInMillis: ad?.startOffsetInMillis,
                            title: ad?.title,
                            type: "filler",
                            adSqId: index + 1,
                        };
                    });
                ads = updatedAdBreaks;
            }
            if (dateIndex !== -1) {
                const uuid = generateUniqueUUID(gridList);
            
                // Convert panelHoverTime (HH:MM:SS) to milliseconds
                const [hours, minutes, seconds] = panelHoverTime.split(":").map(Number);
                const hoverTimeInMillis = (hours * 3600 + minutes * 60 + seconds) * 1000;
            
                // Add hoverTimeInMillis to panelDate
                let combinedTimeInMillis = panelDate + hoverTimeInMillis;
            
                const scheduleItems = gridList[dateIndex]?.scheduleItems || [];
            
                // Find overlapping item across all gridList scheduleItems
                const overlappingItem = gridList.find(item => 
                    item.scheduleItems.some(schedule => {
                        const itemStart = schedule.startAt;
                        const itemEnd = schedule.endAt;
                        const objStart = combinedTimeInMillis;
                        const objEnd = combinedTimeInMillis + totalAdsDuration + value["duration"];

                        return (
                            (objStart >= itemStart && objStart <= itemEnd) || // Starts within an existing item's time
                            (objEnd >= itemStart && objEnd <= itemEnd) ||     // Ends within an existing item's time
                            (objStart <= itemStart && objEnd >= itemEnd)     // Fully covers an existing item's time
                        );
                    })
                );
                
                if (overlappingItem) {
                    // Find the overlapping schedule within the gridList
                    const overlappingSchedule = overlappingItem.scheduleItems.find(schedule => {
                        const itemStart = schedule.startAt;
                        const itemEnd = schedule.endAt;
                        const objStart = combinedTimeInMillis;
                        const objEnd = combinedTimeInMillis + totalAdsDuration + value["duration"];

                        return (
                            (objStart >= itemStart && objStart <= itemEnd) || 
                            (objEnd >= itemStart && objEnd <= itemEnd) ||     
                            (objStart <= itemStart && objEnd >= itemEnd)     
                        );
                    });

                    if (overlappingSchedule) {
                        console.error(`Overlap found with schedule ID: ${overlappingSchedule.id}`);
                        
                        // If overlapping is the last item
                        if (overlappingItem.scheduleItems.indexOf(overlappingSchedule) === overlappingItem.scheduleItems.length - 1) {
                            if (overlappingSchedule.endAt >= overlappingItem.date + 24 * 3600 * 1000) {
                                if (overlappingItem.date === panelDate) {
                                    toast.error("Overlap exceeds 24 hours within the same panel date.", {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        autoClose: 3000
                                    });
                                    setPanelHoverTime(null);
                                    setPanelLinePosition(null);
                                    setPanelHoverDate(null);
                                    return;
                                } else {
                                    // Adjust startAt for different date
                                    combinedTimeInMillis = overlappingSchedule.endAt;
                                    // Check next day's scheduleItems for overlap
                                    const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date + 24 * 3600 * 1000));
                                    if (nextDayIndex !== -1) {
                                        const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                        const nextItem = nextDaySchedules[0]; // First item of the next day
                            
                                        if (nextItem) {
                                            const nextItemStart = nextItem.startAt;
                                            const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                            
                                            if (availableSpace < (value["duration"] + totalAdsDuration)) {
                                                toast.error("Not enough space to adjust the program", {
                                                    position: toast.POSITION.BOTTOM_RIGHT,
                                                    autoClose: 3000
                                                });
                                                setPanelHoverTime(null);
                                                setPanelLinePosition(null);
                                                setPanelHoverDate(null);
                                                return;
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (overlappingItem.date !== panelDate) {
                                    if (overlappingSchedule.endAt >= overlappingItem.date) {
                                        const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date));
                                        if (nextDayIndex !== -1) {
                                            const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                            const nextItem = nextDaySchedules[0]; // First item of the next day
    
                                            if (nextItem) {
                                                const nextItemStart = nextItem.startAt;
                                                const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                                
                                                if (availableSpace < (value["duration"] + totalAdsDuration)) {
                                                    toast.error("Not enough space to adjust the program", {
                                                        position: toast.POSITION.BOTTOM_RIGHT,
                                                        autoClose: 3000
                                                    });
                                                    setPanelHoverTime(null);
                                                    setPanelLinePosition(null);
                                                    setPanelHoverDate(null);
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }else {
                                    combinedTimeInMillis = overlappingSchedule.endAt; // 1 second after last item's endAt
                                }
                            }
                        } else {
                            // Check if enough space exists between overlapping item and the next item
                            const nextItemIndex = overlappingItem.scheduleItems.indexOf(overlappingSchedule) + 1;
                            const nextItem = overlappingItem.scheduleItems[nextItemIndex];
                            const spaceAvailable = nextItem.startAt - (overlappingSchedule.endAt); // Time gap in ms
                            if (spaceAvailable >= value["duration"] + totalAdsDuration) {
                                combinedTimeInMillis = overlappingSchedule.endAt; // Adjust startAt
                            } else {
                                if (overlappingItem.date === panelDate) {
                                    toast.error("Not enough space to adjust the program.", {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        autoClose: 3000
                                    });
                                    setPanelHoverTime(null);
                                    setPanelLinePosition(null);
                                    setPanelHoverDate(null);
                                    return;
                                } else {
                                    if (overlappingItem.date !== panelDate) {
                                        if (overlappingSchedule.endAt >= overlappingItem.date) {
                                            const nextDayIndex = gridList.findIndex(item => item.date === (overlappingItem.date));
                                            if (nextDayIndex !== -1) {
                                                const nextDaySchedules = gridList[nextDayIndex]?.scheduleItems || [];
                                                const nextItem = nextDaySchedules[0]; // First item of the next day
        
                                                if (nextItem) {
                                                    const nextItemStart = nextItem.startAt;
                                                    const availableSpace = nextItemStart - combinedTimeInMillis; // Time gap between the adjusted start and next item's start
                                    
                                                    if (availableSpace < (value["duration"] + totalAdsDuration)) {
                                                        toast.error("Not enough space to adjust the program", {
                                                            position: toast.POSITION.BOTTOM_RIGHT,
                                                            autoClose: 3000
                                                        });
                                                        setPanelHoverTime(null);
                                                        setPanelLinePosition(null);
                                                        setPanelHoverDate(null);
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }else {
                                        combinedTimeInMillis = overlappingSchedule.endAt; // 1 second after last item's endAt
                                    }
                                }
                            }
                        }
                    }
                }
            
                // Create the new object
                const obj = {
                    adBreaks: ads,
                    adBreaksCount: value?.defaultAdMarkers?.length,
                    assetId: value["id"],
                    assetType: "PROGRAM",
                    duration: value["duration"],
                    endAt: combinedTimeInMillis + totalAdsDuration + value["duration"],
                    id: value["id"],
                    scheduledDate: panelDate,
                    startAt: combinedTimeInMillis,
                    title: value["metadata"]["title"],
                    totalDuration: totalAdsDuration + value["duration"],
                    uuid: uuid,
                };
            
                // Copy adBreaks if the assetId matches
                const latestIndex = scheduleItems.findLastIndex(item => item.assetId === value["id"]);
                if (latestIndex !== -1) {
                    obj.adBreaks = [...scheduleItems[latestIndex]?.adBreaks];
                }
            
                addToDragabbleList(obj, panelDate);
                setPanelHoverTime(null);
                setPanelLinePosition(null);
                setPanelHoverDate(null);
            } else {
                console.error(`Date ${panelDate} not found in gridList.`);
                setPanelHoverTime(null);
                setPanelLinePosition(null);
                setPanelHoverDate(null);
            }
            return;
        }
        if (type == "fillers" && value) {
            // const assetValidate = await adValidate(value["id"]);
            // if (!assetValidate?.status) {
            //     toast.error(assetValidate?.message, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 3000
            //     });
            //     return;
            // } 

            obj = {
                assetId: value["id"],
                type: "filler",
                duration: value["duration"],
                startOffsetInMillis: verifyMilliSeconds(hoverTime),
                title: value["filename"],
                date: null,
            };

            // Function to check if the new ad break overlaps with existing ad breaks
            function isOverlapping(adBreaks, newAd) {
                return adBreaks.some(ad => {
                    const adStart = ad.startOffsetInMillis;
                    const adEnd = ad.startOffsetInMillis + ad.duration;
                    const newAdStart = newAd.startOffsetInMillis;
                    const newAdEnd = newAd.startOffsetInMillis + newAd.duration;

                    const isOverlap = 
                        (newAdStart >= adStart && newAdStart < adEnd) || 
                        (newAdEnd > adStart && newAdEnd <= adEnd) || 
                        (newAdStart <= adStart && newAdEnd >= adEnd);

                    if (isOverlap) {
                        toast.error("New Ad cannot overlap with an existing ad.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    return isOverlap;
                });
            }

            function isValidAdBreakAddition(scheduleItems, currentProgramIndex, newAdBreak) {
                const currentProgram = scheduleItems[currentProgramIndex];
    
                // Calculate total adBreaks duration including the new one
                const newEndAt = currentProgram.startAt + currentProgram.totalDuration + newAdBreak.duration;
    
                const overlappingItem = gridList.find(item => 
                    item.scheduleItems.some(schedule => {
                        const itemStart = schedule.startAt;
                        const itemEnd = schedule.endAt;
                        const objStart = currentProgram.startAt;
                        const objEnd = newEndAt;
    
                        return (
                            // Starts within an existing item's time
                            (objEnd >= itemStart && objEnd <= itemEnd)    // Ends within an existing item's time
                               // Fully covers an existing item's time
                        );
                    })
                );
                
                // Check if the new endAt exceeds the next program's startAt
                if (overlappingItem) {
                    toast.error(
                        `Ad break overlaps with the next program.`,
                        { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000 }
                    );
                    return false;
                }
    
                return true;
            }

            // Adding the new ad break with both checks
            const updatedGridList = gridList.map(item =>
                item.date === panelDate
                    ? {
                        ...item,
                        scheduleItems: item.scheduleItems.map((scheduleItem, index) =>
                            scheduleItem.sequence === Number(id)
                                ? {
                                    ...scheduleItem,
                                    adBreaks: !isOverlapping(scheduleItem.adBreaks, obj) &&
                                            isValidAdBreakAddition(item.scheduleItems, index, obj)
                                        ? [...scheduleItem.adBreaks, obj] // Add if valid
                                        : scheduleItem.adBreaks, // Keep original if invalid
                                }
                                : scheduleItem
                        ),
                    }
                    : item
            );

            addadsSqId(updatedGridList, panelDate);
            setHoverTime(null);
            return;
        }
        if (type == "ads" && value) {
            // const assetValidate = await adValidate(value["id"]);
            // if (!assetValidate?.status) {
            //     toast.error(assetValidate?.message, {
            //         position: toast.POSITION.BOTTOM_RIGHT,
            //         autoClose: 3000
            //     });
            //     return;
            // }

            obj = {
                assetId: value["id"],
                type: "filler",
                duration: value["duration"],
                startOffsetInMillis: verifyMilliSeconds(hoverTime),
                title: value["filename"],
                date: null,
            };

            // Function to check if the new ad break overlaps with existing ad breaks
            function isOverlapping(adBreaks, newAd) {
                return adBreaks.some(ad => {
                    const adStart = ad.startOffsetInMillis;
                    const adEnd = ad.startOffsetInMillis + ad.duration;
                    const newAdStart = newAd.startOffsetInMillis;
                    const newAdEnd = newAd.startOffsetInMillis + newAd.duration;

                    const isOverlap = 
                        (newAdStart >= adStart && newAdStart < adEnd) || 
                        (newAdEnd > adStart && newAdEnd <= adEnd) || 
                        (newAdStart <= adStart && newAdEnd >= adEnd);

                    if (isOverlap) {
                        toast.error("New Ad cannot overlap with an existing ad.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    return isOverlap;
                });
            }

            // Function to check if the new ad break causes the program to exceed its allowed end time
            function isValidAdBreakAddition(scheduleItems, currentProgramIndex, newAdBreak) {
                const currentProgram = scheduleItems[currentProgramIndex];
    
                // Calculate total adBreaks duration including the new one
                const newEndAt = currentProgram.startAt + currentProgram.totalDuration + newAdBreak.duration;
                
                const overlappingItem = gridList.find(item => 
                    item.scheduleItems.some(schedule => {
                        const itemStart = schedule.startAt;
                        const itemEnd = schedule.endAt;
                        const objStart = currentProgram.startAt;
                        const objEnd = newEndAt;                        
                        return (
                            // Starts within an existing item's time
                            (objEnd >= itemStart && objEnd <= itemEnd)    // Ends within an existing item's time
                               // Fully covers an existing item's time
                        );
                    })
                );
                // Check if the new endAt exceeds the next program's startAt
                if (overlappingItem) {
                    toast.error(
                        `Ad break overlaps with the next program.`,
                        { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000 }
                    );
                    return false;
                }
    
                return true;
            }

            // Adding the new ad break with both checks
            const updatedGridList = gridList.map(item =>
                item.date === panelDate
                    ? {
                        ...item,
                        scheduleItems: item.scheduleItems.map((scheduleItem, index) =>
                            scheduleItem.sequence === Number(id)
                                ? {
                                    ...scheduleItem,
                                    adBreaks: !isOverlapping(scheduleItem.adBreaks, obj) &&
                                            isValidAdBreakAddition(item.scheduleItems, index, obj)
                                        ? [...scheduleItem.adBreaks, obj] // Add if valid
                                        : scheduleItem.adBreaks, // Keep original if invalid
                                }
                                : scheduleItem
                        ),
                    }
                    : item
            );

            addadsSqId(updatedGridList, panelDate);
            setHoverTime(null);
            return;
        }
    }

    const getAssetInfo = (sequenceId, panelDate, isSplit) => {
        if (isSplit) {
            return;
        }
        // Find the schedule for the matching panelDate
        const matchingSchedule = gridList.find(item => item.date === panelDate);
        if (matchingSchedule && matchingSchedule.scheduleItems?.length > 0) {
            // Find the schedule item with the matching sequenceId
            const matchedAsset = matchingSchedule.scheduleItems.find(
                item => item.sequence === Number(sequenceId)
            );
            
            if (matchedAsset) {
                setAssetInfo({...matchedAsset, panelDate: panelDate, panelLength: matchingSchedule?.scheduleItems.length});
                setSeqId(sequenceId);
                setShowPopup(true);
            } else {
                // Handle case where sequenceId doesn't match any item
                const lastItem = matchingSchedule.scheduleItems[matchingSchedule.scheduleItems.length - 1];
                setAssetInfo({...matchedAsset, panelDate: panelDate, panelLength: matchingSchedule?.scheduleItems.length} || null);
                setSeqId(lastItem?.sequence || null);
                setShowPopup(true);
            }
        } else {
            // Handle case where no matching panelDate or empty scheduleItems
            setAssetInfo(null);
            setSeqId(null);
            setShowPopup(false);
        }
    };
    
    const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;
    const verifyMilliSeconds = (duration) => {
        const len = duration?.toString()?.split(":");
        if(len?.length > 1) {
          return parseInt(toMilliseconds(len[0], len[1], len[2]))
        } else {
          return parseInt(duration);
        }
    }

    const millisecondsToHuman = (duration) => {
        // if(duration === 0) return '00:00:00';
        let milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)));

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds ;
    }

    const calculateMinutes = (durationInMillis) => {
        const durationInMinutes = durationInMillis / 60000;
        // const roundedMinutes = Math.round(durationInMinutes); // Rounds to nearest minute
        return durationInMinutes;
    }

    function millisecondsSinceMidnightUTC(epochTime) {
        const date = new Date(epochTime);
    
        // Extract UTC hours, minutes, seconds, and milliseconds
        const utcHours = date.getUTCHours();
        const utcMinutes = date.getUTCMinutes();
        const utcSeconds = date.getUTCSeconds();
        const utcMilliseconds = date.getUTCMilliseconds();
    
        // Calculate milliseconds since midnight UTC
        const millisecondsSinceMidnight =
            (utcHours * 60 * 60 * 1000) + // Hours to milliseconds
            (utcMinutes * 60 * 1000) +   // Minutes to milliseconds
            (utcSeconds * 1000) +        // Seconds to milliseconds
            utcMilliseconds;             // Add remaining milliseconds
        return millisecondsSinceMidnight;
    }   
    
    const generateTimeSlots = () => {
        let timeSlotsArray = [];
        let currentHour = 0; // Start at 00:00
    
        while (currentHour < 24) {
            let hours = Math.floor(currentHour).toString().padStart(2, '0'); // Convert to 2-digit format
            let minutes = (currentHour % 1 === 0) ? '00' : '30'; // Set minutes to 00 or 30
            
            // Format the time as HH:MM
            let time = `${hours}:${minutes}`;
            timeSlotsArray.push(time);
    
            // Increment by 30 minutes
            currentHour += 0.5;
        }
        return timeSlotsArray;
    };

    const addToDragabbleListOld = (data, panelDate) => {
        // Find the index of the date in gridList that matches the panelDate
        const dateIndex = gridList.findIndex(item => item.date === panelDate);

        if (dateIndex !== -1) {
            // Get the scheduleItems array for the matching date
            const scheduleItems = gridList[dateIndex].scheduleItems;
    
            // Calculate the new sequence number
            const newSequence = scheduleItems.length > 0 
                ? Math.max(...scheduleItems.map(item => item.sequence)) + 1 
                : 1;
    
            // Create the new data object with the updated sequence number
            const newItem = { ...data, sequence: newSequence };
    
            // Update the gridList with the new schedule item
            const updatedGridList = [...gridList];
            updatedGridList[dateIndex].scheduleItems = [...scheduleItems, newItem];
    
            // Set the updated gridList
            // adjustScheduleItems(updatedGridList);
            setGridList(updatedGridList);
        } else {
            // If panelDate does not exist, create a new entry for it
            const newEntry = {
                date: panelDate,
                scheduleItems: [{ ...data, sequence: 1 }]
            };            
            setGridList([...gridList, newEntry]);
        }
    };

    const addToDragabbleList = (data, panelDate) => {
        // Find the index of the date in gridList that matches the panelDate
        const dateIndex = gridList.findIndex(item => item.date === panelDate);
    
        if (dateIndex !== -1) {
            // Get the scheduleItems array for the matching date
            const scheduleItems = gridList[dateIndex].scheduleItems;
    
            // Add the new data to the scheduleItems array
            const updatedItems = [...scheduleItems, data];
    
            // Sort the updatedItems by startAt time
            updatedItems.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
    
            // Update the sequence based on the sorted order
            const sortedItems = updatedItems.map((item, index) => ({
                ...item,
                sequence: index + 1
            }));
    
            // Update the gridList with the sorted and updated schedule items
            const updatedGridList = [...gridList];
            updatedGridList[dateIndex].scheduleItems = sortedItems;
    
            // Set the updated gridList
            // adjustScheduleItems(updatedGridList);
            setGridList(updatedGridList);
        } else {
            // If panelDate does not exist, create a new entry for it
            const newEntry = {
                date: panelDate,
                scheduleItems: [{ ...data, sequence: 1 }]
            };
            setGridList([...gridList, newEntry]);
        }
    };

    const handleZoomIn = () => {
        setZoomInOut((prevZoom) => Math.min(prevZoom + 1, 25));
    };
    
    const handleZoomOut = () => {
        setZoomInOut((prevZoom) => Math.max(prevZoom - 1, 1));
    };
    
    const fetchSchedule = async () => {
        setLoaderStatus(true);
        if(!locationState?.state?.channelId) {
            return;
        }
        const scheduleData = await Schedules_list(locationState?.state?.channelId, 'PROGRAM');
        setProgramList(scheduleData?.data);
        setOriginalProgramList(scheduleData?.data);

        const fillerData = await Schedules_list(locationState?.state?.channelId, 'FILLER');
        setFillerist(fillerData?.data);

        const promoData = await Schedules_list(locationState?.state?.channelId, 'PROMO');
        setPromoList(promoData?.data);

        const date = new Date();
        const setDate = 6;
        
        // Set the start date to 12 AM UTC
        const startDateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        
        // Calculate the end date by adding 'setDate' days and setting 12 AM UTC
        const endDateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + setDate, 0, 0, 0, 0);
        // expandPanelView(startDateUTC);
        const gridData = await Schedules_Datalist(locationState?.state?.channelId, startDateUTC, endDateUTC);
        const updatedGridList = gridData?.data?.map((item) => {
            let sequenceCounter = 1;
            const updatedScheduleItems = item?.scheduleItems?.map((scheduleItem, index) => {
                const updatedAdBreaks = scheduleItem?.adBreaks?.map((adBreak, adIndex) => ({
                    ...adBreak,
                    adSqId: adIndex + 1, // Add adSqId
                }));
        
                return {
                    id: scheduleItem.assetId, // Add id field
                    ...scheduleItem,
                    sequence: sequenceCounter++, // Remove sequence
                    adBreaks: updatedAdBreaks,
                };
            });
            
            return {
                date: item.date,
                // date: new Date(item.date).getTime(),
                scheduleItems: updatedScheduleItems,
            };
        });
        
        // Add empty dates for the range
        for (let i = 0; i <= setDate; i++) {
            const currentDate = new Date();
            currentDate.setDate(new Date(startDateUTC).getDate() + i);
            // Set the time to 12 AM (midnight)
            currentDate.setUTCHours(0, 0, 0, 0);

            const epochTimestamp = currentDate.getTime();
            // const formattedDate = currentDate.toISOString().slice(0, 10);
            
            // Check if date already exists in updatedGridList
            if (!updatedGridList?.some((entry) => entry.date === epochTimestamp)) {
                updatedGridList?.push({
                    date: epochTimestamp,
                    // date: new Date(formattedDate).getTime(),
                    scheduleItems: [], // Add empty scheduleItems
                });
            }
        }
        
        // Sort dates to maintain order
        updatedGridList?.sort((a, b) => new Date(a.date) - new Date(b.date));
        setGridList(updatedGridList);
        setLoaderStatus(false);
    }

    const update_schedule = async () => {
        setLoaderStatus(true);
        const formattedGridList = gridList.map(item => {
            const dateObj = new Date(item.date);
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to month as it's 0-indexed
            const day = dateObj.getDate().toString().padStart(2, '0'); // Padding single digit days with zero
            
            return {
                ...item,
                date: `${year}-${month}-${day}` // Format as yyyy-mm-dd
            };
        });

        const response = await Schedule_update(formattedGridList, locationState?.state?.channelId);
        if (response) {
            toast.success("Channel Updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            fetchSchedule();
            setLoaderStatus(false);
        } else {
            toast.error("Channel Update failed", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            setLoaderStatus(false);
        }
    }

    useEffect(() => {
        const slots = generateTimeSlots();
        setTimeSlots(slots);
        setSingleTimeSlots(slots);
    }, []);
    
    useEffect(() => {
        splitScheduleItems(gridList);
    },[gridList])

    useEffect(()=> {
        fetchSchedule();
    },[locationState])

    const formatTime = (epoch) => {
        if (!epoch) return "Invalid Time";
        const date = new Date(epoch);
        return date.toISOString().split('T')[1].split('.')[0]; // This will return time in 24-hour format (HH:mm:ss) in UTC
    };

    const handlecategoryTab = (tab) => {
        setCategoryTab(tab);
    };

    const selectedFiller = (id, adSqId, sequenceId, panelDate) => {
        const matchedFiller = fillerList.find(item => item.id === Number(id));
        const updatedGridList = gridList.map(dateItem => {
            if (dateItem.date === panelDate) {
                return {
                    ...dateItem,
                    scheduleItems: dateItem.scheduleItems.map(scheduleItem => {
                        if (scheduleItem.sequence === sequenceId) {
                            return {
                                ...scheduleItem,
                                adBreaks: scheduleItem.adBreaks.map(ad => {
                                    if (ad.adSqId === adSqId) {
                                        const assetId = matchedFiller["id"];
                                        const duration = matchedFiller["duration"];
                                        const title = matchedFiller["filename"];
                                        const assetType = "filler";
                                        const date = null;
                                        return {
                                            ...ad,
                                            assetId,
                                            duration,
                                            title,
                                            assetType,
                                            date,
                                        };
                                    }
                                    return ad;
                                }),
                            };
                        }
                        return scheduleItem;
                    }),
                };
            }
            return dateItem;
        });
        setGridList(updatedGridList);
        const updatedMatchingSchedule = updatedGridList.find(
            item => item.date === panelDate
        );
        const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
            item => item.sequence === sequenceId
        );
        if (matchedAsset) {
            setAssetInfo({ ...matchedAsset, panelDate: panelDate });
        }
    };

    const setAdsTime = (e, adSqId, sequenceId, panelDate) => {
        let convertTime = verifyMilliSeconds(e);
        const updatedGridList = gridList.map(item => {
            if (item.date === panelDate) { // Match the panelDate with the date in gridList
                return {
                    ...item,
                    scheduleItems: item.scheduleItems.map(schedule => {
                        if (schedule.sequence === sequenceId) { // Match the sequence inside scheduleItems
                            return {
                                ...schedule,
                                adBreaks: schedule.adBreaks.map(ad => {
                                    if (ad.adSqId === adSqId) { // Match adSqId inside adBreaks
                                        const startOffsetInMillis = convertTime;
                                        return {
                                            ...ad,
                                            startOffsetInMillis
                                        };
                                    }
                                    return ad;
                                })
                            };
                        }
                        return schedule;
                    })
                };
            }
            return item;
        });
        setGridList(updatedGridList);
        const updatedMatchingSchedule = updatedGridList.find(
            item => item.date === panelDate
        );
        const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
            item => item.sequence === sequenceId
        );
        if (matchedAsset) {
            setAssetInfo({ ...matchedAsset, panelDate: panelDate });
        }
    };

    const setProgramTime = (e, programUuid, sequenceId, panelDate) => {
        let combinedTimeInMillis = panelDate + verifyMilliSeconds(e);
        let isOverlap = false;
        let updatedGridList = [...gridList];
        
        updatedGridList = updatedGridList.map((grid) => {
            const updatedScheduleItems = grid.scheduleItems.map((scheduleItem) => {
                if (scheduleItem.uuid === programUuid) {
                    const updatedStartAt = combinedTimeInMillis;
                    const updatedEndAt = updatedStartAt + scheduleItem.totalDuration;
    
                    // Check for overlap with other schedule items in the entire gridList
                    const isConflict = updatedGridList.some((grid) => {
                        return grid.scheduleItems.some((item) => {
                            if (item.uuid !== scheduleItem.uuid) {
                                return (
                                    (updatedStartAt > item.startAt && updatedStartAt < item.endAt) || // Starts within another program
                                    (updatedEndAt > item.startAt && updatedEndAt < item.endAt) ||   // Ends within another program
                                    (updatedStartAt < item.startAt && updatedEndAt > item.endAt)  // Envelops another program
                                );
                            }
                            return false;
                        });
                    });
    
                    if (isConflict) {
                        isOverlap = true;
                    }
    
                    return {
                        ...scheduleItem,
                        startAt: updatedStartAt,
                        endAt: updatedEndAt,
                    };
                }
                return scheduleItem;
            });
    
            return {
                ...grid,
                scheduleItems: updatedScheduleItems,
            };
        });
    
        // If overlap detected, show error and exit early
        if (isOverlap) {
            toast.error("Program cannot overlap with an existing program.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            setPanelHoverTime(null);
            setPanelLinePosition(null);
            setPanelHoverDate(null);
            return;
        }
    
        // Sort scheduleItems by startAt and update sequence numbers
        updatedGridList = updatedGridList.map((grid) => {
            const sortedScheduleItems = grid.scheduleItems.sort((a, b) => a.startAt - b.startAt);
            return {
                ...grid,
                scheduleItems: sortedScheduleItems.map((item, index) => ({
                    ...item,
                    sequence: index + 1,
                })),
            };
        });
        // Update state
        setGridList(updatedGridList);
        const updatedMatchingSchedule = updatedGridList.find(
            item => item.date === panelDate
        );
        const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
            item => item.sequence === sequenceId
        );
        if (matchedAsset) {
            setAssetInfo({ ...matchedAsset, panelDate: panelDate });
        }
    }

    const deleteEvent = (seqId, sequenceId, panelDate) => {
        const updatedGridList = gridList.map(item => {
            if (item.date === panelDate) { // Match the panelData (date) with gridList
                return {
                    ...item,
                    scheduleItems: item.scheduleItems.map(schedule => {
                        if (schedule.sequence === sequenceId) { // Match the sequence inside scheduleItems
                            // Filter out the matching adSqId
                            const updatedAdBreaks = schedule.adBreaks
                                .filter(ad => ad.adSqId !== seqId) // Remove the ad with matching adSqId
                                .map((ad, index) => ({
                                    ...ad,
                                    adSqId: index + 1 // Rearrange adSqId
                                }));
    
                            // Recalculate total duration, startAt, and endAt
                            let totalAdBreakDuration = updatedAdBreaks.reduce((sum, ad) => sum + (ad.duration || 0), 0);
                            const totalDuration = totalAdBreakDuration + (schedule?.duration || 0);
                            const endTime = schedule?.startAt + (schedule?.duration || 0) + totalAdBreakDuration;
                            const adBreaksCount = updatedAdBreaks.length;
    
                            return {
                                ...schedule,
                                adBreaks: updatedAdBreaks,
                                totalDuration: totalDuration,
                                adBreaksCount: adBreaksCount,
                                startAt: schedule?.startAt,
                                endAt: endTime,
                            };
                        }
                        return schedule;
                    })
                };
            }
            return item;
        });
    
        // Update the gridList with the new data
        setGridList(updatedGridList);
        // Find the updated matching schedule to pass the panelDate and set asset info
        const updatedMatchingSchedule = updatedGridList.find(
            item => item.date === panelDate
        );
        const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
            item => item.sequence === sequenceId
        );
        if (matchedAsset) {
            setAssetInfo({ ...matchedAsset, panelDate: panelDate });
        }
    };

    const adAdsOnClick = (sequenceId, panelDate) => { 
        let value = locationState?.state?.defaultFiller;

        // Function to check if the new ad break overlaps with existing ad breaks
        function isOverlapping(adBreaks, newAd) {
            return adBreaks.some(ad => {
                const adStart = ad.startOffsetInMillis;
                const adEnd = ad.startOffsetInMillis + ad.duration;
                const newAdStart = newAd.startOffsetInMillis;
                const newAdEnd = newAd.startOffsetInMillis + newAd.duration;

                const isOverlap = 
                    (newAdStart >= adStart && newAdStart < adEnd) || 
                    (newAdEnd > adStart && newAdEnd <= adEnd) || 
                    (newAdStart <= adStart && newAdEnd >= adEnd);

                if (isOverlap) {
                    toast.error("New Ad cannot overlap with an existing ad.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    });
                }
                return isOverlap;
            });
        }
        
        function isValidAdBreakAddition(scheduleItems, currentProgramIndex, newAdBreak) {
            const currentProgram = scheduleItems[currentProgramIndex];

            // Calculate total adBreaks duration including the new one
            const newEndAt = currentProgram.startAt + currentProgram.totalDuration + newAdBreak.duration;

            const overlappingItem = gridList.find(item => 
                item.scheduleItems.some(schedule => {
                    const itemStart = schedule.startAt;
                    const itemEnd = schedule.endAt;
                    const objEnd = newEndAt;

                    return (
                        // Starts within an existing item's time
                        (objEnd >= itemStart && objEnd <= itemEnd)    // Ends within an existing item's time
                           // Fully covers an existing item's time
                    );
                })
            );
            
            // Check if the new endAt exceeds the next program's startAt
            if (overlappingItem) {
                toast.error(
                    `Ad break overlaps with the next program.`,
                    { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000 }
                );
                return false;
            }

            return true;
        }
    
        let updatedGridList = gridList.map(item => {
            // Match the grid item by panelDate
            if (item.date === panelDate) {
                // Match scheduleItems by sequenceId
                const updatedScheduleItems = item.scheduleItems.map((schedule, index) => {
                    if (schedule.sequence === Number(sequenceId)) {
                        const lastAdBreak = schedule.adBreaks[schedule.adBreaks.length - 1];
                        const lastStartOffset = lastAdBreak ? lastAdBreak?.startOffsetInMillis + lastAdBreak?.duration: 0;
                        const newStartOffset = lastStartOffset; // Adjust as needed for increment
    
                        let obj = {
                            adSqId: lastAdBreak?.adSqId + 1,
                            assetId: value["id"],
                            type: "filler",
                            duration: value["duration"],
                            startOffsetInMillis: newStartOffset,
                            title: value["filename"],
                            date: null,
                        };    
                        // Add the new adBreak to the schedule item
                        return { 
                            ...schedule, 
                            adBreaks: !isOverlapping(schedule.adBreaks, obj) &&
                                isValidAdBreakAddition(item.scheduleItems, index, obj)
                            ?[...schedule.adBreaks, obj] 
                            :schedule.adBreaks
                        };
                    }
                    return schedule;
                });
    
                // Return the updated item with modified scheduleItems
                return { ...item, scheduleItems: updatedScheduleItems };
            }
            return item;
        });
    
        // Update the gridList with the new data
        // setGridList(updatedGridList);
        addadsSqId(updatedGridList, panelDate);
    
        // Find the updated matching schedule to pass the panelDate and set asset info
        const updatedMatchingSchedule = updatedGridList.find(
            item => item.date === panelDate
        );
        const matchedAsset = updatedMatchingSchedule?.scheduleItems.find(
            item => item.sequence === sequenceId
        );
        if (matchedAsset) {
            setAssetInfo({ ...matchedAsset, panelDate: panelDate });
        }
    };

    const splitScheduleItemsOld = (gridList) => {
        const MS_IN_A_DAY = 86400000; // 24 hours in milliseconds
    
        // Deep clone gridList to avoid mutating the original data
        const clonedGridList = _.cloneDeep(gridList);
    
        for (let i = 0; i < clonedGridList?.length; i++) {
            let currentDay = clonedGridList[i];
            let nextDay = clonedGridList[i + 1];
    
            // Calculate totalDuration for current day's scheduleItems
            let totalDuration = currentDay.scheduleItems.reduce(
                (sum, item) => sum + item.totalDuration,
                0
            );
    
            // Check if totalDuration exceeds 24 hours
            if (totalDuration > MS_IN_A_DAY) {
                let overflow = totalDuration - MS_IN_A_DAY;
                let lastItem = currentDay.scheduleItems.pop(); // Remove the last item
    
                // Adjust last item's totalDuration to fit within 24 hours
                let adjustedDuration = lastItem.totalDuration - overflow;
    
                // Create a new object for the overflow
                let newObject = {
                    ...lastItem,
                    totalDuration: overflow,
                    sequence: (lastItem.sequence || 0) + 1 // Increment sequence
                };
    
                // Adjust the last item to fit within 24 hours
                lastItem.totalDuration = adjustedDuration;
    
                // Add the adjusted last item back to the current day's scheduleItems
                currentDay.scheduleItems.push(lastItem);
    
                // Add the new object to the next day's scheduleItems
                if (nextDay) {
                    nextDay.scheduleItems.unshift(newObject);
                } else {
                    // If no next day exists, create a new day
                    clonedGridList.push({
                        date: currentDay.date + MS_IN_A_DAY,
                        scheduleItems: [newObject]
                    });
                }
            }
        }
        setNewGridData(clonedGridList);
    }

    const splitScheduleItemsTest = (gridList) => {
        // Deep clone gridList to avoid mutating the original data
        const clonedGridList = _.cloneDeep(gridList);
    
        for (let i = 0; i < clonedGridList?.length; i++) {
            let currentDay = clonedGridList[i];
            let nextDay = clonedGridList[i + 1];
            let lastItem = currentDay.scheduleItems[currentDay.scheduleItems.length - 1]; // Last item of current day's scheduleItems
    
            if (lastItem && lastItem.endAt > currentDay.date + 86400000) { // Check if the item overflows the day boundary
                // Calculate overflow time (endAt - 24hrs boundary)
                let overflow = lastItem.endAt - (currentDay.date + 86400000);
    
                // Adjust last item's endAt time
                let adjustedEndAt = lastItem.endAt - overflow;
                let adjustedDuration = lastItem.totalDuration - overflow;
    
                // Split adBreaks for the first and second parts
                const splitAdBreaks = (adBreaks, duration, newStartAt) => {
                    let firstPartAdBreaks = [];
                    let secondPartAdBreaks = [];
    
                    for (const adBreak of adBreaks) {
                        if (adBreak.startOffsetInMillis < duration) {
                            // AdBreak belongs to the first part
                            firstPartAdBreaks.push(adBreak);
                        } else {
                            // Adjust the offset for the second part
                            secondPartAdBreaks.push({
                                ...adBreak,
                                startOffsetInMillis: adBreak.startOffsetInMillis - duration
                            });
                        }
                    }
    
                    return { firstPartAdBreaks, secondPartAdBreaks };
                };
    
                const { firstPartAdBreaks, secondPartAdBreaks } = splitAdBreaks(
                    lastItem.adBreaks || [],
                    adjustedDuration,
                    adjustedEndAt
                );
    
                // Create a new object for the overflow
                let newObject = {
                    ...lastItem,
                    startAt: adjustedEndAt, // New start time for the overflow
                    endAt: lastItem.endAt,  // Original end time remains
                    totalDuration: overflow,
                    adBreaks: secondPartAdBreaks, // Assign second part adBreaks
                    isSplit: true,
                };
    
                // Update the last item's properties
                lastItem.endAt = adjustedEndAt;
                lastItem.totalDuration = adjustedDuration;
                lastItem.adBreaks = firstPartAdBreaks;
    
                // Add the new object to the next day's scheduleItems
                if (nextDay) {
                    nextDay.scheduleItems.unshift(newObject);
                } else {
                    // If no next day exists, create a new day
                    clonedGridList.push({
                        date: currentDay.date + 86400000,
                        scheduleItems: [newObject]
                    });
                }
            }
        }
        setNewGridData(clonedGridList);
    };
     
    const splitScheduleItems = (gridList) => {
        // Deep clone gridList to avoid mutating the original data
        const clonedGridList = _.cloneDeep(gridList);
    
        for (let i = 0; i < clonedGridList?.length; i++) {
            let currentDay = clonedGridList[i];
            let nextDay = clonedGridList[i + 1];
            let lastItem = currentDay.scheduleItems[currentDay.scheduleItems.length - 1]; // Last item of current day's scheduleItems
    
            if (lastItem && lastItem.endAt > currentDay.date + 86400000) { // Check if the item overflows the day boundary
                // Define 23:59:59 for the current day
                const dayBoundary = currentDay.date + 86400000; // 24:00:00 in milliseconds

                // Calculate overflow time (endAt - 24:00:00)
                let overflow = lastItem.endAt - dayBoundary;
    
                // Adjust last item's endAt time to 24:00:00
                let adjustedEndAt = dayBoundary;
                let adjustedDuration = lastItem.totalDuration - overflow;
    
                // Split adBreaks for the first and second parts
                const splitAdBreaks = (adBreaks, duration, newStartAt) => {
                    let firstPartAdBreaks = [];
                    let secondPartAdBreaks = [];
    
                    for (const adBreak of adBreaks) {
                        if (adBreak.startOffsetInMillis < duration) {
                            // AdBreak belongs to the first part
                            firstPartAdBreaks.push(adBreak);
                        } else {
                            // Adjust the offset for the second part
                            secondPartAdBreaks.push({
                                ...adBreak,
                                startOffsetInMillis: adBreak.startOffsetInMillis - duration
                            });
                        }
                    }
    
                    return { firstPartAdBreaks, secondPartAdBreaks };
                };
    
                const { firstPartAdBreaks, secondPartAdBreaks } = splitAdBreaks(
                    lastItem.adBreaks || [],
                    adjustedDuration,
                    adjustedEndAt
                );
    
                // Create a new object for the overflow
                let newObject = {
                    ...lastItem,
                    startAt: dayBoundary + 1, // New start time for the overflow
                    endAt: lastItem.endAt,   // Original end time remains
                    totalDuration: overflow,
                    adBreaks: secondPartAdBreaks, // Assign second part adBreaks
                    isSplit: true,
                };
    
                // Update the last item's properties
                lastItem.endAt = adjustedEndAt; // Set endAt to 23:59:59
                lastItem.totalDuration = adjustedDuration;
                lastItem.adBreaks = firstPartAdBreaks;
    
                // Add the new object to the next day's scheduleItems
                if (nextDay) {
                    nextDay.scheduleItems.unshift(newObject);
                } else {
                    // If no next day exists, create a new day
                    clonedGridList.push({
                        date: currentDay.date + 86400000,
                        scheduleItems: [newObject]
                    });
                }
            }
        }
        setNewGridData(clonedGridList);
    };    

    const expandPanelView = (panelDate) => {
        if (newGridData?.length) {
            const matchedObject = newGridData.find(item => item.date === panelDate);
            if (matchedObject) {
                setExpandViewData(matchedObject);
                setExpendedViewDate(panelDate);
                setViewType("expandView");
            }
        }
    };

    const defaultView = () => {
        setViewType("defaultView");
    }

    const copyDataFun = (panelDate) => {
        const copyObject = gridList.find(item => item.date === panelDate);
        if (copyObject) {
            setCopyData(copyObject?.scheduleItems);
        } else {
            toast.error("No match found.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
        }
    }

    const pasteDataFun = (panelDate) => {
        let previousEndAt = panelDate; // Initialize with panelDate (12 AM)
        const allExistingUUIDs = new Set(
            gridList.flatMap(item => item.scheduleItems.map(schedule => schedule.uuid))
        );
        const newUUIDs = new Set();
    
        const adjustedCopyData = copyData.map((item, index) => {
            const startAt = index === 0 ? panelDate : previousEndAt + 1000; // Add 1 second for subsequent items
            const endAt = startAt + item.totalDuration;
    
            // Update previousEndAt for the next iteration
            previousEndAt = endAt;
    
            // Generate a unique UUID
            let newUUID;
            do {
                newUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0;
                    const v = c === 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            } while (allExistingUUIDs.has(newUUID) || newUUIDs.has(newUUID));
    
            // Add the new UUID to the set
            newUUIDs.add(newUUID);
    
            return { ...item, startAt, endAt, uuid: newUUID };
        });
    
        // Merge adjusted data into gridList
        const updatedGridList = gridList.map((gridItem) => {
            if (gridItem.date === panelDate) {
                return {
                    ...gridItem,
                    scheduleItems: [...gridItem.scheduleItems, ...adjustedCopyData],
                };
            }
            return gridItem;
        });
    
        // Update the state
        setGridList(updatedGridList);
        setCopyData([]);
    };

    const trim_programs_space = ((date) => {
        if (date) {
            setErrorMessage(`Are you sure you want to trim the spaces in '${new Date(date).toLocaleDateString("en-US", { weekday: "short",month: "short",day: "2-digit", })}'`)
        } else {
            setErrorMessage(`Are you sure you want to trim the spaces between all the scheduled programs?`)
        }
        setErrorbox(true);
        seteventTrim(() => () => trimPrograms(date))
    })

    const trimPrograms = ((panelDate) => {
        if (panelDate) {
            const updatedGridList = gridList.map((entry) => {
                if (entry.date === panelDate) {
                    let previousEndAt = 0; // To keep track of the end time of the previous program
                    
                    entry.scheduleItems = entry.scheduleItems.map((program, index) => {
                        const programStartAt = new Date(entry.date); // Start at midnight of the matching date
                        
                        programStartAt.setUTCHours(0, 0, 0, 0); // Set to 00:00:00 UTC
                        const previousDayIndex = gridList.findIndex(item => item.date === (entry.date));
                        if (index === 0) {
                            if (previousDayIndex - 1 !== -1) {
                                let previousDaySchedules = gridList[previousDayIndex - 1]?.scheduleItems || [];
                                previousDaySchedules = previousDaySchedules[previousDaySchedules.length - 1];
                                if (previousDaySchedules?.endAt >= panelDate) {
                                    programStartAt.setTime(previousDaySchedules?.endAt);
                                }
                            }
                        }
                        
                        // If it's not the first program, update the start time based on previous program's end time
                        if (index !== 0) {
                            programStartAt.setTime(previousEndAt);
                        }
        
                        const programEndAt = new Date(programStartAt).getTime() + program.totalDuration; // Calculate end time by adding total duration
                        previousEndAt = programEndAt; // Update previousEndAt for the next iteration
        
                        // Update program with new startAt and endAt
                        return {
                            ...program,
                            startAt: programStartAt.getTime(),
                            endAt: programEndAt
                        };
                    });
                }
        
                return entry;
            });
            setGridList(updatedGridList);
        } else {
            const updatedGridList = gridList.map((entry) => {
                let previousEndAt = 0; // To keep track of the end time of the previous program
                
                entry.scheduleItems = entry.scheduleItems.map((program, index) => {
                    const programStartAt = new Date(entry.date); // Start at midnight of the current entry's date
                    programStartAt.setUTCHours(0, 0, 0, 0); // Set to 00:00:00 UTC
                    
                    const previousDayIndex = gridList.findIndex(item => item.date === (entry.date));
                    
                    if (index === 0) {
                        if (previousDayIndex - 1 !== -1) {
                            let previousDaySchedules = gridList[previousDayIndex - 1]?.scheduleItems || [];
                            previousDaySchedules = previousDaySchedules[previousDaySchedules.length - 1];
                            if (previousDaySchedules?.endAt >= entry?.date) {
                                programStartAt.setTime(previousDaySchedules?.endAt);
                            }
                        }
                    }
                    
                    // If it's not the first program, update the start time based on previous program's end time
                    if (index !== 0) {
                        programStartAt.setTime(previousEndAt);
                    }
        
                    const programEndAt = new Date(programStartAt).getTime() + program.totalDuration; // Calculate end time by adding total duration
                    previousEndAt = programEndAt; // Update previousEndAt for the next iteration
        
                    // Update program with new startAt and endAt
                    return {
                        ...program,
                        startAt: programStartAt.getTime(),
                        endAt: programEndAt
                    };
                });
        
                return entry;
            });
        
            setGridList(updatedGridList);
        }
    })

    const errorboxclose = () => {
        setErrorbox(false);
    }
    return (
        <>
            {viewType === "defaultView" && (
                <div className={`content-body SchedulingDetail SchedulingLoop scheduling-loop-content-body ${appTheme}`}>
                    <div className="dashboard-content flex schedule-loop-view">
                        <div className="left">
                            <div>
                                <h3 className='schedule-title-header title-header mb-3'>Scheduling</h3>
                                <div className='list-box'>
                                    <div className="search-container" style={{height: '44px'}}>
                                        <input
                                            id="search-input"
                                            className="search-box"
                                            type="text"
                                            placeholder="Search program"
                                            style={{
                                            width: '100%',
                                            height: '40px',
                                            color: appTheme === "dark" ? '#ffffff' : 'black',
                                            borderRadius: '6px',
                                            padding: '0 10px',
                                            }}
                                            value={searchedText}
                                            onChange={(e)=>{searchProgram(e.target.value)}}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    searchProgram(e.target.value); // Trigger the button's onClick function
                                                }
                                            }}
                                        />
                                        <button
                                            className="search-button"
                                            aria-label="Search"
                                            style={{
                                            border: 'none',
                                            cursor: 'pointer',
                                            padding: '0',
                                            backgroundColor: 'transparent',
                                            color: appTheme === "dark" ? '#ffffff' : 'black',
                                            }}
                                        >
                                            <img
                                            src={icroundsearch}
                                            alt="Search"
                                            className="search-icon"
                                            style={{
                                                filter: appTheme === "dark" ? 'invert(100%) brightness(150%)' : 'none',
                                            }}
                                            />
                                        </button>
                                    </div>
        
                                    <div className="channel-listing-cseq">
                                        <div className="list-wrapper" onDragStart={channelDragStart} >
                                            {['Programs', 'Promos', 'Fillers'].map((tab, index) => {
                                                return (
                                                        <div
                                                            key={index}
                                                            className={`item-wrapper ${
                                                                tab == selectedTab ? "active" : ""
                                                            }`}
                                                        >
                                                            <div
                                                                className="list-item"
                                                                data-id={index}
                                                                onClick={(e) => {
                                                                    tab == selectedTab
                                                                    ? setSelectedTab("")
                                                                    : setSelectedTab(tab);
                                                                }}
                                                            >
                                                            <img src={ellipse} width={10} />
                                                            <div className="channel-name" data-id={index}>
                                                            {tab}
                                                            </div>
                                                            <span className="channel-arrow"></span>
                                                        </div>
                                                        <ul className="nexted-list">
                                                            {
                                                                tab === "Programs" ?
                                                                    programList?.length?
                                                                    programList?.map((data, index) => {
                                                                        return (
                                                                            <li
                                                                                key={`${index}`}
                                                                                className="nexted-list-item"
                                                                                data-type="programs"
                                                                                draggable={locationState?.state?.permission === "edit"? true : false}
                                                                                // draggable={true}
                                                                                data-value={JSON.stringify(data)}
                                                                                data-key={index}
                                                                                onDoubleClick={() => {handelProgramDoubleClick(data, index)}}
                                                                                title={data?.metadata?.title}
                                                                            >
                                                                                <span className="program-name" data-key={index}>
                                                                                    {data?.metadata?.title}
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <span className="program-name text-center no-list-data">
                                                                        No programs available.
                                                                    </span>
                                                                :
                                                                tab === "Promos" ?
                                                                    promoList?.length?
                                                                    promoList?.map((data, index) => {
                                                                        return (
                                                                            <li
                                                                                key={`${index}`}
                                                                                className="nexted-list-item"
                                                                                data-type="promos"
                                                                                draggable={locationState?.state?.permission === "edit"? true : false}
                                                                                // draggable={true}
                                                                                data-value={JSON.stringify(data)}
                                                                                data-key={index}
                                                                                title={data?.metadata?.title}
                                                                            >
                                                                                <span className="program-name" data-key={index}>
                                                                                    {data?.metadata?.title}
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <span className="program-name text-center no-list-data">
                                                                        No promos available.
                                                                    </span>
                                                                :
                                                                tab === "Fillers"?
                                                                    fillerList?.length?
                                                                    fillerList?.map((data, index) => {
                                                                        return (
                                                                            <li
                                                                                key={`${index}`}
                                                                                className="nexted-list-item"
                                                                                data-type="fillers"
                                                                                draggable={locationState?.state?.permission === "edit"? true : false}
                                                                                // draggable={true}
                                                                                data-value={JSON.stringify(data)}
                                                                                data-key={index}
                                                                                title={data?.filename}
                                                                            >
                                                                                <span className="program-name" data-key={index}>
                                                                                    {data?.filename}
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    :
                                                                    <span className="program-name text-center no-list-data">
                                                                        No fillers available.
                                                                    </span>
                                                                :
                                                                ''
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
        
                                    <div className="ads mt-5" onDragStart={channelDragStart}>
                                        <button className="btn btn-danger w-100" data-type="ads" data-duration='00:00:30' data-value={JSON.stringify(locationState?.state?.defaultFiller)} draggable="true">
                                            + Ads
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 right" style={{width: "83%"}}>
                            <div className="border-right-container p-2">
                                <div className="top-content">
                                    <div className="left-side">
                                        <h3>{locationState?.state?.channelName}</h3>
                                    </div>
                                    <div className="right-side d-flex">
                                        <a className='btn btn-revert mx-2 p-1 bg-secondary'><img src={revertIcon} width={35}/></a>
                                        {
                                            locationState?.state?.permission === "edit" &&
                                            <>
                                                <button className='btn btn-primary mx-2' onClick={update_schedule}>Update</button>
                                                <a className='btn btn-revert mx-2 p-1 bg-secondary' onClick={()=>trim_programs_space()}><img src={trim_svg} width={35}/></a>
                                            </>
                                        }
                                        <button className='btn btn-danger mx-2'>Auto Schedule</button>
                                    </div>
                                </div>
                                <div className="bottom-content mt-2 d-flex schedule-detail-container-scroll" style={{height: "90vh"}}>
                                    <div className="middle-content col-1" style={{height: `${(30*timeSlots.length*zoomInOut + 220)}px`}}>
                                        <div className="border-middle-container rounded border-0">
                                            <div className="middle-up row justify-content-center">
                                                <div class="d-flex flex-column align-items-center zoom col-2">
                                                    <a class="btn btn-secondary btn-circle" onClick={handleZoomIn}>
                                                        <img src={zoomInIcon} width={30}/>
                                                    </a>
                                                    <div class="vertical-line my-2"></div>
                                                    <a class="btn btn-secondary btn-circle" onClick={handleZoomOut}>
                                                        <img src={zoomOutIcon} width={30}/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="middle-down mt-3">
                                                <div className="content-wrapper" style={{height: 'auto', overflow: "hidden"}}>
                                                    <table className="scheduling-table tbl-50-per">
                                                        <tbody>
                                                            <div className="row w-100">
                                                                {/* Render timeSlots in a separate loop */}
                                                                <div className="time-slots-container col-2 p-2 text-center" style={{height: `${(30*timeSlots.length*zoomInOut)}px`}}>
                                                                    {timeSlots?.map((slot, index) => (
                                                                        <div 
                                                                            key={index}
                                                                            style={{ width: "100px", height: `${30 * zoomInOut}px` }}
                                                                            className="time-slot"
                                                                        >
                                                                            <p>{slot}</p>  {/* Customize this to display relevant time slot info */}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* Render gridList as you already have it */}
                                                            </div>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {gridList?.length !== 0 &&
                                    newGridData
                                    // gridList
                                        ?.map((item) => {
                                            return (
                                            <div className="middle-content col-3 px-1" style={{height: `${(30*timeSlots.length*zoomInOut + 220)}px`}}>
                                                <div className="border-middle-container rounded" >
                                                    <div className="middle-up row">
                                                        <div className="schedule-details col-12">
                                                            <div>
                                                                <div className="d-flex justify-content-between">
                                                                    <span className="text-white">
                                                                        <span style={{ fontSize: "16px", color: "#999999" }}>
                                                                            {new Date(item?.date).toLocaleDateString("en-US", { weekday: "short" })}
                                                                        </span>
                                                                        {" "}
                                                                        <span style={{ fontSize: "20px"}}>
                                                                            {new Date(item?.date).toLocaleDateString("en-US", {
                                                                            month: "short",
                                                                            day: "2-digit",
                                                                            })}
                                                                        </span>
                                                                    </span>
                                                                    <div className="d-flex justify-content-between">
                                                                        {
                                                                            copyData?.length && item?.scheduleItems?.length === 0 ?
                                                                            <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {pasteDataFun(item?.date)}}>
                                                                                <img src={pasteIcon} width= {20}/>
                                                                            </a>
                                                                            :
                                                                            <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {copyDataFun(item?.date)}}>
                                                                                <img src={copyIcon} width= {20}/>
                                                                            </a>
                                                                        }
                                                                        <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {trim_programs_space(item?.date)}}>
                                                                            <img src={trim_svg} width= {20}/>
                                                                        </a>
                                                                        {/* <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {expandPanelView(item?.date)}}>
                                                                            <img src={expandIcon} width={20}/>
                                                                        </a> */}
                                                                    </div>    
                                                                </div>
                                                                <table className={`table table-striped table-hover ${appTheme === "light"? "border":""}`}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Total scheduled</th>
                                                                            <th className="text-center">Number</th>
                                                                            <th className="text-center">HH:MM:SS</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Total Scheduled</td>
                                                                            <td className="text-center">{item?.scheduleItems.length + item?.scheduleItems?.reduce((total, item) => total + (item?.adBreaksCount || 0), 0)}</td>
                                                                            <td className="text-center">{millisecondsToHuman(item?.scheduleItems?.reduce((total, data) => total + (data?.totalDuration || 0), 0))}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Videos</td>
                                                                            <td className="text-center">{item?.scheduleItems.length}</td>
                                                                            <td className="text-center">{millisecondsToHuman(item?.scheduleItems?.reduce((total, data) => total + (data?.duration || 0), 0))}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Ads</td>
                                                                            <td className="text-center">{item?.scheduleItems.reduce((total, item) => total + (item.adBreaksCount || 0), 0)}</td>
                                                                            <td className="text-center">{millisecondsToHuman(item?.scheduleItems?.flatMap(item => item.adBreaks || []).reduce((total, adBreak) => total + (adBreak.duration || 0), 0))}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Promos</td>
                                                                            <td className="text-center">0</td>
                                                                            <td className="text-center">00:00:00</td>
                                                                        </tr>
                                                                        {/* <tr>
                                                                            <td>Secondary</td>
                                                                            <td className="text-center">0</td>
                                                                            <td className="text-center">00:00:00</td>
                                                                        </tr> */}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="middle-down">
                                                        <div className="content-wrapper" style={{height: 'auto'}}>
                                                            <table className="scheduling-table tbl-50-per">
                                                                <tbody
                                                                    data-type="table-body"
                                                                    role="none"
                                                                    onDragStart={handleDragStart}
                                                                    onDragOver={(e) => handleDragOver(e, item?.date)}
                                                                    onDrop={(e) => {handleDrop(e, item?.date)}}
                                                                >
                                                                    <div className="row w-100 m-0 h-100">
                                                                        <div className="grid-list-container col-12 p-2" style={{height: `${(30*timeSlots.length*zoomInOut + 10)}px`, position: 'relative'}}>
                                                                            {panelLinePosition !== null && panelHoverDate === item?.date && (draggableType === "programs" || draggableType === "draggable-list") && (
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        pointerEvents: "none",
                                                                                        top: `${panelLinePosition}px`,
                                                                                        zIndex: "99999",
                                                                                        left: "-8",
                                                                                        width: "270px",
                                                                                        height: "2px",
                                                                                        backgroundColor: "red",
                                                                                        pointerEvents: "none"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            zIndex: "99999999",
                                                                                            top: "-22px",
                                                                                            left: "0px",
                                                                                            backgroundColor: "white",
                                                                                            padding: "2px 5px",
                                                                                            fontSize: "12px",
                                                                                        }}
                                                                                    >
                                                                                        {panelHoverTime} min
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                            {item?.length !== 0 ?
                                                                                item?.scheduleItems?.map((data, index) => {
                                                                                    return (
                                                                                        <div 
                                                                                            className="d-flex px-2" 
                                                                                            onDragOver={(e) => handleDragOver2(e, data?.uuid)}
                                                                                            style={{top: `${calculateMinutes(millisecondsSinceMidnightUTC(data?.startAt))*zoomInOut}px`,position: "absolute", zIndex: `${data?.sequence}` }}
                                                                                        >
                                                                                            <div
                                                                                                style={{height: `${calculateMinutes(data?.totalDuration)*zoomInOut}px`, width: "36px", position:"relative"}}
                                                                                            >   
                                                                                                {linePosition !== null && hoveredId === data?.uuid && (draggableType === "fillers" || draggableType === "ads") && (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            position: "absolute",
                                                                                                            pointerEvents: "none",
                                                                                                            top: `${linePosition}px`,
                                                                                                            zIndex: "9999",
                                                                                                            left: "-8",
                                                                                                            width: "270px",
                                                                                                            height: "2px",
                                                                                                            backgroundColor: "blue",
                                                                                                            pointerEvents: "none"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                position: "absolute",
                                                                                                                zIndex: "99999999",
                                                                                                                top: "-22px",
                                                                                                                left: "0px",
                                                                                                                backgroundColor: "white",
                                                                                                                padding: "2px 5px",
                                                                                                                fontSize: "12px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {hoverTime} min
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )}      
                                                                                                {
                                                                                                    data?.adBreaks?.length !== 0?
                                                                                                        data?.adBreaks?.map((data) => {
                                                                                                            if (data?.type === "ad" || data?.type === "filler") {
                                                                                                                return (
                                                                                                                    <span style={{position: "absolute" , zIndex: `${data?.adSqId}`, top: `${calculateMinutes(data?.startOffsetInMillis)*zoomInOut}px`, height: "0px", left:"-0.5vw"}}>
                                                                                                                        <img src={adMarkers} width={35} style={{position: "absolute", top: "-4px"}}/>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            }
                                                                                                            else if (data?.type === "promo") {
                                                                                                                return (
                                                                                                                    <span style={{position: "absolute" , zIndex: `${data?.adSqId}`, top: `${calculateMinutes(data?.startOffsetInMillis)*zoomInOut}px`, height: "0px"}}>
                                                                                                                        <img src={adMarkers} width={35} style={{position: "absolute", top: "-4px", filter: "invert(30%) sepia(90%) saturate(400%) hue-rotate(75deg) brightness(90%) contrast(90%)"}}/>
                                                                                                                    </span>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    :
                                                                                                    ''
                                                                                                }
                                                                                                
                                                                                                <tr
                                                                                                    className="dragabble"
                                                                                                    onClick={() => {getAssetInfo(data?.sequence, item?.date, data?.isSplit)}}
                                                                                                    draggable={locationState?.state?.permission === "edit"? true : false}
                                                                                                    // draggable={true}
                                                                                                    data-type="draggable-list"
                                                                                                    key={`${index}`}
                                                                                                    data-value={JSON.stringify({date:item.date, ...data})}
                                                                                                    data-id={data?.sequence}
                                                                                                    style={{
                                                                                                        height: `${calculateMinutes(
                                                                                                          data?.totalDuration
                                                                                                            ? data?.totalDuration
                                                                                                            : data?.durationInMillis +
                                                                                                              (data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)
                                                                                                        ) * zoomInOut}px`,
                                                                                                        width: "13vw", // Set width relative to the viewport width
                                                                                                        position: "absolute",
                                                                                                        left: "1.5vw", // Adjust left position relative to the viewport width
                                                                                                        overflow: "hidden",
                                                                                                      }}
                                                                                                    title={data?.title}
                                                                                                >
                                                                                                    <td data-id={data?.sequence} style={{ background: index % 4 === 0 ? "#FFC374" : index % 4 === 1 ? "#4AD2C9" : index % 4 === 2 ? "#8E73EF" : "#536EFF" }}>
                                                                                                        <div className="table-content2" style={{ background: "rgba(255, 255, 255, 0.65)", pointerEvents: "none"}}>
                                                                                                            <div className="title-schedule-container" style={{width: "70%"}}> 
                                                                                                                <p className="px-2 fw-bold m-0 program-title" style={{color: "#212529"}}>
                                                                                                                    {data?.title}
                                                                                                                </p>
                                                                                                                <p className="px-2 fw-bold schedule-time" style={{color: "#212529"}}>
                                                                                                                    {`${formatTime(data?.startAt)} -  ${formatTime(data?.endAt)}`}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="position-relative">
                                                                                                                <a className="p-2 table-icon position-absolute" onClick={(e) => {handleProgramDelete(e, data?.uuid, item?.date)}} style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                                                                                                    <img src={deleteIcon} width={18} />
                                                                                                                </a>
                                                                                                                <a className="p-2 table-adbreaks-count position-absolute" style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                                                                                                    <span>{data?.adBreaks.length}</span>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                :
                                                                                <td colspan="5" class="text-center no-data">
                                                                                    No data has been scheduled.
                                                                                    {/* Nothing scheduled yet */}
                                                                                </td>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <AdsInfo
                        show={showPopup}
                        handleClose={closePopup}
                        assetInfo={assetInfo}
                        millisecondsToHuman={millisecondsToHuman}
                        formatTime={formatTime}
                        appTheme={appTheme}
                        categoryTab={categoryTab}
                        handlecategoryTab={handlecategoryTab}
                        permission={locationState?.state?.permission}
                        selectedFiller={selectedFiller}
                        fillerList={fillerList}
                        setAdsTime={setAdsTime}
                        deleteEvent={deleteEvent}
                        getAssetInfo={getAssetInfo}
                        adAdsOnClick={adAdsOnClick}
                        handleProgramDelete={handleProgramDelete}
                        setProgramTime={setProgramTime}
                    />
                </div>
            )}
            {viewType === "expandView" && (
                <ExpandView
                    expandViewData={expandViewData}
                    defaultView={defaultView}
                    appTheme={appTheme}
                    channelDragStart={channelDragStart}
                    searchProgram={searchProgram}
                    searchedText={searchedText}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    ellipse={ellipse}
                    programList={programList}
                    locationState={locationState}
                    handelProgramDoubleClick={handelProgramDoubleClick}
                    promoList={promoList}
                    fillerList={fillerList}
                    update_schedule={update_schedule}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    millisecondsToHuman={millisecondsToHuman}
                    pasteDataFun={pasteDataFun}
                    copyDataFun={copyDataFun}
                    copyData={copyData}
                    handleDragStart={handleDragStart}
                    handleDragOver={handleDragOver}
                    handleDrop={handleDrop}
                    timeSlots={timeSlots}
                    zoomInOut={zoomInOut}
                    handleDragOver2={handleDragOver2}
                    calculateMinutes={calculateMinutes}
                    linePosition={linePosition}
                    hoveredId={hoveredId}
                    hoverTime={hoverTime}
                    getAssetInfo={getAssetInfo}
                    assetInfo={assetInfo}
                    handleProgramDelete={handleProgramDelete}
                    formatTime={formatTime}
                />
            )}
            <ToastContainer />
            {
                loaderStatus &&
                <div className="loader-container">
                    <div className="loader"></div>
                    <p></p>
                </div>
            }
            { errorbox &&
                <ErrorPopup
                    btntext={"Trim"}
                    message={errorMessage}
                    closebox={() => errorboxclose()}
                    overrides={() => eventTrim()}
                />
            }
        </>    
    )
}

export default SchedulingDetailsNew;