import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/css/style.scss";
import "./channelCreation.scss";
import { TagsInput } from "react-tag-input-component";
import Multiselect from "multiselect-react-dropdown";
import closeChip from "../../assets/images/close-chip.svg";
import dropdownArrow from "../../assets/images/dropdown-arrow.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Partner_list,
  ChannelType_list,
  Language_list,
  Categories_list,
  Theme_list,
  Country_list,
  FillerList_By_Partner,
  Channel_create,
  GetChannelById,
  Channel_update,
  FillerList,
  getAllFillers,
} from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { useSelector } from "react-redux";

import FileUploaderContainer from "../../component/File-Uploader-Container/FileUploaderContainer";
import ErrorPopup from "../../component/popup/ErrorPopup";
import { epgHandlersList } from "../../api/api";
import { permissionDetails } from "../../utility/localStoageData";
import { channelSetupMetaData } from "../../utility/xmlData";
import CDNPopUp from "../../component/popup/CDNPopUp";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import TextareaAutosize from "../../component/TextareaAutosize";

const AssetPartnerDetail = JSON.parse(
  localStorage.getItem("AssetPartnerDetail")
);

function ChannelCreation() {
  const state = useSelector((state) => state);
  const Territorys = state?.CountryList?.data?.data;
  const Ratings = state?.MaturityList?.data;




  const locationState = useLocation();

  const defaultFileUploaded = {
    Mobile: {
      thumbnail: {
        file: null,
        multiPart: null,
        type: "Mobile",
        subType: "thumbnail",
      },
      poster: {
        file: null,
        multiPart: null,
        type: "Mobile",
        subType: "poster",
      },
      coverart: {
        file: null,
        multiPart: null,
        type: "Mobile",
        subType: "coverart",
      },
    },
    Tablet: {
      thumbnail: {
        file: null,
        multiPart: null,
        type: "Tablet",
        subType: "thumbnail",
      },
      poster: {
        file: null,
        multiPart: null,
        type: "Tablet",
        subType: "poster",
      },
      coverart: {
        file: null,
        multiPart: null,
        type: "Tablet",
        subType: "coverart",
      },
    },
    TV: {
      thumbnail: {
        file: null,
        multiPart: null,
        type: "TV",
        subType: "thumbnail",
      },
      poster: { file: null, multiPart: null, type: "TV", subType: "poster" },
      coverart: {
        file: null,
        multiPart: null,
        type: "TV",
        subType: "coverart",
      },
    },
    Web: {
      thumbnail: {
        file: null,
        multiPart: null,
        type: "Web",
        subType: "thumbnail",
      },
      poster: {
        file: null,
        immultiPartg: null,
        type: "Web",
        subType: "poster",
      },
      coverart: {
        file: null,
        immultiPartg: null,
        type: "Web",
        subType: "coverart",
      },
    },
    Partner: {
      thumbnail: {
        file: null,
        multiPart: null,
        type: "Partner",
        subType: "thumbnail",
      },
      poster: {
        file: null,
        immultiPartg: null,
        type: "Partner",
        subType: "poster",
      },
      coverart: {
        file: null,
        immultiPartg: null,
        type: "Partner",
        subType: "coverart",
      },
    },
  };

  // Default Form Value on Initial Load
  const defaultFormValue = {
    channelId: "",
    name: "",
    type: "Loop",
    description: "",
    goLiveDate: new Date(),
    channelTags: [],
    playbackUrl: "",
    territoryDetails: [
      {
        territoryId: "IN",
        territoryName: "India",
      },
    ],
    fillerSourcePath: "",
    regionalLanguages: "2",
    theme: [],
    channelImages: { ...defaultFileUploaded },
    categories: [],
    partners: [],
    channelSetupDate: new Date(),
    channelAssembly: "AWS",
    isStartOverEnabled: true,
    isWatchListEnabled: true,
    isForceLogin: false,
    forceLoginWatchDuration: "0",
    epgHandler: "",
    epgType: "",
    playoutBaseUrl: "https://prod-runn.b-cdn.net",
    epgTypeApiUrl: "",
    isKidsSafe: false,
    ratingId: "",

    applyLogo: "",

    partnerPlaybacks: [
      {
        partnerCode: "",
        partnerName: "",
        contentPlaybackUrl: "",
      },
    ],
  };

  // Default Error Value on Initial Load
  const defaultFormError = {
    channelId: "",
    name: "",
    type: "",
    goLiveDate: "",
    territoryDetails: "",
    fillerSourcePath: "",
    theme: "",
    channelImages: "",
    categories: "",
    partners: "",
    channelSetupDate: "",
    channelAssembly: "",
    isStartOverEnabled: "",
    isWatchListEnabled: "",
    isForceLogin: "",
    forceLoginWatchDuration: "",
    epgHandler: "",
    epgType: "",
    playoutBaseUrl: "",
    epgTypeApiUrl: "",
    isKidsSafe: "",
    ratingId: "",

    applyLogo: "",

    partnerPlaybacks: [
      {
        partnerCode: "",
        partnerName: "",
        contentPlaybackUrl: "",
      },
    ],
  };

  // Form State
  const [formData, setFormData] = useState({ ...defaultFormValue });
  // Error State

  const [errors, setErrors] = useState({ ...defaultFormError });
  let defaultattributes = { csai_ad_tag: "", vast_csai_tag: "" };
  // Special Fiels Stat Handling
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [channelId, setChannelId] = useState("");
  const [ratinglId, setRatingId] = useState("");
  const [playoutBaseUrl, setplayoutBaseUrl] = useState("");
  const [apiUrl, setapiUrl] = useState("");

  const [customAttributes, setCustomAttributes] = useState(
    JSON.stringify(defaultattributes, null, 2)
  );

  // Create or Update Identification Flag
  const [isEdit, setIsEdit] = useState(false);
  const [assemblyEdit, setassemblyEdit] = useState(false);
  const [externalUrlBool, setExternalUrlBool] = useState(false);
  const [editChannelData, setEditChannelData] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  // Field Options State
  const [partnerOption, setPartnerOptions] = useState([]);
  const [territoryOption, setTerritoryOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [channelTypeList, setChannelTypeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [themeList, setThemeList] = useState([]);
  const [fillerList, setFillerList] = useState([]);
  const [PartnerfillerList, setPartnerfillerList] = useState([]);
  const [dataCheckvalid, setdataCheckvalid] = useState({ ...defaultFormValue });
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const [inputCon, setInputCon] = useState(true);
  const [epgHandlerData, setEpgHandlerData] = useState("");
  const [permission, setpermission] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [ratingList, setRatingList] = useState([]);

  const [applyLogolist, setApplyLogolist] = useState([]);

  const [ratingsDescriptor, setRatingsDescriptor] = useState("");
  const [editable, setEditable] = useState(false);
  const [ratingvalue, setRating] = useState("");


  const navigate = useNavigate();

  const backToChannel = () => {
    navigate("/channel", {
      state: {
        currentPage: currentPage,
      },
    });
  };

  const backToChannels = () => {
    if (JSON.stringify(formData) !== JSON.stringify(dataCheckvalid)) {
      // Objects are different
      setErrorbox(true);
    } else {
      // Objects are the same
      navigate(-1);
    }
  };

  const [errorbox, setErrorbox] = useState(false);

  const errorboxclose = () => {
    setErrorbox(false);
  };

  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem("tenantInfo");
    const Ratings = state?.MaturityList?.data;
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.identifier);
    setIdentifier(tenantLocalDataParse?.code);
  };
  const getEpgHandlersList = async () => {
    const response = await epgHandlersList();
    setEpgHandlerData(response?.data);
  };

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("CHANNEL_SETUP_EDIT")) setpermission("edit");
    else if (findEdit.includes("CHANNEL_SETUP_READ")) setpermission("read");
    else setpermission(null);
  };

  useEffect(() => {
    getPermission();
    setRatingList(Ratings);

    setApplyLogolist()

    accessStore();
    getPartnerList();
    getChannelTypeList();
    getLanguageList();
    getChannelCategoryList();
    getChannelThemeList();
    getCountriesList();
    getEpgHandlersList();
    getPermission();
    // initializeDropdownOptions();
    if (locationState?.state?.id) {
      setIsEdit(true);
      setCurrentPage(locationState?.state?.currentPage);
      getChannelById(locationState.state.id);
    }
    // let rate = ratings[0]?.id
    // setRating(rate);
    getPartnerFiller();
    // setRating();

    if (ratingvalue) {
      const selectedItem = ratingList.find(
        (item) => item.id === parseInt(ratingvalue)
      );
      if (selectedItem) {
        setRatingsDescriptor(selectedItem.description);
      }
    }
  }, [ratingvalue, ratingList]);

  // const initializeDropdownOptions = () => {
  //   setRatingList(ratings || []);

  // };

  // Get Channel Data
  const getChannelById = async (id) => {
    const channelData = await GetChannelById(id);
    if (channelData) {
      if (channelData?.channelAssembly === "EXTERNAL") {
        setInputCon(false);
      }
      setEditChannelData(channelData);
      setEditFormData(channelData);
    }
  };

  // Set Form Data
  const setEditFormData = async (data) => {
    let editFormData = { ...formData };

    editFormData.id = data.id;
    editFormData.categories = data.categories;
    editFormData.channelId = data.channelId;
    editFormData.playoutBaseUrl = data.playoutBaseUrl;
    editFormData.customAttributes = data.customAttributes;
    editFormData.isStartOverEnabled = data.isStartOverEnabled;
    editFormData.isWatchListEnabled = data.isWatchListEnabled;
    editFormData.regionalLanguages = data.regionalLanguage?.id;
    editFormData.territoryDetails = data.territoryDetails;
    editFormData.partners = data.partners;
    editFormData.theme = data.theme;
    editFormData.type = data.type;
    editFormData.playbackUrl = data.playbackUrl;
    editFormData.name = data.name;
    editFormData.fillerSourcePath = data.filler?.id;
    editFormData.channelAssembly = data.channelAssembly;
    editFormData.channelTags = data.channelTags.split(",");
    editFormData.description = data.description;
    editFormData.description = data.description;
    editFormData.epgType = data.epgType;
    editFormData.epgHandler = data?.epgHandler;
    editFormData.goLiveDate = new Date(data.goLiveDate);
    editFormData.channelSetupDate = new Date(data.channelSetupDate);
    editFormData.channelSetupDate = new Date(data.channelSetupDate);
    editFormData.playoutBaseUrl = data.playoutBaseUrl;
    editFormData.epgTypeApiUrl = data.epgTypeApiUrl;
    editFormData.isKidsSafe = data.isKidsSafe;
    editFormData.ratingId = data.ratingId;

    editFormData.applyLogo = data.applyLogo;

  
    editFormData.isForceLogin = data.isForceLogin;
    setassemblyEdit(true);
    setapiUrl(data.epgTypeApiUrl);
    setErrors({ ...defaultFormError });
    handleFieldChangeKeyValue("partners", data.partners);
    parseFileUpload(data.channelLogo);
    setName(data.name);
    setDescription(data.description);
    setplayoutBaseUrl(data.playoutBaseUrl);
    setChannelId(data.channelId);
    setFormData({ ...editFormData });
    setExternalUrlBool(true);
    setCustomAttributes(JSON.stringify(data.customAttributes, null, 2));
    setdataCheckvalid({ ...editFormData });
    setTime(convertToHHMMSS(data.forceLoginWatchDuration));
  };

  const parseFileUpload = async (existingFiles) => {
    let fileUploadedLocal = { ...formData.channelImages };
    existingFiles.forEach((item) => {
      fileUploadedLocal[item.size]["poster"].file = item.posterSourcePath
        ? item.posterSourcePath
        : null;
      fileUploadedLocal[item.size]["thumbnail"].file = item.thumbnailSourcePath
        ? item.thumbnailSourcePath
        : null;
      fileUploadedLocal[item.size]["coverart"].file = item.coverartSourcePath
        ? item.coverartSourcePath
        : null;
    });
    handleFileUploader(fileUploadedLocal);
  };
  function isJson(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  // Get Terretory Option List
  const getCountriesList = () => {
    // const countriesData = await Country_list();
    let countries = Territorys?.map((item) => {
      return { territoryId: item.iso, territoryName: item.niceName };
    });

    countries?.sort((a, b) => {
      return a.territoryName.localeCompare(b.territoryName);
    });

    if (countries && countries?.length) {
      setTerritoryOption(countries);
    }
    // setTerritoryOption(Territorys);
  };

  // Get Partner Option List
  const getPartnerList = async () => {
    const partnerData = await Partner_list();
    if (partnerData?.data && partnerData?.status === true) {
      setPartnerOptions(partnerData.data.content);
    }
  };

  // Get Channel Type List
  const getChannelTypeList = async () => {
    const channelTypeList = await ChannelType_list();
    if (channelTypeList && channelTypeList?.length) {
      setChannelTypeList(channelTypeList);
    }
  };

  // Get Language List
  const getLanguageList = async () => {
    const languageList = await Language_list();
    if (languageList?.content && languageList?.content?.length) {
      setLanguageList(languageList.content);
    }
  };

  // Get Channel Category List
  const getChannelCategoryList = async () => {
    const categoryList = await Categories_list({
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
    });
    if (categoryList?.content && categoryList?.content?.length) {
      setCategoryOption(categoryList.content);
    }
  };

  // Get Theme List
  const getChannelThemeList = async () => {
    const themeList = await Theme_list({
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
    });
    if (themeList?.content && themeList?.content?.length) {
      setThemeList(themeList.content);
    }
  };

  // Get Filler List
  const getFillerByPartner = async (id) => {
    // const fillerList = await FillerList_By_Partner(id);
    const fillerList = await getAllFillers(id);
    console.log('fillerList',fillerList);
    
    if (fillerList?.data && fillerList?.data?.content?.length) {
      setFillerList(fillerList?.data?.content);
    }
  };
  // Get Filler List
  const getPartnerFiller = async () => {
    const params = {
      // partnerId: AssetPartnerDetail?.id,

      ...(false ? { searchQuery: "" } : null),
      fillerType: "PLATFORM_FILLER",
    };
    const fillerDetail = await FillerList(params);

    setPartnerfillerList(fillerDetail?.data?.content);
  };

  // Handle Changes in Multiple Fields for Validation and State
  const handleMultiFieldChange = (fields) => {
    let formFieldData = { ...formData };
    let errorData = { ...errors };
    fields.forEach((item) => {
      formFieldData[item.name] = item.value;
      if (item.required) {
        errorData[item.name] = validateValue(item.value);
      }
    });
    setFormData(formFieldData);
    setErrors(errorData);
  };

  // Required validation
  const validateValue = (value) => {
    if (value == "" || value == null || value.length === 0) {
      return "Field is Required";
    } else {
      return "";
    }
  };

  // Handle Field Change
  const handleFieldChangeKeyValue = (field, value, required = false) => {
    setFormData({ ...formData, [field]: value });
    if (required) {
      handleFieldValidations({ name: field, value: value });
    }
    if (field == "partners" && value.length > 0) {
      const listOfId = value.map((item) => item.id);
      getFillerByPartner(listOfId.join());
    } else if (field == "partners" && value.length === 0) {
      handleFieldValidations({ name: "partners", value: "" });
    }
    // else if (field == 'channelAssembly' && value.length === 0) {
    //   handleFieldValidations({ name: 'channelAssembly', value: "" });
    // }
    else if (field == "isWatchListEnabled" && value.length === 0) {
      handleFieldValidations({ name: "isWatchListEnabled", value: "true" });
    } else if (field == "isStartOverEnabled" && value.length === 0) {
      handleFieldValidations({ name: "isStartOverEnabled", value: "true" });
    } else if (field == "isKidsSafe" && value.length === 0) {
      handleFieldValidations({ name: "isKidsSafe", value: "false" });
    } else if (field == "ratingId" && value.length === 0) {
      handleFieldValidations({ name: "ratingId", value: "false" });
    } else if (field == "categories" && value.length === 0) {
      handleFieldValidations({ name: "categories", value: "" });
    }
    // else if (field == "applyLogo" && value.length === 0) {
    //   handleFieldValidations({ name: "applyLogo", value: "" })
    // }
  };

  // Handle playoutBaseUrl Change
  const handleapiUrlChange = (event) => {
    setapiUrl(event.target.value);
    handleFieldChangeKeyValue(
      event.target.name,
      event.target.value,
      event.target.required
    );
  };

  // Handle Name Change
  const handleNameChange = (event) => {
    if (event.target.value.length <= 100) {
      setName(event.target.value);
      if (!isEdit) {
        generateId(event);
      } else {
        handleMultiFieldChange([
          { name: "name", value: event.target.value, required: true },
        ]);
      }
    }
  };

  // Handle Description Change
  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 2500) {
      setDescription(event.target.value);
      handleFieldChangeKeyValue(
        event.target.name,
        event.target.value,
        event.target.required
      );
    }
  };

  // Handle Channel ID Change
  const handleChennelIdChange = (event) => {
    if (event.target.value.length <= 8) {
      setChannelId(event.target.value);
      handleFieldChangeKeyValue(
        event.target.name,
        event.target.value,
        event.target.required
      );
    }
  };
  // Function to convert time in HH:MM:SS format to total seconds
  const convertToTotalSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);

    return hours * 3600 + minutes * 60 + seconds;
  };

  // Handle playoutBaseUrl Change
  const handleplayoutBaseUrlChange = (event) => {
    setplayoutBaseUrl(event.target.value);
    handleFieldChangeKeyValue(
      event.target.name,
      event.target.value,
      event.target.required
    );
  };
  // // Handle playoutBaseUrl Change
  // const handleplayoutBaseUrlChange = (event) => {

  //   setplayoutBaseUrl(event.target.value);
  //   handleFieldChangeKeyValue(event.target.name, event.target.value, event.target.required);

  // }

  const [time, setTime] = useState("00:00:00");
  // Handle forceLoginWatchDuration Change

  const handleDurationChange = (event) => {
    const { name, value } = event.target;
    // If the user deletes the entire time or inputs a value not in "HH:MM:SS" format
    if (!value) {
      setTime("00:00:00");
    } else {
      setTime(value);
    }

    handleFieldChangeKeyValue(
      event.target.name,
      convertToTotalSeconds(value),
      event.target.required
    );
  };

  const convertToHHMMSS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const padValue = (value) => {
    return value.length === 1 ? `0${value}` : value;
  };

  const handleBlur = (event) => {
    const parts = time.split(":").map((part) => padValue(part));
    setTime(parts.join(":"));

    const value = parts.join(":");
    let isValid = /^([0-9]{2}):([0-5][0-9]):([0-5][0-9])$/.test(value);

    if (!isValid || !value) {
      setErrors({
        ...errors,
        forceLoginWatchDuration: "Please enter a valid time format (HH:MM:SS)",
      });
    } else {
      setErrors({ ...errors, forceLoginWatchDuration: "" });
    }
  };

  // Handle customAttributes  Change
  const handlecustomAttributesChange = (event) => {
    try {
      const jsonString = event.target.value;

      if (typeof jsonString !== "string") {
        throw new Error("Input is not a string");
      }

      JSON.parse(jsonString);
      handleFieldChangeKeyValue(
        event.target.name,
        event.target.value,
        event.target.required
      );
    } catch (error) {
      console.error("Error:", error);

      setErrors({ ...errors, customAttributes: "Invalid JSON format" });
    }

    setCustomAttributes(event.target.value);
  };

  // Handel to format customAttributes data into pretty-print JSON
  const handleFormatJson = () => {
    try {
      const formattedJson = JSON.stringify(
        JSON.parse(customAttributes),
        null,
        2
      );
      setCustomAttributes(formattedJson);
    } catch (error) {
      alert("Invalid JSON format");
    }
  };

  const handleFormatJsonRaw = () => {
    const formattedJson = JSON.stringify(JSON.parse(customAttributes), null);
    setCustomAttributes(formattedJson);
  };

  // Handle Field Validations
  const handleFieldValidations = (event) => {
    const { name, value } = event;
    setErrors({ ...errors, [name]: validateValue(value) });
  };

  // Reset Form
  const resetForm = () => {
    if (!isEdit) {
      setFormData(defaultFormValue);
      setErrors(defaultFormError);
      setName("");
      setDescription("");
      setChannelId("");

      setCustomAttributes(JSON.stringify(defaultattributes));
    } else {
      setEditFormData(editChannelData);
    }
  };

  const ratingOptions = [
    { ratingId: "1", ratingName: "General Audience (G)" },
    { ratingId: "2", ratingName: "Parental Guidance (PG)" },
    { ratingId: "3", ratingName: "Restricted (R)" },
  ];



  const generateId = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    const words = value.split(" ");

    // Extracting the first and last word
    const firstWord = words[0].toUpperCase();
    let lastWord = "";
    if (words.length > 1 && words[1].length < 3) {
      // If the second word has less than 3 characters, consider the first word for the last word
      lastWord = firstWord;
    } else {
      lastWord = words[words.length - 1].toUpperCase();
    }

    // Creating the code with the first word and last word's first 3 letters
    let code = firstWord.slice(0, 3) + lastWord.slice(0, 3);

    // If the code is less than 6 letters, add 'X' to make it 6 letters long
    while (code.length < 6) {
      code += "X";
    }

    setChannelId(code);
    handleMultiFieldChange([
      { name: name, value: value, required: true },
      { name: "channelId", value: code, required: true },
    ]);
  };

  const handleFileUploader = (event) => {
    handleFieldChangeKeyValue("channelImages", event, true);
  };

  const validateForm = () => {
    let invalid = false;
    for (const key in errors) {
      if (key != "channelImages") {
        if (errors[key] && (!formData[key] || !formData[key].length)) {
          invalid = true;
        }
      } else {
        Object.keys(formData[key]["Mobile"]).forEach((innerKey) => {
          if (!formData[key]["Mobile"][innerKey].file) {
            invalid = true;
          }
        });
      }
    }
    return invalid;
  };

  const formatGoLiveDate = (data) => {
    if (data) {
      return moment(data).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "";
    }
  };

  const formatRegionalLang = (data) => {
    if (data) {
      return languageList.find((item) => item.id == data).id;
    } else {
      return "";
    }
  };

  const formatTheme = (data) => {
    if (data) {
      return themeList.find((item) => item.id == data).id;
    } else {
      return "";
    }
  };

  const formatChannelImage = (data) => {
    let files = [];
    Object.keys(data).forEach((key) => {
      Object.keys(data[key]).forEach((innerKey) => {
        if (data[key][innerKey].multiPart) {
          files.push({
            size: key,
            multipartFile: data[key][innerKey].multiPart,
            type: innerKey,
          });
        }
      });
    });
    return files;
  };

  const merge = (data) => {
    const mergedData = data.reduce((acc, obj) => {
      const {
        size,
        posterSourcePath,
        thumbnailSourcePath,
        coverartSourcePath,
      } = obj;

      if (!acc[size]) {
        acc[size] = { size };
      }

      acc[size].posterSourcePath =
        acc[size].posterSourcePath || posterSourcePath;
      acc[size].thumbnailSourcePath =
        acc[size].thumbnailSourcePath || thumbnailSourcePath;
      acc[size].coverartSourcePath =
        acc[size].coverartSourcePath || coverartSourcePath;

      return acc;
    }, {});

    return Object.values(mergedData);
  };

  const getSeperateImages = async (existing, updated) => {
    const deletedImg = [];
    const newImg = [];
    const existingImg = [];
    Object.keys(updated).forEach((parentKey) => {
      Object.keys(updated[parentKey]).forEach((childKey) => {
        let isExist = existing.find((item) => item.size === parentKey);
        if (
          isExist &&
          childKey == "poster" &&
          updated[parentKey][childKey].file === isExist.posterSourcePath
        ) {
          existingImg.push({
            size: isExist.size,
            thumbnailSourcePath: null,
            coverartSourcePath: null,
            posterSourcePath: isExist.posterSourcePath,
          });
        } else if (
          isExist &&
          childKey == "thumbnail" &&
          updated[parentKey][childKey].file === isExist.thumbnailSourcePath
        ) {
          existingImg.push({
            size: isExist.size,
            posterSourcePath: null,
            coverartSourcePath: null,
            thumbnailSourcePath: isExist.thumbnailSourcePath,
          });
        } else if (
          isExist &&
          childKey == "coverart" &&
          updated[parentKey][childKey].file === isExist.coverartSourcePath
        ) {
          existingImg.push({
            size: isExist.size,
            posterSourcePath: null,
            thumbnailSourcePath: null,
            coverartSourcePath: isExist.coverartSourcePath,
          });
        } else if (
          isExist &&
          childKey == "poster" &&
          updated[parentKey][childKey].file === null &&
          isExist.posterSourcePath
        ) {
          deletedImg.push({
            size: isExist.size,
            thumbnailSourcePath: null,
            coverartSourcePath: null,
            posterSourcePath: isExist.posterSourcePath,
          });
        } else if (
          isExist &&
          childKey == "thumbnail" &&
          updated[parentKey][childKey].file === null &&
          isExist.thumbnailSourcePath
        ) {
          deletedImg.push({
            size: isExist.size,
            posterSourcePath: null,
            coverartSourcePath: null,
            thumbnailSourcePath: isExist.thumbnailSourcePath,
          });
        } else if (
          isExist &&
          childKey == "coverart" &&
          updated[parentKey][childKey].file === null &&
          isExist.coverartSourcePath
        ) {
          deletedImg.push({
            size: isExist.size,
            posterSourcePath: null,
            thumbnailSourcePath: null,
            coverartSourcePath: isExist.coverartSourcePath,
          });
        } else {
          if (updated[parentKey][childKey].file) {
            newImg.push({
              size: parentKey,
              multipartFile: updated[parentKey][childKey].multiPart,
              type: childKey,
            });
          }
        }
      });
    });
    return {
      deletedImg: merge(deletedImg),
      newImg: newImg,
      existingImg: merge(existingImg),
    };
  };

  const saveChannel = async () => {
    setdataCheckvalid({ ...formData });
    setDisableBtn(true);

    const payload = {
      channelId: formData.channelId,
      name: formData.name,
      type: formData.type, // Format Type
      description: formData.description,
      goLiveDate: formatGoLiveDate(formData.goLiveDate),
      channelTags: formData.channelTags.join(","),
      playbackUrl: formData.playbackUrl,
      territoryDetails: formData.territoryDetails,
      fillerId: formData.fillerSourcePath,
      regionalLanguages: formatRegionalLang(formData.regionalLanguages),
      theme: returnId(formData.theme),
      categories: returnId(formData.categories),
      partners: returnId(formData.partners),
      channelAssembly: formData.channelAssembly,
      isWatchListEnabled: formData.isWatchListEnabled,
      isStartOverEnabled: formData.isStartOverEnabled,
      playoutBaseUrl: formData.playoutBaseUrl,
      isForceLogin: formData.isForceLogin,
      forceLoginWatchDuration: formData.forceLoginWatchDuration,
      epgType: formData.epgType,
      epgHandler: formData?.epgHandler,
      epgTypeApiUrl: formData.epgTypeApiUrl,
      isKidsSafe: formData.isKidsSafe,

     
      applyLogo: formData.applyLogo,

      ratingId: Number(formData.ratingId),
      applyLogo: formData.applyLogo,

    };

    // Convert File Uploaded to FormData
    // if (isEdit) {
    if (externalUrlBool) {
      // Fetch Edit Image Status
      const fileUploadData = await getSeperateImages(
        editChannelData.channelLogo,
        formData.channelImages
      );
      // Modify Payload
      payload["listExistingImage"] = fileUploadData.existingImg;
      payload["deleteImageList"] = fileUploadData.deletedImg;
      // Convert Payload to FormData
      let jason =
        formData.customAttributes && !isJson(formData.customAttributes)
          ? JSON.stringify(formData.customAttributes)
          : formData.customAttributes;
      const payloadForm = new FormData();
      payloadForm.append("requestBody", JSON.stringify(payload));
      payloadForm.append("customAttributes", jason);
      let thumbIndex = 0;
      fileUploadData.newImg.forEach((value, index) => {
        if (value.type === "thumbnail" && value.multipartFile) {
          payloadForm.append(
            `addChannelImages[${thumbIndex}].size`,
            value.size
          );
          payloadForm.append(
            `addChannelImages[${thumbIndex}].multipartFile`,
            value.multipartFile
          );
          payloadForm.append(
            `addChannelImages[${thumbIndex}].type`,
            "thumbnail"
          );
        }
        if (value.type === "poster" && value.multipartFile) {
          payloadForm.append(
            `addChannelImages[${thumbIndex}].size`,
            value.size
          );
          payloadForm.append(
            `addChannelImages[${thumbIndex}].multipartFile`,
            value.multipartFile
          );
          payloadForm.append(`addChannelImages[${thumbIndex}].type`, "poster");
        }
        if (value.type === "coverart" && value.multipartFile) {
          payloadForm.append(
            `addChannelImages[${thumbIndex}].size`,
            value.size
          );
          payloadForm.append(
            `addChannelImages[${thumbIndex}].multipartFile`,
            value.multipartFile
          );
          payloadForm.append(
            `addChannelImages[${thumbIndex}].type`,
            "coverart"
          );
        }
        thumbIndex++;
      });

      const response = await Channel_update(payloadForm);
      if (response?.data?.message) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
        setTimeout(() => {
          backToChannel();
          setDisableBtn(false);
        }, 3000);
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
        setDisableBtn(false);
      }
    } else {
      // Convert Payload to FormData
      const payloadForm = new FormData();
      payloadForm.append("requestBody", JSON.stringify(payload));
      payloadForm.append(
        "customAttributes",
        formData.customAttributes == undefined ? "" : formData.customAttributes
      );
      let thumbIndex = 0;
      let posterIndex = 0;
      let coverIndex = 0;
      formatChannelImage(formData.channelImages).forEach((value, index) => {
        if (value.type === "thumbnail") {
          payloadForm.append(`thumbnailImages[${thumbIndex}].size`, value.size);
          payloadForm.append(
            `thumbnailImages[${thumbIndex}].multipartFile`,
            value.multipartFile
          );
          payloadForm.append(
            `thumbnailImages[${thumbIndex}].type`,
            "thumbnail"
          );
          thumbIndex++;
        }
        if (value.type === "poster") {
          payloadForm.append(`posterImages[${posterIndex}].size`, value.size);
          payloadForm.append(
            `posterImages[${posterIndex}].multipartFile`,
            value.multipartFile
          );
          payloadForm.append(`posterImages[${posterIndex}].type`, "poster");
          posterIndex++;
        }
        if (value.type === "coverart") {
          payloadForm.append(`coverartImages[${coverIndex}].size`, value.size);
          payloadForm.append(
            `coverartImages[${coverIndex}].multipartFile`,
            value.multipartFile
          );
          payloadForm.append(`coverartImages[${coverIndex}].type`, "coverart");
          coverIndex++;
        }
      });

      const response = await Channel_create(payloadForm);
      if (response?.data?.message) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
        setTimeout(() => {
          backToChannel();
          setDisableBtn(false);
        }, 3000);
      } else {
        toast.error(response?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
        setDisableBtn(false);
      }
    }
  };

  const returnId = (data) => {
    return data.map((item) => item.id);
  };

  const setfilterRequirement = (value) => {
    if (value == "EXTERNAL") {
      setInputCon(false);
      if (!formData.playbackUrl) {
        setFormData({
          ...formData,
          isStartOverEnabled: false,
          isWatchListEnabled: false,
          channelAssembly: value,
        });
        setIsEdit(true);
      }
    } else {
      setInputCon(true);
      if (!formData.playbackUrl) {
        setFormData({
          ...formData,
          isStartOverEnabled: true,
          isWatchListEnabled: true,
          channelAssembly: value,
        });
      }
      setIsEdit(false);
    }
  };

  const openCDNPopUp = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };
  console.log('ddddddd', state)
  console.log("placeholder", customAttributes);
  return permission !== null ? (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <div className="breadcrumbs">
              <ul>
                <li onClick={backToChannels}>Channel Setup</li>
                <li className="active">
                  {" "}
                  {externalUrlBool ? name : "New Channel"}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="channel-creation-form">
          <form>
            <div className="form-container">
              <h4>Channel Source</h4>
              <div className="form-row">
                {channelSetupMetaData?.map((data, index) => {
                  return data?.channelSource?.map((data2, index2) => {
                    return data2?.fields?.map((data3, index3) => {
                      if (data3?.name === "awsChannelName") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.name
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel Name <i>*</i>
                              </label>
                              <input
                                name="name"
                                type="text"
                                placeholder="Enter channel name"
                                required
                                value={name}
                                onChange={handleNameChange}
                                onBlur={handleNameChange}
                              />
                              <div className="field-info-row">
                                {errors.name ? (
                                  <span className="field-error">
                                    {errors.name}
                                  </span>
                                ) : null}
                                <span className="field-length">
                                  {name.length}/100
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "partners") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.partners
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Partner <i>*</i>
                              </label>
                              <Multiselect
                                className="multiselect-field"
                                placeholder="Select Partner"
                                options={partnerOption}
                                selectedValues={formData.partners}
                                onSelect={(event) => {
                                  handleFieldChangeKeyValue(
                                    "partners",
                                    event,
                                    true
                                  );
                                }}
                                onRemove={(event) => {
                                  handleFieldChangeKeyValue(
                                    "partners",
                                    event,
                                    true
                                  );
                                }}
                                displayValue="name"
                                customCloseIcon={<img src={closeChip} />}
                                showArrow={true}
                                customArrow={<img src={dropdownArrow} />}
                              />
                              {errors.partners ? (
                                <span className="field-error">
                                  {errors.partners}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      }
                    });
                  });
                })}
              </div>
            </div>
            <div className="form-container">
              <h4>Channel Details</h4>
              <div className="form-row">
                {channelSetupMetaData?.map((data, index) => {
                  return data?.channelDetails?.map((data2, index2) => {
                    return data2?.fields?.map((data3, index) => {
                      if (data3?.name === "channelId") {
                        return (
                          <div className="form-col ">
                            <div
                              className={
                                errors.channelId
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel ID <i>*</i>
                              </label>
                              <input
                                disabled={isEdit ? "disabled" : ""}
                                name="channelId"
                                type="text"
                                placeholder="Enter channel name"
                                required
                                value={channelId}
                                onChange={handleChennelIdChange}
                                onBlur={handleChennelIdChange}
                              />
                              {errors.channelId ? (
                                <span className="field-error">
                                  {errors.channelId}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "type") {
                        return (
                          <div className="form-col ">
                            <div
                              className={
                                errors.type
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel Type <i>*</i>
                              </label>
                              <select
                                name="type"
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                onBlur={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                value={formData.type}
                                required
                              >
                                {/* Render 'Loop' option first */}
                                {channelTypeList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.type}>
                                      {item.type}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.type ? (
                                <span className="field-error">
                                  {errors.type}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "channelAssembly") {
                        return (
                          <>
                            <div className="form-col  width-33">
                              <div
                                className={
                                  errors.channelAssembly
                                    ? "form-field hasError"
                                    : "form-field"
                                }
                              >
                                <label>
                                  Channel Assembly<i>*</i>
                                </label>
                                <select
                                  name="channelAssembly"
                                  disabled={assemblyEdit}
                                  value={formData.channelAssembly}
                                  onChange={(event) => {
                                    handleFieldChangeKeyValue(
                                      event.target.name,
                                      event.target.value,
                                      event.target.required
                                    );
                                    setfilterRequirement(event.target.value);
                                  }}
                                  nBlur={(event) => {
                                    handleFieldChangeKeyValue(
                                      event.target.name,
                                      event.target.value,
                                      event.target.required
                                    );
                                  }}
                                  required
                                >
                                  {/* <option value="">Select Channel Assembly</option> */}

                                  <option value="AWS">AWS MediaTailor</option>
                                  <option value="RUNN_MEDIA">
                                    Runn Playout
                                  </option>
                                  <option value="EXTERNAL">External</option>
                                </select>
                                {errors.channelAssembly ? (
                                  <span className="field-error">
                                    {errors.channelAssembly}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            {formData?.channelAssembly == "EXTERNAL" && (
                              <>
                                <div className="form-col  width-33">
                                  <div
                                    className={
                                      errors.epgHandler
                                        ? "form-field hasError"
                                        : "form-field"
                                    }
                                  >
                                    <label>
                                      EPG Handler<i>*</i>
                                    </label>
                                    <select
                                      name="epgHandler"
                                      value={formData?.epgHandler}
                                      onChange={(event) => {
                                        handleFieldChangeKeyValue(
                                          event.target.name,
                                          event.target.value,
                                          event.target.required
                                        );
                                      }}
                                      onBlur={(event) => {
                                        handleFieldChangeKeyValue(
                                          event.target.name,
                                          event.target.value,
                                          event.target.required
                                        );
                                      }}
                                      required
                                    >
                                      <option value="">
                                        Select EPG Handler
                                      </option>
                                      {epgHandlerData?.map((data) => {
                                        return (
                                          <option value={data}>{data}</option>
                                        );
                                      })}
                                    </select>
                                    {errors.epgHandler ? (
                                      <span className="field-error">
                                        {errors.epgHandler}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-col  width-33">
                                  <div
                                    className={
                                      errors.epgType
                                        ? "form-field hasError"
                                        : "form-field"
                                    }
                                  >
                                    <label>
                                      EPG Type<i>*</i>
                                    </label>
                                    <select
                                      name="epgType"
                                      value={formData.epgType}
                                      onChange={(event) => {
                                        handleFieldChangeKeyValue(
                                          event.target.name,
                                          event.target.value,
                                          event.target.required
                                        );
                                      }}
                                      onBlur={(event) => {
                                        handleFieldChangeKeyValue(
                                          event.target.name,
                                          event.target.value,
                                          event.target.required
                                        );
                                      }}
                                      required
                                    >
                                      <option value="">Select EPG Type</option>

                                      <option value="APIDriven">
                                        API Driven
                                      </option>
                                      <option value="XLSInput">
                                        XLS Input
                                      </option>
                                    </select>
                                    {errors.epgType ? (
                                      <span className="field-error">
                                        {errors.epgType}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-col width-33">
                                  {formData.epgType == "APIDriven" && (
                                    <div
                                      className={
                                        false
                                          ? "form-field hasError"
                                          : "form-field"
                                      }
                                    >
                                      <label>
                                        API URL<i></i>
                                      </label>
                                      <input
                                        name="epgTypeApiUrl"
                                        type="text"
                                        placeholder="Enter API URL"
                                        required
                                        value={formData.epgTypeApiUrl}
                                        onChange={(event) =>
                                          handleapiUrlChange(event)
                                        }
                                        onBlur={(event) =>
                                          handleapiUrlChange(event)
                                        }
                                      />
                                      {errors.epgTypeApiUrl ? (
                                        <span className="field-error">
                                          {errors.epgTypeApiUrl}
                                        </span>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        );
                      } else if (data3?.name === "isStartOverEnabled") {
                        return storeFrontAccess === "cloudtv" ? (
                          ""
                        ) : (
                          <div className="form-col  width-33 ">
                            <div
                              className={
                                errors.isStartOverEnabled
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Start Over<i>*</i>
                              </label>
                              <select
                                name="isStartOverEnabled"
                                value={formData.isStartOverEnabled}
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                onBlur={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                required
                              >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                              </select>
                              {errors.isStartOverEnabled ? (
                                <span className="field-error">
                                  {errors.isStartOverEnabled}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "isWatchListEnabled") {
                        return storeFrontAccess === "cloudtv" ? (
                          ""
                        ) : (
                          <div className="form-col  width-33 ">
                            <div
                              className={
                                errors.isWatchListEnabled
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Watch List<i>*</i>
                              </label>
                              <select
                                name="isWatchListEnabled"
                                value={formData.isWatchListEnabled}
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                onBlur={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                required
                              >
                                <option value="true">Enabled</option>
                                <option value="false">Disabled</option>
                              </select>
                              {errors.isWatchListEnabled ? (
                                <span className="field-error">
                                  {errors.isWatchListEnabled}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "isForceLogin") {
                        return storeFrontAccess === "cloudtv" ? (
                          ""
                        ) : (
                          <>
                            <div className="form-col">
                              <div
                                className={
                                  errors.isForceLogin
                                    ? "form-field hasError"
                                    : "form-field"
                                }
                              >
                                <label>
                                  Force Login<i>*</i>
                                </label>
                                <select
                                  name="isForceLogin"
                                  value={formData.isForceLogin}
                                  onChange={(event) => {
                                    handleFieldChangeKeyValue(
                                      event.target.name,
                                      event.target.value,
                                      event.target.required
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleFieldChangeKeyValue(
                                      event.target.name,
                                      event.target.value,
                                      event.target.required
                                    );
                                  }}
                                  required
                                >
                                  <option value="false">Disabled</option>
                                  <option value="true">Enabled</option>
                                </select>
                                {errors.isForceLogin ? (
                                  <span className="field-error">
                                    {errors.isForceLogin}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-col">
                              {formData.isForceLogin === true ||
                                formData.isForceLogin == "true" ? (
                                <div
                                  className={
                                    errors.forceLoginWatchDuration
                                      ? "form-field hasError"
                                      : "form-field"
                                  }
                                >
                                  <label>
                                    Duration{" "}
                                    <small
                                      style={{
                                        fontSize: "12px",
                                        color: "grey",
                                      }}
                                    >
                                      (HH:MM:SS)
                                    </small>{" "}
                                    <i>*</i>
                                  </label>
                                  <input
                                    name="forceLoginWatchDuration"
                                    type="text"
                                    placeholder="HH:MM:SS"
                                    maxLength={8}
                                    value={time}
                                    pattern="[0-9]{2}:[0-5][0-9]:[0-5][0-9]" // Pattern to enforce numeric values in HH:MM:SS format
                                    onChange={handleDurationChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.forceLoginWatchDuration ? (
                                    <span className="field-error">
                                      {errors.forceLoginWatchDuration}
                                    </span>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      } else if (data3?.name === "isKidsSafe") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.isKidsSafe
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Kids Safe<i>*</i>
                              </label>
                              <select
                                name="isKidsSafe"
                                value={formData.isKidsSafe}
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                onBlur={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                                required
                              >
                                <option value="false">Disabled</option>
                                <option value="true">Enabled</option>
                              </select>
                              {errors.isKidsSafe ? (
                                <span className="field-error">
                                  {errors.isKidsSafe}
                                </span>
                              ) : null}
                            </div>



                            <div
                              style={{ marginTop: 15 }}
                              className={
                                errors.ratingId
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Rating <i>*</i>
                              </label>
                              <select
                                name="ratingId"
                                value={formData.ratingId}
                                onChange={(event) =>
                                  handleFieldChangeKeyValue(
                                    "ratingId",
                                    event.target.value,
                                    true
                                  )
                                }
                                required
                              >
                                <option value="" disabled>
                                  Select a rating
                                </option>
                                {
                                  ratingList && ratingList.map((item, index) => {
                                    return (
                                      <option key={item} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.ratingId ? (
                                <span className="field-error">
                                  {errors.ratingId}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      }

                      else if (data3?.name === "goLiveDate") {
                        return (
                          <>
                            <div className="form-col">
                              <div
                                className={
                                  errors.goLiveDate
                                    ? "form-field hasError"
                                    : "form-field"
                                }
                              >
                                <label>
                                  Go-Live date <i>*</i>
                                </label>
                                <DatePicker
                                  name="goLiveDate"
                                  selected={formData.goLiveDate}
                                  onChange={(event) =>
                                    handleFieldChangeKeyValue(
                                      "goLiveDate",
                                      event,
                                      true
                                    )
                                  }
                                  showTimeSelect
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                {errors.goLiveDate ? (
                                  <span className="field-error">
                                    {errors.goLiveDate}
                                  </span>
                                ) : null}
                              </div>


                              <div style={{ marginTop: 16 }} className={errors.applyLogo ? "form-field hasError" : "form-field"}>
  <label>
    Enable Logo <i>*</i>
  </label>
  <select
    name="applyLogo"
    value={formData.applyLogo === true ? "true" : formData.applyLogo === false ? "false" : ""}
    onChange={(event) => {
      const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
      handleFieldChangeKeyValue("applyLogo", value, true);
    }}
    required
  >
    <option value="">--Select--</option>
    <option value="true">Yes</option>
    <option value="false">No</option>
  </select>
  {errors.applyLogo ? (
    <span className="field-error">{errors.applyLogo}</span>
  ) : null}
</div>


                            </div>


                          </>
                        );
                      }

                    



                      else if (data3?.name === "channelSetupDate") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.channelSetupDate
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel Setup date <i>*</i>
                              </label>
                              <DatePicker
                                disabled
                                name="channelSetupDate"
                                selected={formData.channelSetupDate}
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    "channelSetupDate",
                                    event,
                                    true
                                  );
                                }}
                                dateFormat="MMMM d, yyyy"
                              />
                              {errors.channelSetupDate ? (
                                <span className="field-error">
                                  {errors.channelSetupDate}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "description") {
                        return (
                          <div className="form-col full-form-col">
                            <div className="form-field">
                              <label>Channel Description</label>
                              {/* <textarea name='description' placeholder='Enter channel Description' value={description} onChange={handleDescriptionChange}></textarea> */}
                              {/* <input name='description' placeholder='Enter channel Description' value={description} onChange={handleDescriptionChange}  /> */}
                              <TextareaAutosize
                                name="description"
                                placeholder="Enter channel Description"
                                value={description}
                                onChange={handleDescriptionChange}
                                maxRows={5}
                                minRows={1}
                                style={{
                                  width: "100%",
                                  resize: "none",
                                }}
                              />

                              <span className="field-length">
                                {description.length}/2500
                              </span>
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "territoryDetails") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.territoryDetails
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Territory <i>*</i>
                              </label>
                              <Multiselect
                                className="multiselect-field"
                                placeholder="Select Territory"
                                options={territoryOption}
                                selectedValues={formData.territoryDetails}
                                onSelect={(event) => {
                                  handleFieldChangeKeyValue(
                                    "territoryDetails",
                                    event,
                                    true
                                  );
                                }}
                                onRemove={(event) => {
                                  handleFieldChangeKeyValue(
                                    "territoryDetails",
                                    event,
                                    true
                                  );
                                }}
                                displayValue="territoryName"
                                customCloseIcon={<img src={closeChip} />}
                                showArrow={true}
                                customArrow={<img src={dropdownArrow} />}
                              />
                              {errors.territoryDetails ? (
                                <span className="field-error">
                                  {errors.territoryDetails}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "regionalLanguage") {
                        return (
                          // <div className='form-col'>
                          //   <div className='form-field'>
                          //     <label>Language</label>
                          //     <select name='regionalLanguages' value={formData.regionalLanguages} onChange={(event) => { handleFieldChangeKeyValue(event.target.name, event.target.value, event.target.required) }}>

                          //       {
                          //         languageList.map((item) => {
                          //           return (
                          //             <option key={item.id} value={item.id}>{item.name}</option>
                          //           )
                          //         })
                          //       }
                          //     </select>
                          //   </div>
                          // </div>
                          <div className="form-col">
                            <div className="form-field">
                              <label>Language</label>
                              <select
                                name="regionalLanguages"
                                value={formData.regionalLanguages}
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                              >
                                {/* Placeholder option */}
                                <option value="" disabled>
                                  Select a language
                                </option>

                                {/* Render options from languageList */}
                                {languageList.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "categories") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.categories
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel Category <i>*</i>
                              </label>
                              <Multiselect
                                className="multiselect-field"
                                placeholder="Select Channel Category"
                                options={categoryOption}
                                selectedValues={formData.categories}
                                onSelect={(event) => {
                                  handleFieldChangeKeyValue(
                                    "categories",
                                    event,
                                    true
                                  );
                                }}
                                onRemove={(event) => {
                                  handleFieldChangeKeyValue(
                                    "categories",
                                    event,
                                    true
                                  );
                                }}
                                displayValue="name"
                                customCloseIcon={<img src={closeChip} />}
                                showArrow={true}
                                customArrow={<img src={dropdownArrow} />}
                              />
                              {errors.categories ? (
                                <span className="field-error">
                                  {errors.categories}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "theme") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.theme
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel Theme <i>*</i>
                              </label>
                              <Multiselect
                                className="multiselect-field"
                                placeholder="Select Theme"
                                options={themeList}
                                selectedValues={formData.theme}
                                onSelect={(event) => {
                                  handleFieldChangeKeyValue(
                                    "theme",
                                    event,
                                    true
                                  );
                                }}
                                onRemove={(event) => {
                                  handleFieldChangeKeyValue(
                                    "theme",
                                    event,
                                    true
                                  );
                                }}
                                displayValue="name"
                                customCloseIcon={<img src={closeChip} />}
                                showArrow={true}
                                customArrow={<img src={dropdownArrow} />}
                              />
                              {errors.theme ? (
                                <span className="field-error">
                                  {errors.theme}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "fillerSourcePath") {
                        return (
                          <div className="form-col">
                            {inputCon ? (
                              <div
                                className={
                                  errors.fillerSourcePath
                                    ? "form-field hasError"
                                    : "form-field"
                                }
                              >
                                <label>
                                  Default Filler <i>*</i>
                                </label>
                                <select
                                  name="fillerSourcePath"
                                  value={formData.fillerSourcePath}
                                  onChange={(event) => {
                                    handleFieldChangeKeyValue(
                                      event.target.name,
                                      event.target.value,
                                      event.target.required
                                    );
                                  }}
                                  onBlur={(event) => {
                                    handleFieldChangeKeyValue(
                                      event.target.name,
                                      event.target.value,
                                      event.target.required
                                    );
                                  }}
                                  required
                                >
                                  <option value="">Select Filler</option>
                                  {fillerList.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.sourcePath}
                                      </option>
                                    );
                                  })}{" "}
                                  {/* {PartnerfillerList.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.sourcePath}
                                      </option>
                                    );
                                  })} */}
                                </select>
                                {errors.fillerSourcePath ? (
                                  <span className="field-error">
                                    {errors.fillerSourcePath}
                                  </span>
                                ) : null}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      } else if (data3?.name === "channelTags") {
                        return (
                          <div className="form-col">
                            <div className="form-field">
                              <label>Channel Tags</label>
                              <div className="tagInput-field">
                                <TagsInput
                                  value={
                                    formData.channelTags == ""
                                      ? []
                                      : formData.channelTags
                                  }
                                  onChange={(event) => {
                                    handleFieldChangeKeyValue(
                                      "channelTags",
                                      event
                                    );
                                  }}
                                  name="channelTags"
                                  placeHolder="Enter Channel Tags"
                                  classNames={{
                                    tag: "tag-chip",
                                    input: "tag-input",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "playoutBaseUrl") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.playoutBaseUrl
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Playout Base URL <i>*</i>
                              </label>
                              <input
                                name="playoutBaseUrl"
                                type="text"
                                placeholder="Enter channel name"
                                required
                                value={playoutBaseUrl}
                                onChange={handleplayoutBaseUrlChange}
                                onBlur={handleplayoutBaseUrlChange}
                              />
                              {errors.playoutBaseUrl ? (
                                <span className="field-error">
                                  {errors.playoutBaseUrl}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "playbackUrl") {
                        return (
                          <div className="form-col">
                            <div className="form-field">
                              <label>Playback URL </label>
                              <input
                                name="playbackUrl"
                                type="text"
                                disabled={externalUrlBool ? false : !isEdit}
                                placeholder=""
                                value={formData.playbackUrl}
                                onChange={(event) => {
                                  handleFieldChangeKeyValue(
                                    event.target.name,
                                    event.target.value,
                                    event.target.required
                                  );
                                }}
                              />
                              {isEdit && identifier === "pktflm" && (
                                <a
                                  className="btn btn-secondary btn-add w-25 mt-2 p-1"
                                  onClick={openCDNPopUp}
                                >
                                  Add Partner URL
                                </a>
                              )}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "channelLogo") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.channelImages
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Channel image <i>*</i>
                              </label>
                              <FileUploaderContainer
                                files={formData.channelImages}
                                onChange={handleFileUploader}
                              ></FileUploaderContainer>
                              {errors.channelImages ? (
                                <span className="field-error">
                                  {errors.channelImages}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        );
                      } else if (data3?.name === "customAttributes") {
                        return (
                          <div className="form-col">
                            <div
                              className={
                                errors.customAttributes
                                  ? "form-field hasError"
                                  : "form-field"
                              }
                            >
                              <label>
                                Custom Attributes <i>*</i>
                              </label>
                              {/* Keep the textarea for input */}
                              <textarea
                                id="json-data-scroll"
                                name="customAttributes"
                                placeholder="Enter custom attributes"
                                style={{
                                  fontFamily: "monospace",
                                  whiteSpace: "pre",
                                  minHeight: "150px",
                                }}
                                required
                                value={
                                  customAttributes != null
                                    ? customAttributes
                                    : JSON.stringify(defaultattributes, null, 2)
                                }
                                onChange={handlecustomAttributesChange}
                                onBlur={handlecustomAttributesChange}
                              />
                              {errors.customAttributes ? (
                                <span className="field-error">
                                  {errors.customAttributes}
                                </span>
                              ) : null}
                              <div>
                                <a
                                  className="btn btn-danger w-25 p-0 mt-2"
                                  style={{ height: "25px" }}
                                  onClick={handleFormatJson}
                                >
                                  Format JSON
                                </a>
                                {/* <a onClick={handleFormatJsonRaw}>Format RAW</a> */}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    });
                  });
                })}
              </div>
            </div>
            {permission === "edit" ? (
              <div className="form-container">
                <div className="button-row">
                  {/* disabled={validateForm()} */}
                  <button
                    type="button"
                    disabled={disableBtn || validateForm()}
                    onClick={() => {
                      saveChannel();
                    }}
                    className="btn btn-primary"
                  >
                    {/* {isEdit ? 'Update' : 'Create'} */}
                    {externalUrlBool ? "Update" : "Create"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={resetForm}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={backToChannel}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {errorbox && (
              <ErrorPopup
                btntext={"Sure"}
                message={
                  "Your changes have not been saved. Are you sure you want to exit this page?"
                }
                closebox={() => errorboxclose()}
                overrides={() => backToChannel()}
              />
            )}
          </form>
          <CDNPopUp
            show={showPopup}
            handleClose={closePopup}
            formData={formData}
            setFormData={setFormData}
            setErrors={setErrors}
            errors={errors}
          />
          <ToastContainer />
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}





export default ChannelCreation;

