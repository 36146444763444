import backArrow from '../../../assets/images/back-arrow.svg';
import expandIcon from "../../../assets/images/expand-icon.svg";
import copyIcon from "../../../assets/images/copy-icon.svg";
import pasteIcon from "../../../assets/images/paste.svg";
import revertIcon from "../../../assets/images/revert.svg";
import zoomInIcon from "../../../assets/images/zoom-in.svg";
import zoomOutIcon from "../../../assets/images/zoom-out.svg";
import icroundsearch from "../../../assets/images/ic_round-search.svg";
import adMarkers from "../../../assets/images/adMarkers.svg";
import deleteIcon from '../../../assets/images/delete_forever.png';

const ExpandView = ({
    expandViewData,
    defaultView,
    appTheme,
    channelDragStart,
    searchProgram,
    searchedText,
    selectedTab,
    setSelectedTab,
    ellipse,
    programList,
    locationState,
    handelProgramDoubleClick,
    promoList,
    fillerList,
    update_schedule,
    handleZoomIn,
    handleZoomOut,
    millisecondsToHuman,
    pasteDataFun,
    copyDataFun,
    copyData,
    handleDragStart,
    handleDragOver,
    handleDrop,
    timeSlots,
    zoomInOut,
    handleDragOver2,
    calculateMinutes,
    linePosition,
    hoveredId,
    hoverTime,
    getAssetInfo,
    assetInfo,
    handleProgramDelete,
    formatTime,
}) => {
    console.log('expandViewData',expandViewData);
    
    return (
        <div className={`content-body SchedulingDetail SchedulingLoop scheduling-loop-content-body ${appTheme}`}>
            <div className="dashboard-content flex schedule-loop-view">
                <div className="left">
                    <div>
                        <h3 className='schedule-title-header title-header mb-3'>Scheduling</h3>
                        <div className='list-box'>
                            <div className="search-container" style={{height: '44px'}}>
                            <input
                                    id="search-input"
                                    className="search-box"
                                    type="text"
                                    placeholder="Search program"
                                    style={{
                                    width: '100%',
                                    height: '40px',
                                    color: appTheme === "dark" ? '#ffffff' : 'black',
                                    borderRadius: '6px',
                                    padding: '0 10px',
                                    }}
                                    value={searchedText}
                                    onChange={(e)=>{searchProgram(e.target.value)}}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            searchProgram(e.target.value); // Trigger the button's onClick function
                                        }
                                    }}
                                />
                                <button
                                    className="search-button"
                                    aria-label="Search"
                                    style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '0',
                                    backgroundColor: 'transparent',
                                    color: appTheme === "dark" ? '#ffffff' : 'black',
                                    }}
                                >
                                    <img
                                    src={icroundsearch}
                                    alt="Search"
                                    className="search-icon"
                                    style={{
                                        filter: appTheme === "dark" ? 'invert(100%) brightness(150%)' : 'none',
                                    }}
                                    />
                                </button>
                            </div>
                            <div className="channel-listing-cseq">
                                <div className="list-wrapper" onDragStart={channelDragStart}>
                                {['Programs', 'Promos', 'Fillers'].map((tab, index) => {
                                        return (
                                                <div
                                                    key={index}
                                                    className={`item-wrapper ${
                                                        tab == selectedTab ? "active" : ""
                                                    }`}
                                                >
                                                    <div
                                                        className="list-item"
                                                        data-id={index}
                                                        onClick={(e) => {
                                                            tab == selectedTab
                                                            ? setSelectedTab("")
                                                            : setSelectedTab(tab);
                                                        }}
                                                    >
                                                    <img src={ellipse} width={10} />
                                                    <div className="channel-name" data-id={index}>
                                                    {tab}
                                                    </div>
                                                    <span className="channel-arrow"></span>
                                                </div>
                                                <ul className="nexted-list">
                                                    {
                                                        tab === "Programs" ?
                                                            programList?.length?
                                                            programList?.map((data, index) => {
                                                                return (
                                                                    <li
                                                                        key={`${index}`}
                                                                        className="nexted-list-item"
                                                                        data-type="programs"
                                                                        draggable={locationState?.state?.permission === "edit"? true : false}
                                                                        // draggable={true}
                                                                        data-value={JSON.stringify(data)}
                                                                        data-key={index}
                                                                        // onDoubleClick={() => {handelProgramDoubleClick(data, index)}}
                                                                        title={data?.metadata?.title}
                                                                    >
                                                                        <span className="program-name" data-key={index}>
                                                                            {data?.metadata?.title}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <span className="program-name text-center no-list-data">
                                                                No programs available.
                                                            </span>
                                                        :
                                                        tab === "Promos" ?
                                                            promoList?.length?
                                                            promoList?.map((data, index) => {
                                                                return (
                                                                    <li
                                                                        key={`${index}`}
                                                                        className="nexted-list-item"
                                                                        data-type="promos"
                                                                        draggable={locationState?.state?.permission === "edit"? true : false}
                                                                        // draggable={true}
                                                                        data-value={JSON.stringify(data)}
                                                                        data-key={index}
                                                                        title={data?.metadata?.title}
                                                                    >
                                                                        <span className="program-name" data-key={index}>
                                                                            {data?.metadata?.title}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <span className="program-name text-center no-list-data">
                                                                No promos available.
                                                            </span>
                                                        :
                                                        tab === "Fillers"?
                                                            fillerList?.length?
                                                            fillerList?.map((data, index) => {
                                                                return (
                                                                    <li
                                                                        key={`${index}`}
                                                                        className="nexted-list-item"
                                                                        data-type="fillers"
                                                                        draggable={locationState?.state?.permission === "edit"? true : false}
                                                                        // draggable={true}
                                                                        data-value={JSON.stringify(data)}
                                                                        data-key={index}
                                                                        title={data?.filename}
                                                                    >
                                                                        <span className="program-name" data-key={index}>
                                                                            {data?.filename}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <span className="program-name text-center no-list-data">
                                                                No fillers available.
                                                            </span>
                                                        :
                                                        ''
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="ads mt-5" onDragStart={channelDragStart}>
                                <button className="btn btn-danger w-100" data-type="ads" data-duration='00:00:30' data-value={JSON.stringify(locationState?.state?.defaultFiller)} draggable="true">
                                    + Ads
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 p-2 right">
                    <div className="border-right-container p-2">
                        <div className="top-content">
                            <div className="left-side d-flex align-items-center">
                                <h3 className='m-0'>{locationState?.state?.channelName}</h3>
                                {/* <a className='btn btn-primary mx-2' onClick={defaultView}><img className='mx-2' src={backArrow} width={30}/>Back</a> */}
                            </div>
                            <div className="right-side d-flex">
                                <a className='btn btn-revert mx-2 p-1 bg-secondary'><img src={revertIcon} width={35}/></a>
                                {/* {
                                    locationState?.state?.permission === "edit" &&
                                    <button className='btn btn-primary mx-2' onClick={update_schedule}>Update</button>
                                } */}
                                <button className='btn btn-danger mx-2'>Auto Schedule</button>
                            </div>
                        </div>
                        <div className="bottom-content row mt-2">
                            <div className="middle-content col-6">
                                <div className="border-middle-container rounded">
                                    <div className="middle-up">
                                        <div className="d-flex justify-content-between">
                                            <span className="text-white">
                                                <span style={{ fontSize: "16px", color: "#999999" }}>
                                                    {new Date(expandViewData?.date).toLocaleDateString("en-US", { weekday: "short" })}
                                                </span>
                                                {" "}
                                                <span style={{ fontSize: "20px"}}>
                                                    {new Date(expandViewData?.date).toLocaleDateString("en-US", {
                                                    month: "short",
                                                    day: "2-digit",
                                                    })}
                                                </span>
                                            </span>
                                            <div className="d-flex justify-content-between">
                                                {
                                                    copyData?.length && expandViewData?.scheduleItems?.length === 0 ?
                                                    <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {pasteDataFun(expandViewData?.date)}}>
                                                        <img src={pasteIcon} width= {20}/>
                                                    </a>
                                                    :
                                                    <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={()=> {copyDataFun(expandViewData?.date)}}>
                                                        <img src={copyIcon} width= {20}/>
                                                    </a>
                                                }
                                                <a className="btn mx-2 p-1 bg-secondary" style={{height: '28px'}} onClick={defaultView}>
                                                    <img src={expandIcon} width={20}/>
                                                </a>
                                            </div>    
                                        </div>
                                        <div className="row">
                                            <div class="d-flex flex-column align-items-center zoom col-2">
                                                <a class="btn btn-secondary btn-circle" onClick={handleZoomIn}>
                                                    <img src={zoomInIcon} width={30}/>
                                                </a>
                                                <div class="vertical-line my-2" style={{height: "50px"}}></div>
                                                <a class="btn btn-secondary btn-circle" onClick={handleZoomOut}>
                                                    <img src={zoomOutIcon} width={30}/>
                                                </a>
                                            </div>
                                            <div className="schedule-details col-10">
                                                <div>
                                                    <table className={`table table-striped table-hover ${appTheme === "light"? "border":""}`}>
                                                        <thead>
                                                            <tr>
                                                                <th>Total scheduled</th>
                                                                <th className="text-center">Number</th>
                                                                <th className="text-center">HH:MM:SS</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Total Scheduled</td>
                                                                <td className="text-center">{expandViewData?.scheduleItems.length + expandViewData?.scheduleItems?.reduce((total, item) => total + (item.adBreaksCount || 0), 0)}</td>
                                                                <td className="text-center">{millisecondsToHuman(expandViewData?.scheduleItems?.reduce((total, data) => total + (data?.totalDuration || 0), 0))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Videos</td>
                                                                <td className="text-center">{expandViewData?.scheduleItems.length}</td>
                                                                <td className="text-center">{millisecondsToHuman(expandViewData?.scheduleItems?.reduce((total, data) => total + (data?.duration || 0), 0))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ads</td>
                                                                <td className="text-center">{expandViewData?.scheduleItems.reduce((total, item) => total + (item.adBreaksCount || 0), 0)}</td>
                                                                <td className="text-center">{millisecondsToHuman(expandViewData?.scheduleItems?.flatMap(item => item.adBreaks || []).reduce((total, adBreak) => total + (adBreak.duration || 0), 0))}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Promos</td>
                                                                <td className="text-center">0</td>
                                                                <td className="text-center">00:00:00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="middle-down">
                                        <div className="content-wrapper">
                                            <table className="scheduling-table tbl-50-per">
                                                <tbody
                                                    data-type="table-body"
                                                    role="none"
                                                    onDragStart={handleDragStart}
                                                    onDragOver={handleDragOver}
                                                    onDrop={(e) => {handleDrop(e, expandViewData?.date)}}
                                                >
                                                    <div className="row w-100">
                                                        {/* Render timeSlots in a separate loop */}
                                                        <div className="time-slots-container col-2 p-2 text-center">
                                                            {timeSlots?.map((slot, index) => (
                                                                <div 
                                                                    key={index}
                                                                    style={{ width: "100px", height: `${30 * zoomInOut}px` }}
                                                                    className="time-slot"
                                                                >
                                                                    <p>{slot}</p>  {/* Customize this to display relevant time slot info */}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/* Render gridList as you already have it */}
                                                        <div className="grid-list-container col-10 p-2">
                                                            {
                                                                expandViewData?.scheduleItems?.length !== 0?
                                                                    expandViewData?.scheduleItems?.map((data, index) => {
                                                                        return (
                                                                            <section 
                                                                                className="d-flex px-2" 
                                                                                id={`sequenceId${data?.sequence}`}
                                                                                onDragOver={(e) => handleDragOver2(e, data?.uuid)}
                                                                            >
                                                                                <div
                                                                                    style={{height: `${calculateMinutes(data?.durationInMillis + data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)*zoomInOut}px`, width: "36px", position:"relative"}}
                                                                                >   
                                                                                    {linePosition !== null && hoveredId === data?.sequence && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: "absolute",
                                                                                                pointerEvents: "none",
                                                                                                top: `${linePosition}px`,
                                                                                                zIndex: "9999",
                                                                                                left: "-8",
                                                                                                width: "460px",
                                                                                                height: "2px",
                                                                                                backgroundColor: "blue",
                                                                                                pointerEvents: "none"
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    zIndex: "99999999",
                                                                                                    top: "-22px",
                                                                                                    left: "0px",
                                                                                                    backgroundColor: "white",
                                                                                                    padding: "2px 5px",
                                                                                                    fontSize: "12px",
                                                                                                }}
                                                                                            >
                                                                                                {hoverTime} min
                                                                                            </span>
                                                                                        </div>
                                                                                    )}  
                                                                                    {
                                                                                        data?.adBreaks?.length !== 0?
                                                                                            data?.adBreaks?.map((data) => {
                                                                                                if (data?.assetType === "AD" || data?.assetType === "FILLER") {
                                                                                                    return (
                                                                                                        <span style={{position: "absolute" , zIndex: `${data?.adSqId}`, top: `${calculateMinutes(data?.startOffsetInMillis)*zoomInOut}px`, height: "0px"}}>
                                                                                                            <img src={adMarkers} width={35} style={{position: "absolute", top: "-4px"}}/>
                                                                                                        </span>
                                                                                                    )
                                                                                                }
                                                                                                else if (data?.assetType === "PROMO") {
                                                                                                    return (
                                                                                                        <span style={{position: "absolute" , zIndex: `${data?.adSqId}`, top: `${calculateMinutes(data?.startOffsetInMillis)*zoomInOut}px`, height: "0px"}}>
                                                                                                            <img src={adMarkers} width={35} style={{position: "absolute", top: "-4px", filter: "invert(30%) sepia(90%) saturate(400%) hue-rotate(75deg) brightness(90%) contrast(90%)"}}/>
                                                                                                        </span>
                                                                                                    )
                                                                                                }
                                                                                            })
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                </div>
                                                                                <tr
                                                                                    className="dragabble"
                                                                                    onClick={() => {getAssetInfo(data?.sequence, expandViewData?.date)}}
                                                                                    draggable={locationState?.state?.permission === "edit"? true : false}
                                                                                    // draggable={true}
                                                                                    data-type="draggable-list"
                                                                                    key={`${index}`}
                                                                                    data-value={JSON.stringify(data)}
                                                                                    data-id={data?.sequence}
                                                                                    style={{height: `${calculateMinutes(data?.totalDuration? data?.totalDuration : data?.durationInMillis + data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)*zoomInOut}px`, width: "95%", overflow: "hidden", border : `${assetInfo?.sequence === data?.sequence ? "1px solid transparent" : ""}`, borderImage: `${assetInfo?.sequence === data?.sequence ? "linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80) 1" : ""}`}}
                                                                                    title={data?.title}
                                                                                >
                                                                                    <td data-id={data?.sequence} style={{ background: index % 4 === 0 ? "#FFC374" : index % 4 === 1 ? "#4AD2C9" : index % 4 === 2 ? "#8E73EF" : "#536EFF", border : `${assetInfo?.sequence === data?.sequence ? "4px solid rgba(0, 0, 0, 0.9)" : ""}`}}>
                                                                                        <div className="table-content2" style={{ background: "rgba(255, 255, 255, 0.65)", pointerEvents: "none"}}>
                                                                                            <div className="title-schedule-container"> 
                                                                                                <p className="px-2 fw-bold m-0 program-title" style={{color: "#212529"}}>
                                                                                                    {data?.title}
                                                                                                </p>
                                                                                                <p className="px-2 fw-bold schedule-time" style={{color: "#212529"}}>
                                                                                                    {`${formatTime(data?.startAt)} -  ${formatTime(data?.endAt)}`}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="position-relative">
                                                                                                <a className="p-2 table-icon position-absolute" onClick={(e) => {handleProgramDelete(e, data?.uuid, expandViewData?.date)}} style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                                                                                    <img src={deleteIcon} width={18} />
                                                                                                </a>
                                                                                                <a className="p-2 table-adbreaks-count position-absolute" style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                                                                                    <span>{data?.adBreaks.length}</span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </section>
                                                                        )
                                                                    })
                                                                :
                                                                <td colspan="5" class="text-center no-data">
                                                                    No data has been scheduled.
                                                                </td>
                                                            }
                                                        </div>
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="right-side-content col-6">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpandView;