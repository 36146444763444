// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import "../../assets/css/style.scss";
// import LoginHeader from '../../component/Login-header';
// import lock_outline from "../../assets/svg/login/mailIcon.svg";
// import { postData } from "../../api/apiMethod";
// import { commonHeaders } from '../../service/Constant';
// import RunnBackground from '../../assets/svg/login/backgroundRunn.svg';
// import { image_url } from "../../service/Constant";
// import logo from "../../assets/images/logo.png";
// import { ForgetPassword_URL } from "../../service/API_URL";
// import logoInvert from "../../assets/images/logo-invert.png";
// import { UUID_CODE, deviceType } from "../../utility/deviceid";


// export default function ForgetPassword() {
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [formData, setFormData] = useState({ username: '' });
//   const [storeFrontAccess, setStoreFrontAccess] = useState("");
//   const [errors, setErrors] = useState({});

//   const { appThemeReducer: { appTheme } } = useSelector((state) => state);

//   // Validate email
//   const isEmailValid = (email) => {
//     const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
//     return emailRegex.test(email);
//   };

//   // Handle form field changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const accessStore = async () => {
//     const tenantLocalData = localStorage.getItem("tenantInfo");
//     const tenantLocalDataParse = await JSON.parse(tenantLocalData);
//     setStoreFrontAccess(tenantLocalDataParse?.superTenant);
//   };

//   // Handle blur event for validating email
//   const handleBlur = (e) => {
//     const { name, value } = e.target;
//     if (name === 'username' && formData.username && !isEmailValid(value)) {
//       setErrors({
//         ...errors,
//         username: 'Invalid email format',
//       });
//     } else {
//       setErrors({
//         ...errors,
//         username: '',
//       });
//     }
//   };

//   // Call the API to send the password reset link
//   // const forgetPasswordAPI = async () => {
//   //   if (!isEmailValid(formData.username)) {
//   //     setErrors({
//   //       ...errors,
//   //       username: 'Please enter a valid email',
//   //     });
//   //     return;
//   //   }

//   //   const data = {
//   //     email: formData.username,
//   //     deviceInfo: { deviceId: UUID_CODE(), deviceType },
//   //   };

//   //   // try {
//   //   //   const response = await postData(ForgetPassword_URL, data, {
//   //   //     headers: commonHeaders,
//   //   //   });
//   //   //   alert("Password reset link sent successfully!");
     
//   //   //   setFormData({ username: '' });
//   //   // } catch (error) {
//   //   //   alert("Something went wrong, please try after sometime.");
    
//   //   // }

//   //   try {
//   //     const response = await postData(ForgetPassword_URL, data, { headers: commonHeaders });
  
//   //     // Assuming the API responds with success status
//   //     if (response?.success) {
//   //       alert("Password reset link sent successfully to your email!");
//   //       setFormData({ username: '' }); // Reset form data
//   //     } else {
//   //       alert("Failed to send the password reset link. Please try again.");
//   //     }
//   //   } catch (error) {
//   //     alert("Something went wrong, please try after sometime.");
//   //     console.error("An error occurred:", error);
//   //   }
//   // };


//   // const forgetPasswordAPI = async () => {
//   //   let URL = ForgetPassword_URL;
//   //   if (!isEmailValid(formData.username)) {
//   //     setErrors({ username: 'Please enter a valid email' });
//   //     return;
//   //   }
  
//   //   setIsSubmitting(true); // Disable button

//   //   const data = {
//   //        email: formData.username,
//   //         // deviceInfo: { deviceId: UUID_CODE(), deviceType },
//   //     };
  
//   //   try {
//   //     const response = await postData(ForgetPassword_URL, data, { headers: commonHeaders });
//   //     if (response?.success) {
//   //       alert("Password reset link sent successfully!");
//   //       setFormData({ username: '' });
//   //     } else {
//   //       alert("Failed to send the password reset link.");
//   //     }
//   //   } catch (error) {
//   //     alert("Something went wrong.");
//   //   } finally {
//   //     setIsSubmitting(false); // Enable button
//   //   }
//   // };

//   const forgetPasswordAPI = async () => {
//     let URL = ForgetPassword_URL;
//     const data = {
//       email: formData.username,
//       password: formData.password,
//       deviceInfo: { deviceId: UUID_CODE(), deviceType },
//     };

//     try {
//       const response = await postData(URL, data, { headers: commonHeaders });
//       if (response.status === 200 && response.data.success === true) {
//         localStorage.setItem("accessToken", response.data?.data?.accessToken);
//         localStorage.setItem("refreshToken", response.data?.data?.refreshToken);
//         localStorage.setItem("userdetail", JSON.stringify(response.data?.data?.user));

//         if  {
//           sessionStorage.setItem("email", formData.username);
         
//           sessionStorage.setItem("tok", encrypted);
//         } else {
//           sessionStorage.removeItem("email");
//           sessionStorage.removeItem("tok");
//         }

//         setTimeout(() => {
//           window.location.href = "/";
//         }, 1500);
//       } else {
//         sessionStorage.removeItem("email");
//         sessionStorage.removeItem("tok");
//         setErrors({ ...errors, password: "Password is incorrect. Please try again." });
//       }
//     } catch (error) {
//       console.error("Error posting data:", error);
//       sessionStorage.removeItem("email");
//       sessionStorage.removeItem("tok");
//       setErrors({ ...errors, password: "Something went wrong, please try after sometime" });
//     }
//   };









//   useEffect(() => {
//     accessStore(); 
//   }, []);

//   console.log('storeFrontAccess?.darkThemeLogoImagePath', image_url + storeFrontAccess?.darkThemeLogoImagePath);

//   return (
//     <>
//       <LoginHeader />
//       <img src={RunnBackground} alt="Background" className="bottom-background" />
//       <div
//         className="main"
//         style={{
//           backgroundColor: '#131313',
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           height: "100vh",
//           backgroundRepeat: "no-repeat",
//           backgroundPosition: "bottom",
//         }}
//       >
//         <div className="login-block" style={{ position: "relative", zIndex: 1 }}>
//           <div className="login-form" style={{ backgroundColor: '#1A1A1A' }}>
//             <div className="title" style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
//               <div
//                 className="logo"
//                 style={{
//                   backgroundImage: `url(${storeFrontAccess?.darkThemeLogoImagePath ? image_url + storeFrontAccess?.darkThemeLogoImagePath : logoInvert})`,
//                   width: '335px',
//                   height: '80px',
//                   backgroundSize: 'contain',
//                   backgroundRepeat: 'no-repeat',
//                   backgroundPosition: 'center',
//                 }}
//               />
//             </div>

//             <div className="title">
//               <h2 style={{ color: '#fff' }}>Forgot password?</h2>
//               <p style={{ color: '#fff' }}>Enter your email to reset your password</p>
//             </div>

//             <div className="form-group" style={{ color: '#fff' }}>
//               <label>Email address</label>
//               <div className="input-group input-group-sm" style={{ backgroundColor: '#444648' }}>
//                 <div className="input-group-prepend" style={{ color: '#fff' }}>
//                   <img src={lock_outline} alt="Lock Icon" />
//                 </div>
//                 <input
//                   name="username"
//                   id="username"
//                   type="text"
//                   placeholder="Enter email address"
//                   className="form-control"
//                   value={formData.username}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   style={{ backgroundColor: '#444648' }}
//                 />
//               </div>
//               {errors.username && <span className="error" style={{ color: 'red' }}>{errors.username}</span>}
//             </div>

//             {/* <button onClick={forgetPasswordAPI} className="btn btn-block" style={{ backgroundColor: '#F12D34', color: 'white' }}>
//               Submit
//             </button> */}
//             <button 
//   onClick={forgetPasswordAPI} 
//   className="btn btn-block" 
//   style={{ backgroundColor: '#F12D34', color: 'white' }}
//   disabled={isSubmitting}
// >
//   {isSubmitting ? 'Submitting...' : 'Submit'}
// </button>

//             <div className="retun-block">
//               <p>Return to <a href="/">Login!</a></p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import "../../assets/css/style.scss";
import LoginHeader from '../../component/Login-header';
import lock_outline from "../../assets/svg/login/mailIcon.svg";
import { postData } from "../../api/apiMethod"; 
import { commonHeaders } from '../../service/Constant';
import RunnBackground from '../../assets/svg/login/backgroundRunn.svg';
import { image_url } from "../../service/Constant";
import logo from "../../assets/images/logo.png";
import { ForgetPassword_URL } from "../../service/API_URL";
import logoInvert from "../../assets/images/logo-invert.png";
import { UUID_CODE, deviceType } from "../../utility/deviceid";

export default function ForgetPassword() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({ username: '' });
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const [errors, setErrors] = useState({});

  const { appThemeReducer: { appTheme } } = useSelector((state) => state);


  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem("tenantInfo");
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.superTenant);
  };

  // Handle blur event for email validation
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'username' && formData.username && !isEmailValid(value)) {
      setErrors({
        ...errors,
        username: 'Invalid email format',
      });
    } else {
      setErrors({
        ...errors,
        username: '',
      });
    }
  };

 
  const forgetPasswordAPI = async () => {
    if (!isEmailValid(formData.username)) {
      setErrors({
        ...errors,
        username: 'Please enter a valid email',
      });
      return;
    }

    setIsSubmitting(true);

    const data = {
      email: formData.username,
      // deviceInfo: { deviceId: UUID_CODE(), deviceType },
    };

    try {
      const response = await postData(ForgetPassword_URL, data, { headers: commonHeaders });
      
      if (response?.data?.success) {
        alert("Password reset link sent successfully to your email!");
        setFormData({ username: '' });
      } else {
        alert("Failed to send the password reset link. Please try again.");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      alert("Something went wrong, please try after sometime.");
    } finally {
      setIsSubmitting(false); 
    }
  };

  useEffect(() => {
    accessStore();
  }, []);

  return (
    <>
      <LoginHeader />
      <img src={RunnBackground} alt="Background" className="bottom-background" />
      <div
        className="main"
        style={{
          backgroundColor: '#131313',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div className="login-block" style={{ position: "relative", zIndex: 1 }}>
          <div className="login-form" style={{ backgroundColor: '#1A1A1A' }}>
            <div className="title" style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
              <div
                className="logo"
                style={{
                  backgroundImage: `url(${storeFrontAccess?.darkThemeLogoImagePath ? image_url + storeFrontAccess?.darkThemeLogoImagePath : logoInvert})`,
                  width: '335px',
                  height: '80px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            </div>

            <div className="title">
              <h2 style={{ color: '#fff' }}>Forgot password?</h2>
              <p style={{ color: '#fff' }}>Enter your email to reset your password</p>
            </div>

            <div className="form-group" style={{ color: '#fff' }}>
              <label>Email address</label>
              <div className="input-group input-group-sm mt-2"    style={{ backgroundColor: '#FFFFFF14', }}>
                <div className="input-group-prepend" style={{ color: '#fff' ,}}>
                  <img src={lock_outline} alt="Lock Icon" />
                </div>
                <input
                  name="username"
                  id="username"
                  type="text"
                  placeholder="Enter email address"
                  className="form-control"
                  value={formData.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    outline: "none", 
                    boxShadow: "none", 
                 
                  }}
                />
              </div>
              {errors.username && <span className="error" style={{ color: 'red' }}>{errors.username}</span>}
            </div>

            <button
              onClick={forgetPasswordAPI}
              className="btn btn-block  "
              style={{ backgroundColor: '#D22F26', color: 'white', height:56, width:416 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>

            <div className="retun-block">
              <p style={{color:'#E9E9E9'}}>Return to <a href="/">Login!</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

