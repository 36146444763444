import React, { useEffect, useState, useRef } from "react";
import './table.scss';
import AccordionBlock from "./../../accordian/AccordionBlock";
import { useDispatch, useSelector } from "react-redux";
import { ViewershipTablehead, ViewershipMap } from "../../../../api/api";
import { capitalizeFirstLetter, formatNumber, isLogin, TimeConverter } from "../../../../service/commonFunc";
// import sort from "../../../../assets/images/sort.png";
import sort from "../../../../assets/svg/viewership/sortIcon.svg";
// import desc from "../../../../assets/images/desc.png";
import desc from "../../../../assets/svg/viewership/sortIcon.svg";
import filter_alt from "../../../../assets/svg/viewership/sortIcon.svg";
// import filter_alt from "../../../../assets/images/sortarrow.png";
import { ChannelDataAction, MapDataAction } from "../../../../Redux/slices";
import AccordionFilter from "../../accordian/AccordionFilter";

const ViewershipTable = React.memo(() => {
  const dispatch = useDispatch();

  const [TableHead, setTableHead] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [showLoader, setshowLoader] = useState(true);
  const [Duration, setDuration] = useState("Last 24 Hours");
  const [graphicalView, setGraphicalView] = useState(false);
  const [filterVal, setFilterVal] = useState({
    viewType: "CHANNEL",
    duration: "DAY",
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Number of Active Users',
        data: [],
        fill: false,
        borderColor: '#ffffff',
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: '#0f520c',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hidden: false,
      },
      {
        label: 'Total Watch Hours',
        data: [],
        fill: false,
        tension: 0.2,
        borderColor: '#FFFF00',
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: '#000000',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hidden: false,
      },
      {
        label: 'Total Ad Impression',
        data: [],
        fill: false,
        borderColor: '#A52A2A',
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: '#000000',
        pointBorderColor: '#ffffff',
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hidden: false,
      },
    ],
  });


  // const [filterVal, setFilterVal] = useState({
  //   viewType: "CHANNEL", 
  //   duration: "DAY",      
  // });

  const [sortOrders, setSortOrders] = useState({ ["viewKey"]: "asc" });
  const [TotalHeader, setTotalHeader] = useState({
    "viewType": "HEADER",
    "viewKey": "Average",
    "numberOfActiveUsers": 0,
    "totalWatchHours": 0,
    "averageWatchTimePerUser": 0,
    "averageWatchTimePerSession": 0,
    "totalAdImpression": 0
  });

  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);

  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [yAxis2, setYAxis2] = useState([]);
  const [yAxis3, setYAxis3] = useState([]);

  const gradient1Ref = useRef(null);
  const gradient2Ref = useRef(null);
  const gradient3Ref = useRef(null);

  const chartRef = useRef(null);

  const gradientColors1 = ['#F7E9D3 ', '#92F134 ', '#138800', '#008826', '#5AF134', '#DEF8D5'];
  const gradientColors2 = ['#F7E2D3', '#F18F34', '#885200', '#884900', '#F19A34', '#F8ECD5'];
  const gradientColors3 = ['#FFA07A', '#FF8C00', '#8B4513'];



  // Handle sorting of the table data
  const sortOrderEvent = (columnValue) => {
    const currentSortOrder = sortOrders[columnValue] || "asc";
    const newSortOrder = currentSortOrder === "desc" ? "asc" : "desc";

    setSortOrders({ [columnValue]: newSortOrder });

    const headeFilter = {
      sort: newSortOrder,
      key: columnValue,
    };

    filterChangeData(filterVal.viewType, filterVal.duration, headeFilter);
  };

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      // setOpenIndex(null);
      setOpenIndex(openIndex === index ? null : index);
    } else {
      setOpenIndex(index);
    }
  };

  const mapRenderData = async (key) => {
    const ViewershipMapData = {
      channelName: key,
      allChannels: true
    };

    const MapData = await ViewershipMap(ViewershipMapData);

    if (MapData?.data) {
      dispatch(MapDataAction(MapData?.data));
    }
  };


  const filterChangeData = async (view, duration, headeFilter) => {

    const ViewershipTableheadData = {
      viewType: view.toUpperCase(),
      duration: duration.toUpperCase(),
      sortOrder: headeFilter.sort,
      sortBy: headeFilter.key

    };

    const data = await ViewershipTablehead(ViewershipTableheadData, setshowLoader);

    console.log("datadatadata", data)
    // changeGraphView()
    // console.log("dddd",JSON.stringify(data))
    if (duration === "Month") {
      setDuration("Last 30 Days");
    } else if (duration === "Week") {
      setDuration("Last 7 Days");
    }
    else if (duration === "Year") {
      setDuration("Last 365 Days");
    } else {
      setDuration("Last 24 Hours");
    }

    setTableHead(data?.data?.data?.rows);
    mapRenderData(data?.data.data?.header.viewKey);
    setTotalHeader(data?.data.data?.header);


    setshowLoader(false);

    if (filterVal.duration === duration.toUpperCase()) {
      setOpenIndex(null);
      dispatch(ChannelDataAction(data?.data.data?.header));
    }

    let filterObj = {
      viewType: view.toUpperCase(),
      duration: duration.toUpperCase(),
    };
    setFilterVal(filterObj);

    // Re-initialize gradients if necessary
    if (chartRef.current) {
      const chart = chartRef.current;
      const { ctx, chartArea } = chart;
      if (chartArea) {
        if (!gradient1Ref.current) {
          gradient1Ref.current = createFixedGradient(ctx, chartArea, gradientColors1);
        }
        if (!gradient2Ref.current) {
          gradient2Ref.current = createFixedGradient(ctx, chartArea, gradientColors2);
        }
        if (!gradient3Ref.current) {
          gradient3Ref.current = createFixedGradient(ctx, chartArea, gradientColors3);
        }

        setChartData(prevData => ({
          ...prevData,
          labels: xAxis,
          datasets: [
            {
              ...prevData.datasets[0],
              data: yAxis,
              borderColor: gradient1Ref.current,
            },
            {
              ...prevData.datasets[1],
              data: yAxis2,
              borderColor: gradient2Ref.current,
            },
            {
              ...prevData.datasets[2],
              data: yAxis3,
              borderColor: gradient3Ref.current,
            },
          ],
        }));

        chart.update();
      }
    }



    //added for update
    const xdata = [];
    const ydata = [];
    const ydata2 = [];
    const ydata3 = [];

    TableHead.forEach((data) => {
      // xdata.push(data.viewKey);
      xdata.push(data.totalWatchHours);
      ydata.push(data.numberOfActiveUsers);
      ydata2.push(data.totalWatchHours);
      ydata3.push(data.totalAdImpression);
    });


  };

  // Initial data fetch
  useEffect(() => {
    isLogin();  // Ensure the user is logged in
    // filterChangeData(filterVal.viewType, filterVal.duration, { sort: "asc", key: "viewKey" });  // Fetch data initially
  }, [filterVal]);


  const createFixedGradient = (ctx, chartArea, colors) => {
    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    colors.forEach((color, index) => {
      gradient.addColorStop(index / (colors.length - 1), color);
    });
    return gradient;
  };

  useEffect(() => {
    isLogin();
  }, []);



  return (


    <>

      <div className="viewershipTableHeader">
        <AccordionFilter
          setSortOrders={setSortOrders}
          Durations={Duration}
          handleChange={filterChangeData}
        // handleGraphicalView={changeGraphView}
        />

      </div>



      <div className="channel-container" style={{ backgroundColor: '#1a1a1a' }}>
        <div className="channel-table" style={{ width: '100%' }}>
          <div className="channel-table-header" >
            <div className="table-row head"  >
              <div className="table-header-col name" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
                {capitalizeFirstLetter(filterVal.viewType)}  Name
                <a className='filter-icon mx-2' onClick={() => sortOrderEvent("viewKey")}>
                  {sortOrders["viewKey"] === "desc" && <img src={desc} alt="Descending" />}
                  {sortOrders["viewKey"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                  {!sortOrders["viewKey"] && <img src={sort} alt="No Sorting" />}
                </a>
              </div>
              <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
                Active Users
                <a className='filter-icon mx-2' onClick={() => sortOrderEvent("numberOfActiveUsers")}>
                  {sortOrders["numberOfActiveUsers"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                  {sortOrders["numberOfActiveUsers"] === "desc" && <img src={desc} alt="Descending" />}
                  {!sortOrders["numberOfActiveUsers"] && <img src={sort} alt="No Sorting" />}
                </a>
              </div>
              <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
                Total Watch Hours
                <a className='filter-icon mx-2' onClick={() => sortOrderEvent("totalWatchHours")}>
                  {sortOrders["totalWatchHours"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                  {sortOrders["totalWatchHours"] === "desc" && <img src={desc} alt="Descending" />}
                  {!sortOrders["totalWatchHours"] && <img src={sort} alt="No Sorting" />}
                </a>
              </div>
              {/* <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' ,textAlign:'left'}}>
                <div>
                <span> Avg - Watch Time</span>
               <br/>
               <span> Per User<span style={{fontSize:11}}> (HH:MM:SS)</span> </span>
                </div>
             
       
              
                <a className='filter-icon mx-2' onClick={() => sortOrderEvent("averageWatchTimePerUser")}>
                  {sortOrders["averageWatchTimePerUser"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                  {sortOrders["averageWatchTimePerUser"] === "desc" && <img src={desc} alt="Descending" />}
                  {!sortOrders["averageWatchTimePerUser"] && <img src={sort} alt="No Sorting" />}
                </a>
              </div> */}

<div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black', textAlign: 'left', display: 'flex',  alignItems: 'center' }}>
  <div>
    <span>Avg - Watch Time</span>
    <br />
    <span>Per User<span style={{ fontSize: 11 }}> (HH:MM:SS)</span></span>
  </div>
  <a className="filter-icon  " style={{marginLeft:120}} onClick={() => sortOrderEvent("averageWatchTimePerUser")}>
    {sortOrders["averageWatchTimePerUser"] === "asc" && <img src={filter_alt} alt="Ascending" />}
    {sortOrders["averageWatchTimePerUser"] === "desc" && <img src={desc} alt="Descending" />}
    {!sortOrders["averageWatchTimePerUser"] && <img src={sort} alt="No Sorting" />}
  </a>
</div>
              {/* <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
                Avg - Watch Time
                <a className='filter-icon mx-2' onClick={() => sortOrderEvent("averageWatchTimePerSession")}>
                  {sortOrders["averageWatchTimePerSession"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                  {sortOrders["averageWatchTimePerSession"] === "desc" && <img src={desc} alt="Descending" />}
                  {!sortOrders["averageWatchTimePerSession"] && <img src={sort} alt="No Sorting" />}
                </a> Per Session
                <br />
                <small>(HH:MM:SS)</small>
              </div> */}
               <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' , textAlign:'left', display: 'flex',  alignItems: 'center'}}>
                <div>
                <span >Avg - Watch Time  Per</span>
                <br/>
                <span> Session <span style={{fontSize:11}}>(HH:MM:SS)</span> </span>
                </div>
              
                
              
                <a className='filter-icon ' style={{marginLeft:130}} onClick={() => sortOrderEvent("averageWatchTimePerSession")}>
                  {sortOrders["averageWatchTimePerSession"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                  {sortOrders["averageWatchTimePerSession"] === "desc" && <img src={desc} alt="Descending" />}
                  {!sortOrders["averageWatchTimePerSession"] && <img src={sort} alt="No Sorting" />}
                </a>
              </div>
              {/* <div className="table-header-col" onClick={() => sortOrderEvent("totalAdImpression")}>Total Ad Impression
                    <a className='filter-icon mx-2' onClick={() => sortOrderEvent("totalAdImpression")}>
                      <img src={filter_alt} alt="Filter" />
                    </a>
                  </div> */}
            </div>
          </div>
<div className="BG-TABLE">
{/* style={{backgroundColor:'red', padding:2 , borderRadius:4 , paddingRight:10 , paddingLeft:2}} */}
          <div
            onClick={() => filterChangeData(filterVal.viewType, filterVal.duration, { sort: "desc", key: "viewKey" })}
            className="channel-table-header"
          >
            <div className="table-row" style={{ cursor: "pointer" }}>
              <div className="table-header-col name" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' , }} >
                All {capitalizeFirstLetter(filterVal.viewType)}s
              </div>
              <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>{formatNumber(TotalHeader?.numberOfActiveUsers)}</div>
              <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>{formatNumber(TotalHeader?.totalWatchHours?.toFixed(2))} Hrs</div>
              <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
                {TimeConverter(TotalHeader?.averageWatchTimePerUser)}
                <br />
              </div>
              <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
                {TimeConverter(TotalHeader?.averageWatchTimePerSession)}
              </div>
              {/* <div className="table-header-col">{TotalHeader?.totalAdImpression}</div> */}
            </div>
          </div>


          <div className="channel-table-body " style={{paddingRight:12}} >

            <div className="channel-accordion">
              {TableHead &&
                TableHead?.map((data, index) => (
                  <div
                    key={index}
                    className="table-row"

                  >
                    <AccordionBlock
                      key={index}
                      title={data}
                      childrenData={data?.children || []}
                      closeaccodian={() => filterChangeData(filterVal.viewType, filterVal.duration)}
                      isOpen={index === openIndex}
                      onToggle={() => toggleAccordion(index)}
                      filter={filterVal}

                    />
                  </div>
                ))}
            </div>
          </div>
          </div>
        </div>
      </div>


    </>

  );
})

export default ViewershipTable;

