import React, { useState, useEffect } from "react";
import SlideBar from "../../component/sidebar/sidebar";
import DashboardHeader from "../../component/dashboard-header";
import editoutline from "../../assets/images/edit-outline.svg";
import listView from "../../assets/svg/scheduling/listViewIcon.svg";
import currentDatas from "../../dummyjson/channelList.json";
import "./scheduling.scss";
import rightarrow from "../../assets/images/rightvector.png";
import leftarrow from "../../assets/images/Vector.png";
import scheduleActive from "../../assets/svg/scheduling/Complted.svg";
import scheduleHalf from "../../assets/svg/scheduling/Inprogress.svg";
import icroundsearchGray from "../../assets/images/ic_round-search-gray.svg";
import { useNavigate } from "react-router-dom";
import { cloudFront_URL } from "../../service/API_URL";
import uploadicon from "../../assets/images/uploadicon.png";
import refresh from "../../assets/images/refresh.png";
import { toast, ToastContainer } from "react-toastify";
import { permissionDetails } from "../../utility/localStoageData";
import image_thum from "../../assets/images/image-thum.png";
import Loader from "../../component/Loader/Loader";

import { ScheduleUpload_URL } from "../../service/API_URL";

import { postFormData } from "../../api/apiMethod";

import { GetScheduleList, ListApiSync } from "../../api/api";

import CalendarComponent from "./Schedule";
function Scheduling() {
  // const pageSize = 5; // Number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const [scheduleList, setScheduleList] = useState([]);
  const [daysList, setDaysList] = useState('7');
  const [startDay, setStartDay] = useState("");
  const [endDay, setEndDay] = useState("");
  const [uploadID, setUploadID] = useState("");
  const navigate = useNavigate();
  const [permission, setpermission] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [pageJump, setPageJump] = useState(0);
  const [showLoader, setShowLoader] = useState(true);

  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = currentDatas.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    if (currentPage > 0) {
      // setCurrentPage(currentPage - 1);
      fetchSchedule(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      // setCurrentPage(currentPage + 1);
      fetchSchedule(currentPage + 1);
    }
  };

  const uploadFile = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const URL = `${ScheduleUpload_URL}/${uploadID}/schedule/external/csv`;
        const response = await postFormData(URL, formData);
        if (response) {
          toast.success("File uploaded successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        } else {
          toast.error("File not uploaded", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }
  };

  useEffect(() => {
    getPermission();
    fetchSchedule(currentPage);
  }, [daysList]);

  const fetchSchedule = async (currentPage) => {
    setShowLoader(true);
    let queryParams = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize,
      daysList: daysList,
    };
    let scheduleData = await GetScheduleList(queryParams);
    setScheduleList(scheduleData?.data);
    setShowLoader(false);
    setCurrentPage(scheduleData?.data?.number);
    setTotalPages(scheduleData?.data?.totalPages);
    scheduleData = scheduleData?.data?.content;
    const startDate =
      scheduleData[0].commonScheduleChannelDetails[0].scheduleDate.split("-");
    setStartDay(startDate[2] + "-" + startDate[1] + "-" + startDate[0]);
    const len = scheduleData[0].commonScheduleChannelDetails.length;
    const endDate =
      scheduleData[0].commonScheduleChannelDetails[len - 1].scheduleDate.split(
        "-"
      );
    setEndDay(endDate[2] + "-" + endDate[1] + "-" + endDate[0]);
    // totalPages = Math.ceil(scheduleData.length / pageSize);
  };

  const redirectScheduleDetail = (
    channelId,
    channelName,
    type,
    scheduleType,
    defaultFiller
  ) => {
    const redirectPage =
    scheduleType === "LOOP" ? "/schedule-Loop" : "/scheduling-Details";
    // const redirectPage = (scheduleType === 'LOOP') ? '/schedule-Loop' : '/schedulingDetail';
    navigate(redirectPage, {
      state: {
        channelId,
        channelName,
        type,
        defaultFiller,
        permission: permission,
      },
    });
  };

  const listUpdate = async (id, name, type, scheduleType, defaultFiller) => {
    if (type === "XLSInput") {
      setUploadID(id);
      document.getElementById("fileid").click();
    } else if (type === "APIDriven") {
      const response = await ListApiSync(id);

      if (response) {
        toast.success("Channel Sync successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
      } else {
        toast.error("Channel Sync failed", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000,
        });
      }
    } else {
      redirectScheduleDetail(id, name, type, scheduleType, defaultFiller);
    }
  };

  const getDate = (date) => {
    const current = new Date(date);
    const today = current.toLocaleDateString("en-US", { weekday: "long" });
    return today.substring(0, 3);
  };

  const getStatus = (status, returnImage) => {
    if (status === "Complete") {
      return returnImage ? scheduleActive : "scheduleActive";
    } else if (status === "In Progress") {
      return returnImage ? scheduleHalf : "scheduleHalfActive";
    } else {
      return returnImage ? "" : "scheduleInActive";
    }
  };

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("SCHEDULING_EDIT")) setpermission("edit");
    else if (findEdit.includes("SCHEDULING_READ")) setpermission("read");
    else setpermission(null);
  };

  const setPage = (e) => {
    if (e.target.value > 0 && e.target.value <= totalPages) {
      setPageJump(e.target.value - 1);
    }
  };

  const setSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleKeyPress = (event) => {
    event.preventDefault();
    fetchSchedule(pageJump);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchSchedule();
  };

  return permission !== null ? (
    <div className="content-body Scheduling">
      <div className="dashboard-content">
        <div className="top-content">
      
  <div className="left-side">
    <h3 style={{ fontSize: "32px" }}>Scheduling</h3>
  </div>

  <div className={`channel-form ${daysList == 15 ? "w-1200" : ""}`} style={{width:'600px'}}>
          <form onSubmit={handleSearch}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Search by channel name"
                value={searchText}
                onChange={setSearch}
              />
              <button type="submit">
                <img src={icroundsearchGray} alt="" />
              </button>
            </div>
          </form>
        </div>





          {/* <div className="left-side">
            <h3 style={{ fontSize: 32 }}>Scheduling</h3>
          </div> */}
        </div>
        {/* <div className={`channel-form ${daysList == 15 ? "w-1200" : ""}`}>
          <form onSubmit={handleSearch}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Search by channel name"
                value={searchText}
                onChange={setSearch}
              />
              <button type="submit">
                <img src={icroundsearchGray} alt="" />
              </button>
            </div>
          </form>
        </div> */}

        <div
          className={`table table-striped scheduling-main-table ${
            daysList == 15 ? "w-1400" : "w-1800"
          }`}
        >
          {showLoader ? (
            <div className="tableLoader">
              <Loader></Loader>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th width="10%">Channel LOGO</th>
                  <th width="30%">Channel Name</th>
                  <th
                    style={{
                      alignItems: "center",

                      width: "60%",
                    }}
                  >
                    <span>Date : </span>
<b>
  <span style={{ backgroundColor: '#FFFFFF1F', padding: '6px', color:'#fff' }}>
    {startDay}
  </span>
  {" to "}
  <span style={{ backgroundColor: '#FFFFFF3F', padding: '6px', color:'#fff' }}>
    {endDay}
  </span>
</b>
                  </th>
                  {permission === "edit" ? (
                    <th 
    className="text-right d-flex"
    style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end", 
        gap: "10px",
        minWidth: "250px", 
    }}
>
    <span >Status for</span>
    <select
            className="select-grid-header"
            value={daysList} // Bind value to the state
            onChange={(e) => setDaysList(e.target.value)} // Update state on change
            style={{
                padding: "5px",
                fontSize: "14px",
                backgroundColor: "#606264",
                color: "#fff",
            }}
        >
            <option value="7">Next 7 days</option>
            <option value="15">Next 15 days</option>
        </select>
</th>

                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody >
                {scheduleList?.content.length !== 0 ? (
                  scheduleList?.content?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() =>
                        redirectScheduleDetail(
                          item.channelId,
                          item.channelName,
                          item.epgType,
                          item?.scheduleType,
                          item?.filler,
                        )
                      }
                    >
                      <td>
                     

                       
  <div
    key={index}
    style={{
      backgroundColor: index % 2 === 0 ? '#F12D34' : '#000', 
      width: 120,
      height: 70,
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      borderRadius:6
    }}
  >
    <img
      src={
        item?.channelLogo[0]?.thumbnailSourcePath
          ? cloudFront_URL + item?.channelLogo[0]?.thumbnailSourcePath
          : image_thum
      }
      className="img-40 mx-auto w-50 h-50"
      alt=""
    />
  </div>


                      </td>
                      <td>{item.channelName}</td>
                      {item.scheduleType === "LOOP" ? (
                        <td className="text-center">
                          {/* <div className="d-flex justify-content-between align-items-center" >
                          
                            <span class="list-view-btn fs-6 w-100 border py-1 rounded" style={{backgroundColor:'#FAEAE914'}}>
                            <a className="edit-icon list-view-icon ">
                              <img src={listView} alt="" style={{paddingRight:20}} />
                            </a>
                              List View
                            </span>
                          </div> */}
                          <div className="d-flex justify-content-between align-items-center">
  <span
    className="list-view-btn fs-6 w-100 py-1 rounded"
    style={{
      backgroundColor: '#FAEAE914',
      border: '1px solid #D22F26', 
    }}
  >
    <a className="edit-icon list-view-icon">
      <img src={listView} alt="" style={{ paddingRight: 20 }} />
    </a>
    List View
  </span>
</div>

                        </td>
                      ) : (
                        <td>
  <div
    style={{
      display: "flex",
      justifyContent: "space-between", 
      alignItems: "center", 
      gap: "10px", 
      padding: "10px", 
    }}
  >
    {item.commonScheduleChannelDetails.map((schedule, index) => (
      <div
        key={index}
        style={{
          flex: 1,
          padding: "10px",
          backgroundColor:
            schedule.scheduleStatus === "Complete"
              ? "#73DF5C"
              : schedule.scheduleStatus === "In Progress"
              ? "#FABD40"
              : "#C5E7F7",
          borderRadius: "4px", 
          color: "#000", 
          textAlign: "center",
          position: "relative", 
        }}
      >
        <span>{getDate(schedule.scheduleDate)}</span>
        {schedule?.scheduleStatus !== 'Empty' &&   
        <div
          style={{
            position: "absolute",
            right: "-10px",
            bottom: "-10px", 
            // backgroundColor: "#fff", 
            borderRadius: "50%", 
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
            padding: "2px",
            width: "24px", 
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          
            <img
            src={getStatus(schedule.scheduleStatus, true)}
            alt="Status Icon"
            style={{
              
              width: "18px", 
              height: "18px",
            }}
          />
        </div>}
      </div>
    ))}
  </div>
</td>



                        
                      )}

                      {permission === "edit" ? (
                        <td>
                          <a
                            className="edit-icon"
                            onClick={(event) => {
                              event.stopPropagation();
                              listUpdate(
                                item.channelId,
                                item.channelName,
                                item.epgType,
                                item.scheduleType,
                                item?.filler,
                              );
                            }}
                          >
                            {item.epgType === "XLSInput" ? (
                              <img src={uploadicon} alt="" />
                            ) : item.epgType === "APIDriven" ? (
                              <img src={refresh} alt="" />
                            ) : (
                              <img src={editoutline} alt="" />
                            )}
                          </a>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colspan="5">
                      No data Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <div className={`table-pagination ${daysList == 15 ? "w-1200" : ""}`}>
          <div className="pagination-count">
            <div className="count">
              Page: {currentPage + 1} of {totalPages}
            </div>
            <div className="count">
              {startIndex + 1}-{Math.min(endIndex, scheduleList.totalElements)}{" "}
              of {scheduleList.totalElements}
            </div>
            <div className="pagination-arrow">
              <a onClick={handlePrevPage} className="prev">
                {" "}
                <img src={leftarrow} alt="leftarrow" />
              </a>
              <div className="text-center">
                <form onSubmit={handleKeyPress}>
                  <input
                    type="text"
                    defaultValue={currentPage + 1}
                    onChange={setPage}
                  />
                </form>
              </div>
              <a onClick={handleNextPage} className="next">
                {" "}
                <img src={rightarrow} alt="rightarrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <input
        id="fileid"
        type="file"
        hidden
        onChange={(event) => uploadFile(event)}
      />
      {/* <CalendarComponent /> */}
    </div>
  ) : (
    ""
  );
}

export default Scheduling;
